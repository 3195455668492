import { Flex, Heading } from '@happyfoxinc/react-ui'
import { Fragment } from 'react'

import { useFeature } from 'Src/components/Features'
import { CLIENT_FEATURE_FLAGS } from 'Src/constants/feature-flags'

import Notifications from '../Notifications'
import TimeZone from './TimeZone'

const Settings = () => {
  const showNotifications = useFeature([CLIENT_FEATURE_FLAGS.ENABLE_TICKETING, CLIENT_FEATURE_FLAGS.DM_WORKFLOW_MODE])
  const showTimeZone = useFeature([CLIENT_FEATURE_FLAGS.MS_TEAMS_ACCOUNT])

  return (
    <Fragment>
      <Heading>Settings</Heading>
      <Flex gap='lg' direction='c'>
        {showNotifications && <Notifications />}
        {showTimeZone && <TimeZone />}
      </Flex>
    </Fragment>
  )
}

export default Settings
