// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jAM3gn6BGthSebLPSaZ9{margin-top:1.5rem;border:1px solid #eee;border-radius:var(--border-radius);margin-bottom:1rem}.jAM3gn6BGthSebLPSaZ9 .W6mZrTABeFB6mMXEAuu5{background-color:#eee;padding:.7rem 1.2rem;border-radius:var(--border-radius) var(--border-radius) 0 0}.jAM3gn6BGthSebLPSaZ9 .W6mZrTABeFB6mMXEAuu5 .PlVH55Ugk8eG_uSxZFq4{margin-bottom:0}.jAM3gn6BGthSebLPSaZ9 .XEXnAQkxIlu4CAxco9Qv{padding:1rem 1.2rem}.jAM3gn6BGthSebLPSaZ9 .XEXnAQkxIlu4CAxco9Qv .IiEpSxuSSGjBfM54B9jo{display:flex;justify-content:flex-end;margin-top:1rem}.jAM3gn6BGthSebLPSaZ9 .XEXnAQkxIlu4CAxco9Qv .IiEpSxuSSGjBfM54B9jo button{max-width:106px;box-sizing:border-box}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/components/QuickActionsConfig/QuickActionsConfigForm/QuickActionsConfigForm.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,qBAAA,CACA,kCAAA,CACA,kBAAA,CAEA,4CACE,qBAAA,CACA,oBAAA,CACA,2DAAA,CACA,kEACE,eAAA,CAIJ,4CACE,mBAAA,CAEA,kEACE,YAAA,CACA,wBAAA,CACA,eAAA,CACA,yEACE,eAAA,CACA,qBAAA","sourcesContent":[".TableContainer {\n  margin-top: 1.5rem;\n  border: 1px solid #eee;\n  border-radius: var(--border-radius);\n  margin-bottom: 1rem;\n\n  .TableHeader {\n    background-color: #eee;\n    padding: 0.7rem 1.2rem;\n    border-radius: var(--border-radius) var(--border-radius) 0 0;\n    .SwitchInputLabel {\n      margin-bottom: 0;\n    }\n  }\n\n  .TableBody {\n    padding: 1rem 1.2rem;\n\n    .ActionContainer {\n      display: flex;\n      justify-content: flex-end;\n      margin-top: 1rem;\n      button {\n        max-width: 106px;\n        box-sizing: border-box;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TableContainer": "jAM3gn6BGthSebLPSaZ9",
	"TableHeader": "W6mZrTABeFB6mMXEAuu5",
	"SwitchInputLabel": "PlVH55Ugk8eG_uSxZFq4",
	"TableBody": "XEXnAQkxIlu4CAxco9Qv",
	"ActionContainer": "IiEpSxuSSGjBfM54B9jo"
};
export default ___CSS_LOADER_EXPORT___;
