function testForNoQueryParamsInUrl(text, fieldLabel) {
  try {
    const url = new URL(text)
    if (!url.search) return true

    return this.createError({
      message: `${fieldLabel} should not contain query parameters. Example: ${url.origin}`
    })
  } catch {
    return false
  }
}

const noQueryParamsInUrl = (fieldLabel) => ({
  name: 'no-query-params',
  test(text) {
    return testForNoQueryParamsInUrl.call(this, text, fieldLabel)
  }
})
export default noQueryParamsInUrl
