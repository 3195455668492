import ActionsIcon from 'Icons/actions.svg'

import NoContent from 'Layout/NoContent'

const NoActionsProps = {
  title: 'Create actions to automate your workflow',
  description: 'Add actions to automate your workflow and make your team more productive',
  ctaButtonText: 'Create a new action',
  ctaTarget: 'create',
  icon: ActionsIcon
}

const NoActions = () => {
  return <NoContent {...NoActionsProps} />
}

export default NoActions
