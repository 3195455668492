import { Button, Text } from '@happyfoxinc/react-ui'
import { useMemo } from 'react'

import styles from './AppFooter.module.css'

import IndeterminateCircle from 'Icons/indeterminate-circle.svg'

import { SYNC_STATUS } from 'Constants/syncStatus'
import { format, localDateFromUTC } from 'Utils/date'

const AppStatus = ({ syncInfo }) => {
  const { last_sync_at, is_last_sync_successful, is_sync_enabled } = syncInfo || {}

  const lastSyncAtInfo = useMemo(() => {
    if (!last_sync_at) return ''

    const lastSyncStatus = is_last_sync_successful ? 'successful' : 'unsuccessful'
    const lastSyncTimeStamp = format(localDateFromUTC(last_sync_at).toString(), 'DD MMM YYYY HH:mm')

    return `(Last sync done at ${lastSyncTimeStamp} was ${lastSyncStatus})`
  }, [last_sync_at, is_last_sync_successful])

  if (!syncInfo) {
    return (
      <Text isInline variant='muted' className={styles.Status}>
        {SYNC_STATUS.APP_ENABLED}
      </Text>
    )
  }

  if (!is_sync_enabled) {
    const syncDisabledMessage = `${SYNC_STATUS.DISABLED} ${lastSyncAtInfo}`
    return (
      <Text isInline variant='muted' className={styles.Status}>
        {syncDisabledMessage}
      </Text>
    )
  }

  const syncEnabledMessage = last_sync_at ? `${SYNC_STATUS.APP_ENABLED} ${lastSyncAtInfo}` : SYNC_STATUS.IN_PROGRESS
  return (
    <Text isInline variant='muted' className={styles.Status}>
      {syncEnabledMessage}
    </Text>
  )
}

const AppFooter = (props) => {
  const { onUninstall, isUninstalling = false, app = {} } = props
  const { sync_info = null } = app

  return (
    <div className={styles.Container}>
      Status
      <AppStatus syncInfo={sync_info} />
      {onUninstall && (
        <Button
          variant='link-danger'
          className={styles.UninstallButton}
          role='button'
          onClick={onUninstall}
          disabled={isUninstalling}
        >
          Uninstall this app <IndeterminateCircle width='1em' height='1em' />
        </Button>
      )}
    </div>
  )
}

export default AppFooter
