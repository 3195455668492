import { Heading, Text } from '@happyfoxinc/react-ui'
import { Fragment } from 'react'

import WorkspaceTable from './WorkspaceTable'

const Workspaces = () => {
  return (
    <Fragment>
      <Heading>Manage Workspace</Heading>
      <Text variant='muted'>
        Workspace classification directs your requests to the appropriate department. Each workspace is designed to
        manage specific tasks and projects.
      </Text>
      <WorkspaceTable />
    </Fragment>
  )
}

export default Workspaces
