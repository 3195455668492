import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import cx from 'classnames'

import styles from './Tooltip.module.css'

const Tooltip = ({ delayDuration, children }) => {
  return (
    <TooltipPrimitive.Provider delayDuration={delayDuration}>
      <TooltipPrimitive.Root>{children}</TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}

export const TooltipTrigger = TooltipPrimitive.Trigger

export const TooltipContent = ({ children, className, ...rest }) => {
  return (
    <TooltipPrimitive.Portal>
      <TooltipPrimitive.Content className={cx(styles.TooltipContent, className)} sideOffset={5} {...rest}>
        {children}
        <TooltipPrimitive.Arrow className={styles.Arrow} />
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Portal>
  )
}
export default Tooltip
