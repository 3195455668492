import keyMap from 'Constants/key-map'
const { OKTA, AZURE_AD } = keyMap

export default {
  [OKTA.ID]: [
    {
      title: 'Reset password',
      description: 'This action sends an Okta reset password email to the user.'
    },
    {
      title: 'Expire password',
      description:
        'This action expires the password of an Okta user. User will be forced to change their password the next time they sign in.'
    },
    {
      title: 'Reset factors',
      description: "This action resets the user's factors for multi-factor authentication (MFA)"
    },
    {
      title: 'Unlock user',
      description:
        'This action can be used to unlock a user in Okta, so they can continue to sign into third-party applications using Okta SSO'
    },
    {
      title: 'Suspend user',
      description:
        'This action suspends a user from Okta. The user will not be able to login to the Okta account after suspension.'
    }
  ],
  [AZURE_AD.ID]: [
    {
      title: 'Enable Sign-in',
      description:
        'This action allows users to enable sign-in for their Azure AD accounts, granting them access to the associated services and resources.'
    },
    {
      title: 'Reset MFA (Multi-Factor Authentication)',
      description: 'This action allows to configure new MFA methods for a user.'
    },
    {
      title: 'Revoke User Signed-in Sessions',
      description:
        'This action revokes all active user sessions across devices and applications in Azure Active Directory, ensuring that the user is securely logged out from all sessions.'
    },
    {
      title: 'Add Role to User',
      description:
        'This action helps in assigning a specific role or permissions to a user in Azure Active Directory, granting them access to additional resources or functionalities based on their role requirements.'
    },
    {
      title: 'Remove Role for a User',
      description:
        'This action, removes an assigned role or permissions from a user in Azure Active Directory, restricting their access to specific resources or functionalities as needed.'
    },
    {
      title: 'Add User to Group',
      description:
        'This actions adds a user to a specified group in Azure Active Directory, allowing them to collaborate and access resources shared within the group.'
    },
    {
      title: 'Remove User from Group',
      description:
        'This action removes a user from a specified group in Azure Active Directory, revoking their access to resources shared within the group.'
    },
    {
      title: 'Assign Manager to User',
      description:
        'This action assigns a manager or supervisor to the user in Azure Active Directory, establishing a reporting hierarchy for organizational structure and management purposes.'
    }
  ]
}
