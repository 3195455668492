import { Heading, Text } from '@happyfoxinc/react-ui'

import styles from './QuickActionsConfigModal.module.scss'

import Sheet from 'Layout/Sheet'
import { useAppDetailContext } from 'Src/pages/protected/Apps/AppDetails/AppDetailContext'

import quickActions from '../quick-actions'

const QuickActionsConfigModal = ({ onHide }) => {
  const { id } = useAppDetailContext()
  const content = quickActions[id]

  return (
    <Sheet title='Quick Actions' onHide={onHide}>
      {content.map((item, index) => (
        <div key={index} className={styles.ContentItem}>
          <Heading level={2}>{item.title}</Heading>
          <Text variant='muted'>{item.description}</Text>
        </div>
      ))}
    </Sheet>
  )
}

export default QuickActionsConfigModal
