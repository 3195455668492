import styles from './Product.module.css'

import ProductLogo from 'Images/hf-logo.svg'

const Product = () => {
  return (
    <div className={styles.ProductContainer}>
      <ProductLogo />
    </div>
  )
}

export default Product
