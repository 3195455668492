// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kWdlAh1kR2jVRBFLJmTk{position:relative;margin-top:50px;margin-bottom:10px}.YN0SfTAq5GZTuRC5DthA{width:70%}.rfVZHQCZ3zu6p5W1Ur37{display:flex;align-items:center;gap:1rem;position:absolute;right:0;top:0}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/components/QuickActionsConfig/QuickActionsConfig.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,eAAA,CACA,kBAAA,CAGF,sBACE,SAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,iBAAA,CACA,OAAA,CACA,KAAA","sourcesContent":[".Container {\n  position: relative;\n  margin-top: 50px;\n  margin-bottom: 10px;\n}\n\n.HelpText {\n  width: 70%;\n}\n\n.ActionButton {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n  position: absolute;\n  right: 0;\n  top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "kWdlAh1kR2jVRBFLJmTk",
	"HelpText": "YN0SfTAq5GZTuRC5DthA",
	"ActionButton": "rfVZHQCZ3zu6p5W1Ur37"
};
export default ___CSS_LOADER_EXPORT___;
