import UserGroupsIcon from 'Icons/user-groups.svg'

import NoContent from 'Layout/NoContent'

const noContentProps = {
  title: 'Create user groups to show more relevant answers',
  description: 'Add User Groups to group users together and restrict answers suggested to users',
  ctaButtonText: 'Create a new user group',
  ctaTarget: 'create',
  icon: UserGroupsIcon
}

const NoUserGroups = () => {
  return <NoContent {...noContentProps} />
}

export default NoUserGroups
