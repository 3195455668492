import { Box, Button, Dropdown, Flex, Heading, useDropdownContext } from '@happyfoxinc/react-ui'
import { useState } from 'react'

import styles from './MergeFieldDropdown.module.css'

import CloseIcon from 'Icons/close.svg'
import TwoBarIcon from 'Icons/two-bar.svg'

import BadgeContainer from 'Src/components/InsertMergeFields/BadgeContainer'
import Link from 'Src/components/Link'

const MergeFieldDropdown = ({ fields, name, onAdd }) => {
  const Content = () => {
    const { hide } = useDropdownContext()

    const [selectedFields, setSelectedFields] = useState([])

    const handleClick = (field) => {
      setSelectedFields((prev) => {
        if (prev.includes(field)) {
          return prev.filter((item) => item !== field)
        }
        return [...prev, field]
      })
    }

    const handleAdd = () => {
      hide()
      onAdd({ selectedFields, name })
    }

    return (
      <Box className={styles.Container}>
        <Flex className={styles.HeadingContainer} justify='between' align='center'>
          <Heading className={styles.Heading} size='sm'>
            Merge Fields
          </Heading>
          <CloseIcon className={styles.CloseIcon} onClick={hide} width='18' height='18' />
        </Flex>
        <div className={styles.Divider} />
        <Box className={styles.Body}>
          <BadgeContainer fields={fields} selectedFields={selectedFields} onClick={handleClick} />
          <Flex className={styles.ButtonContainer}>
            <Button disabled={!selectedFields.length} onClick={handleAdd} size='xs' variant='primary'>
              Add
            </Button>
            <Button onClick={hide} size='xs' variant='link-muted'>
              Cancel
            </Button>
          </Flex>
        </Box>
      </Box>
    )
  }

  const Action = () => {
    const { getReferenceProps } = useDropdownContext()

    return (
      <Link {...getReferenceProps()} noUnderline variant='primary-dark' role='button'>
        <TwoBarIcon />
      </Link>
    )
  }

  return (
    <Dropdown
      className={styles.MergeFieldIcon}
      as='span'
      id='merge-fields-action-root'
      placement='bottom-end'
      offset={26}
      action={<Action />}
      dropdown={<Content />}
    />
  )
}

export default MergeFieldDropdown
