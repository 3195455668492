// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ppA4uwM4vkQba12jEfQy{display:grid;place-items:center;height:100%;grid-template-columns:repeat(5, minmax(0, 1fr))}.gek5X0jCF7LLfHw5pcv9{grid-column:3/4;display:flex;flex-direction:column;align-items:center;gap:2rem;text-align:center;color:var(--primary)}", "",{"version":3,"sources":["webpack://./../src/components/ErrorBoundary/CrashPage/CrashPage.module.css"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,WAAA,CACA,+CAAA,CAGF,sBACE,eAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,QAAA,CACA,iBAAA,CACA,oBAAA","sourcesContent":[".GridContainer {\n  display: grid;\n  place-items: center;\n  height: 100%;\n  grid-template-columns: repeat(5, minmax(0, 1fr));\n}\n\n.Container {\n  grid-column: 3/4;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 2rem;\n  text-align: center;\n  color: var(--primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GridContainer": "ppA4uwM4vkQba12jEfQy",
	"Container": "gek5X0jCF7LLfHw5pcv9"
};
export default ___CSS_LOADER_EXPORT___;
