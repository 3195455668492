import { TableOption, TableOptions } from '@happyfoxinc/react-ui'
import { Fragment, useCallback, useState } from 'react'
import toast from 'react-hot-toast'

import api from 'Services/api'
import ConfirmationModal from 'Src/components/ConfirmationModal'
import { CONFIRM_MESSAGES } from 'Src/constants/messages'

const WorkspaceOptions = ({ id }) => {
  const [deleteWorkspace] = api.useDeleteWorkspaceMutation()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const handleDeleteClick = useCallback(() => {
    const promise = deleteWorkspace(id).unwrap()
    promise.then(() => setIsDeleteModalOpen(false))

    toast.promise(promise, {
      loading: 'Deleting workspace...',
      success: 'Workspace deleted successfully.',
      error: 'Unable to delete workspace. Try again....'
    })
  }, [id, deleteWorkspace])

  return (
    <Fragment>
      <TableOptions>
        <TableOption onClick={() => setIsDeleteModalOpen(true)}>Delete</TableOption>
      </TableOptions>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        variant='alert'
        message={CONFIRM_MESSAGES.DELETE_WORKSPACE}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteClick}
      />
    </Fragment>
  )
}

export default WorkspaceOptions
