import { Input, Textarea } from '@happyfoxinc/react-ui'
import { useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import styles from './MergeInput.module.scss'

import InsertMergeFields from 'Components/InsertMergeFields'
import { getConcatenatedMergeFieldText } from 'Utils/mergeFields'

const MergeInput = ({ name, mergeFields, showTextArea = false }) => {
  const fieldRef = useRef(null)

  const formMethods = useFormContext()
  const { control, getValues, setValue } = formMethods

  const handleMergeFieldInsertion = (selectedMergeFields) => {
    const presentFieldValue = getValues(name)
    const cursorIndex = fieldRef.current.selectionStart
    const presentValueLength = presentFieldValue.length

    const isTheCursorAtTheStart = cursorIndex === 0
    const isTheCursorAtTheEnd = cursorIndex === presentValueLength

    const canAddSpacerAtStart = !isTheCursorAtTheStart
    const canAddSpacerAtEnd = !isTheCursorAtTheEnd

    const concatenatedMergeFieldText = getConcatenatedMergeFieldText(
      selectedMergeFields,
      canAddSpacerAtStart,
      canAddSpacerAtEnd
    )

    const updatedFieldValue =
      presentFieldValue.slice(0, cursorIndex) + concatenatedMergeFieldText + presentFieldValue.slice(cursorIndex)

    setValue(name, updatedFieldValue, { shouldDirty: true })
  }

  const InputComponent = showTextArea ? Textarea : Input

  return (
    <div className={styles.FieldContainer}>
      <div className={styles.MergeFieldContainer}>
        <InsertMergeFields fields={mergeFields} onAdd={handleMergeFieldInsertion} />
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field: { ref, ...renderProps } }) => (
          <InputComponent
            {...renderProps}
            ref={(refValue) => {
              ref(refValue)
              fieldRef.current = refValue
            }}
          />
        )}
      />
    </div>
  )
}

export default MergeInput
