// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dA3CIWvZuvGvK7NH5Rp7{margin-top:24px}.pqtzEBDVFxefYw8Y7Z9N{border:1px solid var(--border-color-lighter);padding:10px 12px;border-radius:var(--border-radius);margin-top:9px}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Reports/ContentOutline/ContentOutline.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,4CAAA,CACA,iBAAA,CACA,kCAAA,CACA,cAAA","sourcesContent":[".Title {\n  margin-top: 24px;\n}\n\n.Outline {\n  border: 1px solid var(--border-color-lighter);\n  padding: 10px 12px;\n  border-radius: var(--border-radius);\n  margin-top: 9px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Title": "dA3CIWvZuvGvK7NH5Rp7",
	"Outline": "pqtzEBDVFxefYw8Y7Z9N"
};
export default ___CSS_LOADER_EXPORT___;
