import * as yup from 'yup'

import { KNOWLEDGE_GROUP_VISIBILITY_STATES } from 'Constants/user-groups'

const userGroupsError = 'Please select at least 1 user group'

const knowledgeConfigurationValidationSchema = yup
  .object()
  .shape({
    visibility: yup.string().oneOf(Object.values(KNOWLEDGE_GROUP_VISIBILITY_STATES)).required(),
    userGroups: yup.array().when('visibility', {
      is: KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC,
      then: (schema) => schema.min(1, userGroupsError).required(userGroupsError)
    })
  })
  .required()

export default knowledgeConfigurationValidationSchema
