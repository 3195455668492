import useToggleState from 'Utils/hooks/useToggleState'

import InstallApp from '../../../components/InstallApp'
import { useAppDetailContext } from '../../AppDetailContext'
import OktaAuthForm from './OktaAuthForm'

const InstallOkta = ({ app }) => {
  const { title } = useAppDetailContext()
  const [showCredentialsForm, toggleCredentialsForm] = useToggleState(false)

  if (showCredentialsForm) {
    return <OktaAuthForm onCancel={toggleCredentialsForm} app={app} />
  }

  return <InstallApp name={title} onInstall={toggleCredentialsForm} />
}

export default InstallOkta
