import cx from 'classnames'
import { forwardRef, useMemo } from 'react'
import Select from 'react-select'
import SelectAsync from 'react-select/async'
import SelectCreatable from 'react-select/creatable'

import styles from './ReactSelect.module.css'

const NoRender = () => null

const ReactSelect = forwardRef((props, ref) => {
  const {
    className,
    isInvalid = false,
    creatable = false,
    minCharsBeforeLoadingOptions = 0,
    loadOptions: inputLoadOptions,
    inDialog = false,
    isDisabled,
    isMulti = false,
    closeMenuOnSelect = false,
    ...rest
  } = props

  const SelectComponent = useMemo(() => {
    if (inputLoadOptions) {
      return SelectAsync
    }

    return creatable ? SelectCreatable : Select
  }, [creatable, inputLoadOptions])

  const components = useMemo(() => {
    const selectComponents = {
      ...rest.components,
      IndicatorSeparator: null
    }

    Object.entries(selectComponents).forEach(([key, value]) => {
      if (!value) {
        selectComponents[key] = NoRender
      }
    })

    return selectComponents
  }, [rest.components])

  const loadOptions = useMemo(
    () => (inputValue, callback) => {
      if (inputValue.length < minCharsBeforeLoadingOptions || typeof loadOptions !== 'function') {
        return callback(null)
      }

      return inputLoadOptions(inputValue, callback)
    },
    [minCharsBeforeLoadingOptions, inputLoadOptions]
  )

  const canCloseMenuOnSelect = useMemo(() => closeMenuOnSelect || !isMulti, [closeMenuOnSelect, isMulti])

  return (
    <SelectComponent
      {...rest}
      closeMenuOnSelect={canCloseMenuOnSelect}
      isMulti={isMulti}
      className={cx(styles.Select, className)}
      classNamePrefix='ReactSelectExtended'
      ref={ref}
      components={components}
      loadOptions={loadOptions}
      theme={(theme) => {
        return {
          ...theme,
          colors: {
            ...theme.colors,
            primary: 'var(--primary-light)',
            primary25: 'var(--primary-lightest)'
          }
        }
      }}
      {...(inDialog ? { menuPortalTarget: document.body } : {})}
      styles={{
        ...rest.styles,
        control: (provided) => ({
          ...provided,
          boxShadow: 'none',
          backgroundColor: isDisabled ? 'var(--disabled-field)' : null,
          ...(isInvalid ? { borderColor: 'var(--danger-color)' } : {}),
          ...(rest.styles?.control ? rest.styles.control(provided) : {})
        }),
        multiValue: (provided) => ({
          ...provided,
          backgroundColor: `var(--primary-lightest)`,
          border: `1px solid var(--primary-lighter)`,
          borderRadius: '4px',
          ...(rest.styles?.multiValue ? rest.styles.multiValue(provided) : {})
        }),
        option: (provided) => ({
          ...provided,
          color: 'var(--default-text)'
        }),
        menuPortal: (provided) => ({
          ...provided,
          ...(inDialog ? { zIndex: 999 } : {})
        })
      }}
    />
  )
})

ReactSelect.displayName = 'ReactSelect'

export default ReactSelect
