import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { KNOWLEDGE_GROUP_VISIBILITY_STATES } from 'Constants/user-groups'
import api from 'Services/api'
import parseErrorMessage from 'Utils/error-message-parser'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

import AnswerForm, { getPayloadFromFormData } from './AnswerForm'
import answerFormValidationSchema from './AnswerForm/answer-form-validation-schema'

const CreateAnswer = () => {
  const navigate = useNavigate()
  const { currentWorkspaceId } = useWorkspace()

  const formMethods = useForm({
    defaultValues: {
      name: '',
      content: '',
      workspace_id: currentWorkspaceId,
      visibility: KNOWLEDGE_GROUP_VISIBILITY_STATES.ALL,
      visibleToGroups: []
    },
    resolver: yupResolver(answerFormValidationSchema)
  })
  const { handleSubmit, reset } = formMethods

  const [createAnswer, answerResult] = api.useCreateAnswerMutation()

  const processAnswer = useCallback(
    async (data, addMore = false) => {
      const payload = getPayloadFromFormData(data)

      try {
        const promise = createAnswer(payload).unwrap()

        toast.promise(promise, {
          loading: 'Creating answer',
          success: 'Answer created successfully',
          error: parseErrorMessage('Unable to create answer. Try again...')
        })

        await promise

        if (addMore) {
          reset(undefined, { keepErrors: false })
        }
        navigate('../' + `${addMore ? 'create' : ''}`, { replace: true })
      } catch {}
    },
    [createAnswer, navigate, reset]
  )

  const onSubmit = useCallback(() => {
    handleSubmit((data) => processAnswer(data))()
  }, [handleSubmit, processAnswer])

  const onSaveAndAdd = useCallback(() => {
    handleSubmit((data) => processAnswer(data, true))()
  }, [handleSubmit, processAnswer])

  return (
    <FormProvider {...formMethods}>
      <AnswerForm onSubmit={onSubmit} onSaveAndAdd={onSaveAndAdd} isLoading={answerResult.isLoading} />
    </FormProvider>
  )
}

export default CreateAnswer
