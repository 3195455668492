import { createContext, useContext } from 'react'

import PLANS from 'Constants/plans'

export const PlanContext = createContext({
  planName: '',
  integrationsLimit: null,
  isFreePlan: false,
  isProPlan: false,
  isEnterprisePlan: false
})

export const PlanProvider = ({ planDetails, children }) => {
  const isFreePlan = planDetails.planName === PLANS.FREE
  const isProPlan = planDetails.planName === PLANS.PRO
  const isEnterprisePlan = planDetails.planName === PLANS.ENTERPRISE

  return (
    <PlanContext.Provider
      value={{
        ...planDetails,
        isFreePlan,
        isProPlan,
        isEnterprisePlan
      }}
    >
      {children}
    </PlanContext.Provider>
  )
}

export const usePlanContext = () => useContext(PlanContext)
