import { Heading, Text } from '@happyfoxinc/react-ui'
import { Fragment, useMemo } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import styles from './AppsList.module.css'

import { SecondaryButton } from 'Components/Buttons'
import PageLoader from 'Components/PageLoader'
import { usePlanContext } from 'Components/Plan'
import WorkspaceDropdown from 'Components/WorkspaceDropdown'
import { URLS } from 'Constants/urls'
import VerticalTabs from 'Layout/VerticalTabs'
import api from 'Services/api'
import { useWorkspace } from 'Utils/hooks/useWorkspace'
import { openUrl } from 'Utils/url'

import OtherTicketingSystem from '../AppDetails/apps/OtherTicketingSystem'
import appsList from '../apps-list'
import AppTiles from '../AppTiles'
import { APP_CATEGORY } from './../app-category'

const tabs = [
  {
    label: 'All Apps',
    path: APP_CATEGORY.ALL
  },
  {
    label: 'Knowledge Sources',
    path: APP_CATEGORY.KNOWLEDGE_SOURCES
  },
  {
    label: 'Ticketing system',
    path: APP_CATEGORY.TICKETING_SYSTEM
  },
  {
    label: 'Identity Management',
    path: APP_CATEGORY.IDENTITY_MANAGEMENT
  }
]

const getEnabledTabs = (apps) => {
  const enabledAppsList = appsList.filter((appDetail) => {
    return apps.find((app) => app.key === appDetail.key)
  })
  const enabledCategories = enabledAppsList.reduce((acc, appDetail) => {
    if (Array.isArray(appDetail.category)) {
      acc = [...acc, ...appDetail.category]
    } else {
      acc.push(appDetail.category)
    }
    return acc
  }, [])

  const visibleTabs = tabs.filter((tab) => tab.path === APP_CATEGORY.ALL || enabledCategories.includes(tab.path))

  return visibleTabs
}

const AppTilesContainer = ({ enabledCategories }) => {
  const { category } = useParams()
  const { currentWorkspaceId } = useWorkspace()
  const { data = [] } = api.useGetAppsQuery({ workspace_id: currentWorkspaceId })

  const apps = useMemo(() => {
    const mergedApps = data
      .map((app) => {
        const appDetail = appsList.find((lApp) => lApp.key === app.key)

        if (!appDetail) {
          return undefined
        }

        return {
          ...app,
          ...appDetail
        }
      })
      .filter(Boolean)
      .sort((a, b) => a.status.localeCompare(b.status))

    if (category === APP_CATEGORY.ALL) {
      return mergedApps
    }

    return mergedApps.filter((app) => {
      if (Array.isArray(app.category)) {
        return app.category.includes(category)
      }

      return app.category === category
    })
  }, [data, category])

  if (!enabledCategories.includes(category)) {
    return <Navigate to='../all' replace />
  }

  return (
    <Fragment>
      {category === APP_CATEGORY.TICKETING_SYSTEM && (
        <Routes>
          <Route path='other' element={<OtherTicketingSystem />} />
        </Routes>
      )}
      <AppTiles apps={apps} />
    </Fragment>
  )
}

const PlanUpgradeDetails = () => {
  const { planName, integrationsLimit, isFreePlan, isProPlan, isEnterprisePlan } = usePlanContext()
  const { currentWorkspaceId } = useWorkspace()
  const { isLoading, data: apps = [] } = api.useGetAppsQuery({ workspace_id: currentWorkspaceId })

  const handleUpgradeClick = () => {
    openUrl(URLS.ASSIST_AI)
  }

  if (isEnterprisePlan || isLoading) {
    return null
  }

  const connectedApps = apps
    .filter((app) => app.status === 'linked')
    .filter((app) => app.key !== 'other_ticketing_system')

  if (connectedApps.length >= integrationsLimit) {
    return (
      <Fragment>
        <p>You have reached the integrations limit on your {planName} plan.</p>
        <Text variant='muted'>
          {isFreePlan &&
            'Upgrade now to connect to additional integrations. Premium integrations are available on the Pro and Enterprise plans'}
          {isProPlan && 'Upgrade now to enjoy unlimited integrations with Assist AI'}
        </Text>
        <SecondaryButton className={styles.PlanUpgradeButton} onClick={handleUpgradeClick}>
          Upgrade Plan
        </SecondaryButton>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <p>
        You are currently connected to {connectedApps.length} out of {integrationsLimit} available integrations in your{' '}
        {planName} plan.
      </p>
      <Text variant='muted'>You can connect more apps for the complete experience.</Text>
    </Fragment>
  )
}

const AppRouteContainer = () => {
  const { currentWorkspaceId } = useWorkspace()
  const { isLoading, data: apps = [] } = api.useGetAppsQuery({ workspace_id: currentWorkspaceId })
  const enabledTabs = getEnabledTabs(apps)
  const enabledCategories = enabledTabs.map((tab) => tab.path)

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <Routes>
      <Route path='/*' element={<VerticalTabs tabs={enabledTabs} sidebarContent={<PlanUpgradeDetails />} />}>
        <Route path=':category/*' index element={<AppTilesContainer enabledCategories={enabledCategories} />} />
        <Route path='*' element={<Navigate to='all' replace />} />
      </Route>
    </Routes>
  )
}

const AppsList = () => {
  const { planName, integrationsLimit, isEnterprisePlan } = usePlanContext()
  const { data: account } = api.useGetAccountQuery()

  const showWorkspaceDropdown = account?.is_workspaces_enabled
  return (
    <Fragment>
      <Heading>Apps</Heading>
      <Text variant='muted'>
        Connect knowledge sources & ticketing apps to Assist AI. The number of apps that you can integrate is determined
        by your plan. <br />
        {!isEnterprisePlan && `The ${planName} plan allows up to ${integrationsLimit} standard integrations`}
      </Text>
      <div className={styles.WorkspaceDropdownContainer}>{showWorkspaceDropdown && <WorkspaceDropdown />}</div>
      <AppRouteContainer />
    </Fragment>
  )
}

export default AppsList
