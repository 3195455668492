const INPUT_TYPE_OPTIONS = [
  { value: 'text', label: 'Text' },
  { value: 'number', label: 'Number' }
]

const STEP_OPTIONS = [
  { value: 'create_ticket', label: 'Create Ticket' },
  { value: 'send_message', label: 'Send Message' }
]

export { INPUT_TYPE_OPTIONS, STEP_OPTIONS }
