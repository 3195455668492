const MODE_VALUE = {
  AUTOMATIC: 'automatic',

  MANUAL: 'manual',
  MANUAL_WITH_SUGGESTION: 'manual_with_suggestion',
  MANUAL_WITHOUT_SUGGESTION: 'manual_without_suggestion'
}

const MODE_LABEL = {
  AUTOMATIC: 'Create tickets automatically',
  MANUAL_WITH_SUGGESTION: 'Provide Suggestions and Create tickets manually',
  MANUAL_WITHOUT_SUGGESTION: 'Do not provide Suggestions and Create tickets manually'
}

const MODE_OPTIONS = [
  {
    label: MODE_LABEL.AUTOMATIC,
    value: MODE_VALUE.AUTOMATIC,
    subLabel: 'Once a user posts a message in this channel, Assist AI will automatically create a ticket.'
  },
  {
    label: MODE_LABEL.MANUAL_WITH_SUGGESTION,
    value: MODE_VALUE.MANUAL_WITH_SUGGESTION,
    subLabel:
      'Once a user posts a message in this channel, Assist AI will provide suggestions. The user can choose to manually create a ticket if required.'
  },
  {
    label: MODE_LABEL.MANUAL_WITHOUT_SUGGESTION,
    value: MODE_VALUE.MANUAL_WITHOUT_SUGGESTION,
    subLabel:
      'Once a user posts a message in this channel, Assist AI will not provide any suggestions. The user can choose to manually create a ticket if required.'
  }
]

const SYNC_TO_CHANNEL_OPTIONS = [
  {
    label: 'Yes',
    value: true,
    subLabel: 'Slack channel members can view ticket information and reply to the ticket thread'
  },
  {
    label: 'No',
    value: false,
    subLabel: 'Ticket information needs to be privately shown to the requester'
  }
]

const AUTOMATIC = MODE_VALUE.AUTOMATIC

const commonTextCreator = {
  allowedCategoriesOrTeams: (name) => `Choose the ${name} in which the users can create tickets`,
  defaultCategoryOrTeamForAutoMode: (name) =>
    `Choose the ${name}  in which the ticket needs to be automatically created`,
  defaultCategoryOrTeamForManualMode: (name) => `Choose a ${name} to be pre-selected in create ticket form (Optional)`,

  selectedCategoryOrTeamIsPrivate: (name) => `The selected ${name} is private and it won't be defaulted for employees`,

  allowedCategoriesOrTeamsError: (name) => `At least one ${name} is required`,
  defaultCategoryOrTeamError: (name) => `Default ${name} is required`
}

const COMMON_TEXT = {
  ALLOWED_CATEGORIES: commonTextCreator.allowedCategoriesOrTeams('categories'),
  ALLOWED_TEAMS: commonTextCreator.allowedCategoriesOrTeams('teams'),
  DEFAULT_CATEGORY_FOR_AUTO_MODE: commonTextCreator.defaultCategoryOrTeamForAutoMode('category'),
  DEFAULT_TEAM_FOR_AUTO_MODE: commonTextCreator.defaultCategoryOrTeamForAutoMode('team'),
  DEFAULT_CATEGORY_FOR_MANUAL_MODE: commonTextCreator.defaultCategoryOrTeamForManualMode('category'),
  DEFAULT_TEAM_FOR_MANUAL_MODE: commonTextCreator.defaultCategoryOrTeamForManualMode('team'),

  SELECTED_CATEGORY_IS_PRIVATE: commonTextCreator.selectedCategoryOrTeamIsPrivate('category'),
  SELECTED_TEAM_IS_PRIVATE: commonTextCreator.selectedCategoryOrTeamIsPrivate('team'),

  ALLOWED_CATEGORIES_ERROR: commonTextCreator.allowedCategoriesOrTeamsError('category'),
  ALLOWED_TEAMS_ERROR: commonTextCreator.allowedCategoriesOrTeamsError('team'),

  DEFAULT_CATEGORY_ERROR: commonTextCreator.defaultCategoryOrTeamError('category'),
  DEFAULT_TEAM_ERROR: commonTextCreator.defaultCategoryOrTeamError('team')
}

export { MODE_VALUE, MODE_LABEL }
export { SYNC_TO_CHANNEL_OPTIONS, MODE_OPTIONS, AUTOMATIC }
export { COMMON_TEXT }
