// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UOgOhLLxqQPEqyG4ASDA{margin-top:-3.3rem}.H_0Fd3fsQEck7gYu5Tcz{width:100%;border-collapse:collapse;overflow:hidden;border-radius:var(--border-radius, 6px);box-shadow:0 0 3px 0 rgba(0,0,0,.2);margin-bottom:1rem}.mlro83FbBSUqVMee0T00,.dFtpTIYvxTqRu99IKP7c,.ZCdQNZZRgx52pYp0iXaI{padding:.5rem;text-align:inherit;text-align:-webkit-match-parent}.ZCdQNZZRgx52pYp0iXaI{background-color:#eee}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/AppDetails/apps/Notion/NotionPages/NotionPages.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,sBACE,UAAA,CACA,wBAAA,CACA,eAAA,CACA,uCAAA,CACA,mCAAA,CACA,kBAAA,CAGF,kEACE,aAAA,CACA,kBAAA,CACA,+BAAA,CAGF,sBAEE,qBAAA","sourcesContent":[".TableContainer {\n  margin-top: -3.3rem;\n}\n\n.Table {\n  width: 100%;\n  border-collapse: collapse;\n  overflow: hidden;\n  border-radius: var(--border-radius, 6px);\n  box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);\n  margin-bottom: 1rem;\n}\n\n.Cell {\n  padding: 0.5rem;\n  text-align: inherit;\n  text-align: -webkit-match-parent;\n}\n\n.Th {\n  @extend .Cell;\n  background-color: #eee;\n}\n\n.Td {\n  @extend .Cell;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TableContainer": "UOgOhLLxqQPEqyG4ASDA",
	"Table": "H_0Fd3fsQEck7gYu5Tcz",
	"Cell": "mlro83FbBSUqVMee0T00",
	"Td": "dFtpTIYvxTqRu99IKP7c",
	"Th": "ZCdQNZZRgx52pYp0iXaI"
};
export default ___CSS_LOADER_EXPORT___;
