import React from 'react'

import styles from './Tips.module.css'

import BulbIcon from 'Icons/bulb.svg'

export const Tip = (props) => {
  return <li {...props} className={styles.Tip} />
}

const Tips = ({ title, children }) => {
  return (
    <div className={styles.TipContainer}>
      <BulbIcon />
      <p className={styles.TipTitle}>{title}</p>
      <ul className={styles.TipContent}>{children}</ul>
    </div>
  )
}

export default Tips
