// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Jpn1ETUQ78ZyrDHj1Um0{position:relative;margin-top:10px;margin-bottom:30px}.RK3wv0t9RN2VwblPDxi9{width:80%}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/components/AiActions/AiActions.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,eAAA,CACA,kBAAA,CAGF,sBACE,SAAA","sourcesContent":[".Container {\n  position: relative;\n  margin-top: 10px;\n  margin-bottom: 30px;\n}\n\n.HelpText {\n  width: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "Jpn1ETUQ78ZyrDHj1Um0",
	"HelpText": "RK3wv0t9RN2VwblPDxi9"
};
export default ___CSS_LOADER_EXPORT___;
