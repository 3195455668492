import { Text } from '@happyfoxinc/react-ui'

import styles from './Tile.module.scss'

import InfoIcon from 'Icons/info.svg'

import Tooltip, { TooltipContent, TooltipTrigger } from 'Components/Tooltip'

const InfoToolTip = ({ info }) => {
  if (!info) {
    return null
  }
  return (
    <Tooltip delayDuration={250}>
      <TooltipTrigger className={styles.InfoIcon}>
        <InfoIcon />
      </TooltipTrigger>
      <TooltipContent>
        <Text variant='muted'>{info}</Text>
      </TooltipContent>
    </Tooltip>
  )
}

const Tile = ({ title, info, icon, children }) => {
  return (
    <div className={styles.Tile}>
      <div className={styles.Header}>
        <h3 className={styles.Title}>{title}</h3>
        <InfoToolTip info={info} />
        <span className={styles.Spacer} />
        {icon && <div>{icon}</div>}
      </div>
      <div className={styles.Body}>{children}</div>
    </div>
  )
}

export default Tile
