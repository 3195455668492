import { Heading, Text } from '@happyfoxinc/react-ui'
import { Fragment, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { SecondaryButton } from 'Components/Buttons'
import ConfirmationModal from 'Components/ConfirmationModal'
import PageLoader from 'Components/PageLoader'
import { CONFIRM_MESSAGES } from 'Constants/messages'
import api from 'Services/api'
import parseErrorMessage from 'Utils/error-message-parser'

import AppFooter from '../../../components/AppFooter'
import { ConnectedAppBase } from '../../../components/Common/CommonUpgrade'
import { useAppDetailContext } from '../../AppDetailContext'
import AddCollections from './components/AddCollections'
import CollectionsConfig from './components/CollectionsConfig'

const ManageOutline = ({ app }) => {
  const [isUninstallModalOpen, setIsUninstallModalOpen] = useState(false)

  const { title } = useAppDetailContext()
  const navigate = useNavigate()

  const { data = {}, isLoading } = api.useGetSyncedCollectionsQuery()
  const { results: syncedCollections = [] } = data
  const [uninstall, uninstallResult] = api.useUninstallOutlineMutation()

  const handleUninstall = () => {
    const promise = uninstall().unwrap()
    promise.then(() => setIsUninstallModalOpen(false))

    toast.promise(promise, {
      loading: `Uninstalling ${title}`,
      success: `${title} integration uninstalled successfully`,
      error: parseErrorMessage(`Unable to uninstall ${title}. Try again`)
    })
  }

  const handleChangeAuthClick = () => {
    navigate('auth')
  }

  const hasSyncedCollections = syncedCollections?.length > 0
  return (
    <Fragment>
      <ConnectedAppBase>
        <Heading level={2}>
          Successfully connected to {title} workspace. <br /> The account is tied to{' '}
          <Text isInline variant='muted'>
            {app.base_url}
          </Text>
        </Heading>
        <SecondaryButton onClick={handleChangeAuthClick}>Reconnect</SecondaryButton>
      </ConnectedAppBase>
      {isLoading && <PageLoader />}
      {hasSyncedCollections ? <CollectionsConfig /> : <AddCollections />}
      <AppFooter
        app={app}
        onUninstall={() => setIsUninstallModalOpen(true)}
        isUninstalling={uninstallResult.isLoading}
      />
      <ConfirmationModal
        isOpen={isUninstallModalOpen}
        variant='alert'
        message={CONFIRM_MESSAGES.UNINSTALL_APP}
        onCancel={() => setIsUninstallModalOpen(false)}
        onConfirm={handleUninstall}
      />
    </Fragment>
  )
}

export default ManageOutline
