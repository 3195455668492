import { Heading, Switch, Text } from '@happyfoxinc/react-ui'
import cx from 'classnames'
import { useCallback, useEffect } from 'react'
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form'
import toast from 'react-hot-toast'

import styles from './Notifications.module.css'

import ChatBubble from 'Images/notifications-drib.svg'

import { Feature } from 'Components/Features'
import PageLoader from 'Components/PageLoader'
import api from 'Services/api'

import notificationsConfig from './notifications-config'

const NotificationToggle = ({ name }) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, ref } }) => {
        return <Switch checked={value} onCheckedChange={onChange} ref={ref} />
      }}
    />
  )
}

const Control = ({ config }) => {
  switch (config.type) {
    case 'boolean':
      return <NotificationToggle name={config.key} />

    default:
      return null
  }
}

const NotificationConfig = () => {
  const { data } = api.useGetNotificationsQuery()
  const [updateNotifications, updateNotificationsResult] = api.useUpdateNotificationsMutation()

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: { ...data }
  })

  const { handleSubmit, watch } = formMethods

  const onFormSubmit = useCallback(
    (data) => {
      const promise = updateNotifications(data).unwrap()

      toast.promise(promise, {
        loading: 'Updating notification settings',
        success: 'Successfully updated notification settings',
        error: 'Unable to update notification settings'
      })
    },
    [updateNotifications]
  )

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onFormSubmit)())
    return () => subscription.unsubscribe()
  }, [watch, handleSubmit, onFormSubmit])

  return (
    <FormProvider {...formMethods}>
      <div className={cx(styles.ConfigContainer, { [styles.Loading]: updateNotificationsResult.isLoading })}>
        {notificationsConfig.map((config) => {
          return (
            <Feature features={config.requiredFeatures} key={`Notification_Config__${config.key}`}>
              <div className={styles.Config}>
                <div className={styles.Content}>
                  <Text>{config.title}</Text>
                  <Text variant='muted'>{config.description}</Text>
                </div>
                <div className={styles.Controls}>
                  <Control config={config} />
                </div>
              </div>
            </Feature>
          )
        })}
      </div>
    </FormProvider>
  )
}

const Notifications = () => {
  const { isLoading } = api.useGetNotificationsQuery()

  return (
    <div className={styles.OuterContainer}>
      <Heading className={styles.SubHeading} level={2} weight='bold'>
        My Notifications
      </Heading>
      <div className={styles.Container}>
        <div className={styles.ConfigContainer}>
          <PageLoader isLoading={isLoading}>
            <NotificationConfig />
          </PageLoader>
        </div>
        <div className={styles.Cover}>
          <ChatBubble />
        </div>
      </div>
    </div>
  )
}

export default Notifications
