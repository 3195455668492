// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rT6yufhzywdjsdTMr0ah{position:relative;margin-top:40px;margin-bottom:10px}.SZXDAwfhb0LvIY_mqAKr{margin-top:-2.3rem}.OV0Scj4MethtB114XE1k{display:flex;margin-top:10px;width:450px}.cAk7V_ONvfVSE1jI6MXH{margin-left:10px;min-width:60px;max-height:20px}.L1aLvstrfxOo2iD2Iz37{display:flex;flex-direction:column;margin-top:2rem;width:600px}.y0WHrSCvdza1ZNVZlPuC{padding:.5rem}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/AppDetails/apps/Outline/components/CollectionsConfig/CollectionsConfig.module.css"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,eAAA,CACA,kBAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,YAAA,CACA,eAAA,CACA,WAAA,CAGF,sBACE,gBAAA,CACA,cAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,eAAA,CACA,WAAA,CAGF,sBACE,aAAA","sourcesContent":[".Container {\n  position: relative;\n  margin-top: 40px;\n  margin-bottom: 10px;\n}\n\n.TableContainer {\n  margin-top: -2.3rem;\n}\n\n.SelectCollectionsContainer {\n  display: flex;\n  margin-top: 10px;\n  width: 450px;\n}\n\n.AddButton {\n  margin-left: 10px;\n  min-width: 60px;\n  max-height: 20px;\n}\n\n.SyncedCollectionsContainer {\n  display: flex;\n  flex-direction: column;\n  margin-top: 2rem;\n  width: 600px;\n}\n\n.TableCell {\n  padding: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "rT6yufhzywdjsdTMr0ah",
	"TableContainer": "SZXDAwfhb0LvIY_mqAKr",
	"SelectCollectionsContainer": "OV0Scj4MethtB114XE1k",
	"AddButton": "cAk7V_ONvfVSE1jI6MXH",
	"SyncedCollectionsContainer": "L1aLvstrfxOo2iD2Iz37",
	"TableCell": "y0WHrSCvdza1ZNVZlPuC"
};
export default ___CSS_LOADER_EXPORT___;
