import * as yup from 'yup'

const validationSchema = (isHfHelpdeskTicketingConnected) => {
  const defaultCategoryOrTeam = isHfHelpdeskTicketingConnected ? 'default_category' : 'default_team'

  const defaultCategoryOrTeamError = isHfHelpdeskTicketingConnected
    ? 'Please select a category'
    : 'Please select a team'
  return yup.object().shape({
    is_enabled: yup.boolean().required('Please provide the enable status'),
    is_approval_needed: yup.boolean().required('Please provide the approval status'),
    approvers: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string()
        })
      )
      .notRequired()
      .when('is_approval_needed', {
        is: true,
        then: (schema) => schema.min(1, 'No agents selected').required()
      }),
    is_ticketing_enabled: yup.boolean().required('Please provide the ticketing status'),
    [defaultCategoryOrTeam]: yup
      .object()
      .nullable()
      .notRequired()
      .when('is_ticketing_enabled', {
        is: true,
        then: (schema) =>
          schema
            .shape({
              id: yup.string()
            })
            .required(defaultCategoryOrTeamError)
      }),

    subject_template: yup
      .string()
      .notRequired()
      .when('is_ticketing_enabled', {
        is: true,
        then: (schema) => schema.required('Please provide the ticket subject template')
      }),

    default_solved_status: yup
      .object()
      .nullable()
      .notRequired()
      .when('is_ticketing_enabled', {
        is: true,
        then: (schema) =>
          schema
            .shape({
              id: yup.string()
            })
            .required('Please select a ticket status')
      })
  })
}

export default validationSchema
