import dayjs from 'dayjs'
import durationPlugin from 'dayjs/plugin/duration'

dayjs.extend(durationPlugin)

export const getGMTOffsetStringFromSeconds = (offsetInSeconds) => {
  const offsetInHoursMinutes = dayjs.duration(offsetInSeconds, 'seconds').format('HH:mm')

  return `GMT ${offsetInHoursMinutes}`
}
