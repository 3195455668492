import { Input } from '@happyfoxinc/react-ui'
import cx from 'classnames'

import styles from './SearchInput.module.scss'

import LensIcon from 'Icons/lens.svg'

const SearchInput = (props) => {
  const { className, alignRight = false, ...rest } = props

  return (
    <div className={cx(styles.InputContainer, { [styles.AlignRight]: alignRight })}>
      <LensIcon width='1em' height='1em' className={styles.Lens} />
      <Input className={cx(styles.Search, className)} {...rest} />
    </div>
  )
}

export default SearchInput
