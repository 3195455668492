import { CheckboxTree, Heading, useCheckboxTree } from '@happyfoxinc/react-ui'
import { useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { components } from 'react-select'

import styles from './NotionSelectPages.module.css'

import SearchIcon from 'Icons/lens.svg'

import { SecondaryButton } from 'Components/Buttons'
import PageLoader from 'Components/PageLoader'
import ReactSelect from 'Components/ReactSelect'
import api from 'Services/api'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  )
}

const NotionSelectPages = () => {
  const { currentWorkspaceId } = useWorkspace()

  const {
    data: pages = [],
    isLoading,
    isFetching
  } = api.useGetAllNotionPagesQuery({ workspace_id: currentWorkspaceId })
  const [searchPage, searchPageResult] = api.useLazySearchNotionPagesQuery()
  const [syncPages, syncPagesResult] = api.useSyncNotionPagesMutation()

  const [customPages, setCustomPagesPages] = useState([])
  const [selectedSearchPage, setSelectedSearchPage] = useState(null)

  const allPages = useMemo(() => {
    const mergedPages = [...pages, ...customPages]

    return Array.from(
      // remove duplicates
      mergedPages
        .reduce((map, page) => {
          map.set(page.id, page)
          return map
        }, new Map())
        .values()
    )
  }, [pages, customPages])

  const tree = useMemo(() => {
    if (!allPages.length) {
      return []
    }

    return [
      {
        id: 0,
        name: 'Pages',
        children: allPages.map((page) => {
          return {
            ...page,
            name: page.title,
            parentId: 0,
            children: []
          }
        })
      }
    ]
  }, [allPages])

  const { checked: selectedPages, selectNode, state } = useCheckboxTree(tree)
  const clearSelectedPage = () => {
    setSelectedSearchPage(null)
  }

  const handleSearchSelection = (selected) => {
    const selectedValueExists = pages.find((page) => page.id === selected.id)

    if (!selectedValueExists) {
      setCustomPagesPages((previousState) => [...previousState, selected])
    }

    setSelectedSearchPage(selected)
    selectNode(selected.id, true)
    clearSelectedPage()
  }

  const loadOptions = (inputValue, cb) => {
    searchPage({ title: inputValue, workspaceId: currentWorkspaceId }).unwrap().then(cb)
  }

  const handleSync = async () => {
    const pageIdsToSync = selectedPages.filter((pageId) => pageId !== 0)

    const payload = {
      notion_page_ids: pageIdsToSync,
      workspace_id: currentWorkspaceId
    }

    try {
      const promise = syncPages(payload).unwrap()

      toast.promise(promise, {
        loading: 'Syncing selected notion pages',
        success: 'Successfully synced pages',
        error: 'Unable to sync pages'
      })
      await promise
    } catch {}
  }

  const disableSyncButton = selectedPages.length === 0 || syncPagesResult.isLoading

  return (
    <div className={styles.Container}>
      <Heading level={2}>Select Notion page(s) to sync from</Heading>
      <PageLoader isLoading={isLoading || isFetching}>
        <ReactSelect
          value={selectedSearchPage}
          onChange={handleSearchSelection}
          loadOptions={loadOptions}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.title}
          placeholder='Search for pages in your notion'
          minCharsBeforeLoadingOptions={3}
          isLoading={searchPageResult.isLoading}
          loadingMessage={({ inputValue }) => {
            return `Searching for pages matching "${inputValue}"`
          }}
          noOptionsMessage={({ inputValue }) => {
            if (inputValue.length < 3) {
              return 'Type alteast 3 characters to start searching'
            }

            return `No pages found for input "${inputValue}"`
          }}
          styles={{
            control: (provided) => ({
              ...provided,
              backgroundColor: '#EEEEEE',
              border: 0
            })
          }}
          components={{
            DropdownIndicator
          }}
        />
        <div className={styles.CheckboxTreeContainer}>
          <CheckboxTree nodes={tree} checked={selectedPages} selectNode={selectNode} state={state} />
          <div className={styles.SyncButtonContainer}>
            <SecondaryButton onClick={handleSync} disabled={disableSyncButton}>
              Sync now
            </SecondaryButton>
          </div>
        </div>
      </PageLoader>
    </div>
  )
}

export default NotionSelectPages
