// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Uz8lKkLN6qA7aef1Qj_S{font-weight:700}.eKas8h0dAGykPN2p52No{display:flex;flex-direction:column;gap:1.5rem}.M8Tn16nIVSPL8eo_hgx8{display:flex;align-items:center}.CYjHxzesn_8cxTqgP0tG{margin-left:auto;font-weight:normal !important}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/AppDetails/apps/OtherTicketingSystem/OtherTicketingSystem.module.css"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,UAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,gBAAA,CACA,6BAAA","sourcesContent":[".Title {\n  font-weight: 700;\n}\n\n.Form {\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n}\n\n.ButtonContainer {\n  display: flex;\n  align-items: center;\n}\n\n.DisconnectButton {\n  margin-left: auto;\n  font-weight: normal !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Title": "Uz8lKkLN6qA7aef1Qj_S",
	"Form": "eKas8h0dAGykPN2p52No",
	"ButtonContainer": "M8Tn16nIVSPL8eo_hgx8",
	"DisconnectButton": "CYjHxzesn_8cxTqgP0tG"
};
export default ___CSS_LOADER_EXPORT___;
