// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".afBtKXFeHvDWM5xj5NVl{display:grid;height:100%;place-items:center}.b423Veg_Vond7A2a5FuM{width:var(--loader-weight, 5rem);height:var(--loader-height, 1rem);border-radius:40px;color:var(--primary);border:2px solid;position:relative}.b423Veg_Vond7A2a5FuM::before{content:\"\";position:absolute;margin:2px;width:25%;top:0;bottom:0;left:0;border-radius:inherit;background:currentColor;animation:jjEI2nzt3RZvSHdsKkTR 1s infinite linear}@keyframes jjEI2nzt3RZvSHdsKkTR{50%{left:100%;transform:translateX(calc(-100% - 4px))}}.JmA9x51XqL9qs876lkDo{--loader-height: 0.9rem;--loader-weight: 3.5rem}", "",{"version":3,"sources":["webpack://./../src/components/PageLoader/PageLoader.module.scss"],"names":[],"mappings":"AAGA,sBACE,YAAA,CACA,WAAA,CACA,kBAAA,CAGF,sBACE,gCAAA,CACA,iCAAA,CACA,kBAAA,CACA,oBAAA,CACA,gBAAA,CACA,iBAAA,CAGF,8BACE,UAAA,CACA,iBAAA,CACA,UAAA,CACA,SAAA,CACA,KAAA,CACA,QAAA,CACA,MAAA,CACA,qBAAA,CACA,uBAAA,CACA,iDAAA,CAGF,gCACE,IACE,SAAA,CACA,uCAAA,CAAA,CAIJ,sBACE,uBAAA,CACA,uBAAA","sourcesContent":["$width: 5rem;\n$height: 1rem;\n\n.Container {\n  display: grid;\n  height: 100%;\n  place-items: center;\n}\n\n.Loader {\n  width: var(--loader-weight, $width);\n  height: var(--loader-height, $height);\n  border-radius: 40px;\n  color: var(--primary);\n  border: 2px solid;\n  position: relative;\n}\n\n.Loader::before {\n  content: \"\";\n  position: absolute;\n  margin: 2px;\n  width: 25%;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  border-radius: inherit;\n  background: currentColor;\n  animation: wobble 1s infinite linear;\n}\n\n@keyframes wobble {\n  50% {\n    left: 100%;\n    transform: translateX(calc(-100% - 4px));\n  }\n}\n\n.sm {\n  --loader-height: 0.9rem;\n  --loader-weight: 3.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "afBtKXFeHvDWM5xj5NVl",
	"Loader": "b423Veg_Vond7A2a5FuM",
	"wobble": "jjEI2nzt3RZvSHdsKkTR",
	"sm": "JmA9x51XqL9qs876lkDo"
};
export default ___CSS_LOADER_EXPORT___;
