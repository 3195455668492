// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".F2stSWsaqSS5_mo5sYMg{width:100%;height:100%;background-color:#fff;border-radius:8px;box-shadow:var(--tile-shadow);padding:18px 20px}.F2stSWsaqSS5_mo5sYMg .pue19Va2J94faiO1MhZ2{display:flex;align-items:center;height:24px}.F2stSWsaqSS5_mo5sYMg .pue19Va2J94faiO1MhZ2 .sJdtA7YGesSxF5zI5AC5{font-size:var(--text-heading2);font-weight:400;color:#000}.F2stSWsaqSS5_mo5sYMg .pue19Va2J94faiO1MhZ2 .l9SkKzY8eU810Lxitdrg{margin-left:5px;cursor:pointer;border:0;background:rgba(0,0,0,0)}.F2stSWsaqSS5_mo5sYMg .qoUlqgE_5TPhr35Lrsgt{margin-top:12px;height:calc(100% - 24px - 12px)}.Kdo_KlugFyWbMumtqYzJ{flex:1 1 auto}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Reports/Tile/Tile.module.scss"],"names":[],"mappings":"AAEA,sBACE,UAAA,CACA,WAAA,CACA,qBAAA,CACA,iBAAA,CACA,6BAAA,CACA,iBAAA,CACA,4CACE,YAAA,CACA,kBAAA,CACA,WAZW,CAaX,kEACE,8BAAA,CACA,eAAA,CACA,UAAA,CAEF,kEACE,eAAA,CACA,cAAA,CACA,QAAA,CACA,wBAAA,CAGJ,4CACE,eAzBY,CA0BZ,+BAAA,CAIJ,sBACE,aAAA","sourcesContent":["$headerHeight: 24px;\n$bodyTopMargin: 12px;\n.Tile {\n  width: 100%;\n  height: 100%;\n  background-color: white;\n  border-radius: 8px;\n  box-shadow: var(--tile-shadow);\n  padding: 18px 20px;\n  .Header {\n    display: flex;\n    align-items: center;\n    height: $headerHeight;\n    .Title {\n      font-size: var(--text-heading2);\n      font-weight: 400;\n      color: black;\n    }\n    .InfoIcon {\n      margin-left: 5px;\n      cursor: pointer;\n      border: 0;\n      background: transparent;\n    }\n  }\n  .Body {\n    margin-top: $bodyTopMargin;\n    height: calc(100% - $headerHeight - $bodyTopMargin);\n  }\n}\n\n.Spacer {\n  flex: 1 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Tile": "F2stSWsaqSS5_mo5sYMg",
	"Header": "pue19Va2J94faiO1MhZ2",
	"Title": "sJdtA7YGesSxF5zI5AC5",
	"InfoIcon": "l9SkKzY8eU810Lxitdrg",
	"Body": "qoUlqgE_5TPhr35Lrsgt",
	"Spacer": "Kdo_KlugFyWbMumtqYzJ"
};
export default ___CSS_LOADER_EXPORT___;
