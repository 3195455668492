import { customDates } from 'Utils/date'
import dayjs from 'Utils/dayjs-helper'

export const RANGES = {
  TODAY: 'today',
  LAST_7_DAYS: 'last-7-days',
  LAST_30_DAYS: 'last-30-days',
  LAST_365_DAYS: 'last-365-days',
  ALL_TIME: 'all-time',
  CUSTOM: 'custom-range'
}

const PREDEFINED_DATE_RANGES = [
  {
    key: RANGES.TODAY,
    label: 'Today',
    range: {
      from: customDates.startOfToday(),
      to: customDates.now()
    }
  },
  {
    key: RANGES.LAST_7_DAYS,
    label: 'Last 7 days',
    range: {
      from: customDates.pastDay(6),
      to: customDates.now()
    }
  },
  {
    key: RANGES.LAST_30_DAYS,
    label: 'Last 30 days',
    range: {
      from: customDates.pastDay(29),
      to: customDates.now()
    }
  },
  {
    key: RANGES.LAST_365_DAYS,
    label: 'Last 365 days',
    range: {
      from: customDates.pastDay(364),
      to: customDates.now()
    }
  },
  {
    key: RANGES.ALL_TIME,
    label: 'All time',
    range: {
      from: null,
      to: customDates.now()
    }
  },
  {
    key: RANGES.CUSTOM,
    label: 'Custom range'
  }
]

export const getPredefinedDateRanges = (account_start_date) => {
  const accountStartDate = new Date(account_start_date)
  const today = new Date()
  const accountStartInDays = dayjs(today).diff(dayjs(accountStartDate), 'days')

  const filteredRanges = PREDEFINED_DATE_RANGES.reduce((acc, date) => {
    if (date.key === RANGES.CUSTOM || date.key === RANGES.ALL_TIME) {
      if (date.key === RANGES.ALL_TIME) {
        date.range.from = accountStartDate
      }
      acc.push({ ...date, show: true })
      return acc
    }

    const from = new Date(date.range.from) < accountStartDate ? accountStartDate : new Date(date.range.from)

    if (from <= today) {
      const isValidRange =
        (date.key === RANGES.LAST_365_DAYS && accountStartInDays >= 365) ||
        (date.key === RANGES.LAST_30_DAYS && accountStartInDays >= 30) ||
        (date.key === RANGES.LAST_7_DAYS && accountStartInDays >= 7) ||
        date.key === RANGES.TODAY

      acc.push({
        ...date,
        show: isValidRange,
        range: {
          ...date.range,
          from
        }
      })
    }

    return acc
  }, [])

  return filteredRanges
}
