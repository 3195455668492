import { Dropdown, DropdownItem, Small, Text, useDropdownContext } from '@happyfoxinc/react-ui'
import { Fragment, useCallback, useState } from 'react'

import styles from './UserActions.module.css'

import UserIcon from 'Icons/user.svg'

import ConfirmationModal from 'Components/ConfirmationModal'
import { CONFIRM_MESSAGES } from 'Constants/messages'
import api from 'Services/api'
import { redirect } from 'Utils/url'

const UserDropdown = ({ onLogout }) => {
  const { data: user } = api.useGetProfileQuery()

  return (
    <Fragment>
      <DropdownItem>
        <Text>{user.name}</Text>
        <Small size='xs' variant='muted'>
          {user.email}
        </Small>
      </DropdownItem>
      <DropdownItem onClick={onLogout} disabled={false}>
        Logout
      </DropdownItem>
    </Fragment>
  )
}

const Action = () => {
  const { data: user } = api.useGetProfileQuery()
  const { getReferenceProps } = useDropdownContext()

  return (
    <div {...getReferenceProps()}>
      {user.avatar_url ? <img src={user.avatar_url} className={styles.ProfilePic} alt={user.name} /> : <UserIcon />}
    </div>
  )
}

const UserActions = () => {
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  const handleLogout = useCallback(async () => {
    setIsLogoutModalOpen(false)
    // do logout here
    redirect('logout')
  }, [])

  return (
    <Fragment>
      <Dropdown
        id='user-action-root'
        offset={15}
        action={<Action />}
        dropdown={<UserDropdown onLogout={() => setIsLogoutModalOpen(true)} />}
      />
      <ConfirmationModal
        isOpen={isLogoutModalOpen}
        message={CONFIRM_MESSAGES.LOGOUT}
        onCancel={() => setIsLogoutModalOpen(false)}
        onConfirm={handleLogout}
      />
    </Fragment>
  )
}

export default UserActions
