import { Heading, Text } from '@happyfoxinc/react-ui'
import { Fragment } from 'react'

import AdminsTable from './AdminsTable'

const ManageAdmins = () => {
  return (
    <Fragment>
      <Heading>Manage Admins</Heading>
      <Text variant='muted'>
        Admins can access this admin module to view dashboard, connect knowledge sources, enable conversational
        ticketing, view reports, and invite other admins.
      </Text>
      <AdminsTable />
    </Fragment>
  )
}

export default ManageAdmins
