// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eXmSsnvQbQ7ANMt40LNF{background-color:var(--sidebar-bg-color);display:flex;flex-direction:column}", "",{"version":3,"sources":["webpack://./../src/components/Sidebar/Sidebar.module.css"],"names":[],"mappings":"AAAA,sBACE,wCAAA,CACA,YAAA,CACA,qBAAA","sourcesContent":[".SidebarContainer {\n  background-color: var(--sidebar-bg-color);\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SidebarContainer": "eXmSsnvQbQ7ANMt40LNF"
};
export default ___CSS_LOADER_EXPORT___;
