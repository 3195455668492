// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rOTJANhwKmcEQhZlol75{position:fixed;width:750px;height:430px;left:50%;top:50%;transform:translate(-50%, -50%);z-index:99999999;background-color:#fff;border:2px solid #fff;border-radius:4px}.rOTJANhwKmcEQhZlol75 iframe{left:0;top:0;height:100%;width:100%;position:absolute}.wlJ5VG0tzcseJXeJIp82{position:fixed;background-color:rgba(0,0,0,.5) !important;inset:0px;z-index:1000}", "",{"version":3,"sources":["webpack://./../src/components/YoutubeEmbed/YoutubeEmbed.module.css"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,WAAA,CACA,YAAA,CACA,QAAA,CACA,OAAA,CACA,+BAAA,CACA,gBAAA,CACA,qBAAA,CACA,qBAAA,CACA,iBAAA,CAGF,6BACE,MAAA,CACA,KAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CAGF,sBACE,cAAA,CACA,0CAAA,CACA,SAAA,CACA,YAAA","sourcesContent":[".VideoContainer {\n  position: fixed;\n  width: 750px;\n  height: 430px;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 99999999;\n  background-color: white;\n  border: 2px solid white;\n  border-radius: 4px;\n}\n\n.VideoContainer iframe {\n  left: 0;\n  top: 0;\n  height: 100%;\n  width: 100%;\n  position: absolute;\n}\n\n.Overlay {\n  position: fixed;\n  background-color: rgba(0, 0, 0, 0.5) !important;\n  inset: 0px;\n  z-index: 1000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"VideoContainer": "rOTJANhwKmcEQhZlol75",
	"Overlay": "wlJ5VG0tzcseJXeJIp82"
};
export default ___CSS_LOADER_EXPORT___;
