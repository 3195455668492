import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import reducer from './reducer'

const services = reducer.getServices()

export const createStore = () => {
  const store = configureStore({
    reducer: {
      ...services.reducers
    },
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat(...services.middlewares)
    },
    devTools: {
      name: 'Assist AI - Admin'
    }
  })

  setupListeners(store.dispatch)
  return store
}
