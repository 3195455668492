import { Button, Heading, Small, Text } from '@happyfoxinc/react-ui'
import cx from 'classnames'
import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './DashboardContent.module.scss'

import RightAngleIcon from 'Icons/arrow-right.svg'
import Check from 'Icons/check.svg'
import InProgressIcon from 'Icons/reload.svg'

import { SecondaryButton, SuccessButton } from 'Components/Buttons'
import { useFeature } from 'Components/Features'
import PageLoader from 'Components/PageLoader'
import { usePlanContext } from 'Components/Plan'
import YoutubeEmbed from 'Components/YoutubeEmbed'
import { ACCOUNT_TYPE } from 'Constants/account'
import { ANNOUNCEMENT_STATUS } from 'Constants/announcement-status'
import { CLIENT_FEATURE_FLAGS } from 'Constants/feature-flags'
import KB_LINKS from 'Constants/kb-links'
import { URLS } from 'Constants/urls'
import api from 'Services/api'
import { openUrl } from 'Utils/url'

import TutImage from './tuts.svg'

const Card = ({ children }) => {
  return <div className={styles.Card}>{children}</div>
}

const CardContent = ({ title, connected = false, subtext, children }) => {
  return (
    <div className={styles.CardContent}>
      <Heading className={styles.CardTitle} level={2}>
        {title}{' '}
        {connected && (
          <div className={styles.CheckIcon}>
            <Check width='1em' height='1em' />
          </div>
        )}{' '}
        {subtext && (
          <Text className={styles.SubText} variant='muted'>
            {subtext}
          </Text>
        )}
      </Heading>
      <Text variant='muted'>{children}</Text>
    </div>
  )
}

const CardAction = ({ children }) => {
  return <div className={styles.CardAction}>{children}</div>
}

const CardGroup = ({ title, children }) => {
  return (
    <div className={styles.CardGroup}>
      <p className={styles.CardGroupTitle}>{title}</p>
      {children}
    </div>
  )
}

const ActionButton = ({ children, ...rest }) => {
  return (
    <SecondaryButton size='sm' className={styles.ActionButton} {...rest}>
      {children} <RightAngleIcon width='1em' height='1em' />
    </SecondaryButton>
  )
}

const ActionConnectedButton = ({ ...rest }) => {
  return <SuccessButton size='sm' className={styles.ActionConnectedButton} {...rest} />
}

const ConversationalTicketingAction = () => {
  const { isEnterprisePlan } = usePlanContext()

  if (isEnterprisePlan) {
    return null
  }

  return (
    <div className={styles.PlanLabel}>
      <Small variant='muted'>Available on</Small>
      <Button variant='primary' size='xs' className={styles.PlanButton} onClick={() => openUrl(URLS.ASSIST_AI)}>
        Enterprise Plan <RightAngleIcon width='1em' height='1em' />
      </Button>
    </div>
  )
}

const AnnouncementCard = ({ account, dashboard }) => {
  const isSlackAccountType = account.account_type === ACCOUNT_TYPE.SLACK
  const isMsTeamsAccountType = account.account_type === ACCOUNT_TYPE.MS_TEAMS
  const isAnnouncementPending = dashboard.introductory_announcement_status === ANNOUNCEMENT_STATUS.PENDING
  const isAnnouncementInProgress = dashboard.introductory_announcement_status === ANNOUNCEMENT_STATUS.IN_PROGRESS
  const isAnnouncementCompleted = dashboard.introductory_announcement_status === ANNOUNCEMENT_STATUS.COMPLETED

  const navigate = useNavigate()

  const descriptionForMsTeams = () => {
    if (dashboard.are_agents_present_without_app_installed) {
      return 'There are still a few employees who do not have Assist AI on Teams yet, go ahead and add Assist AI to all your employees.'
    }
    return 'Add Assist AI to all your employees'
  }

  const MsTeamsCardContent = () => {
    return (
      <Fragment>
        <CardContent title='Let Your Team Know'>{descriptionForMsTeams()}</CardContent>
        <CardAction>
          <ActionButton onClick={() => openUrl(KB_LINKS.SHARE_MS_TEAMS_AAI)}>Learn more</ActionButton>
        </CardAction>
      </Fragment>
    )
  }

  const SlackCardContent = () => {
    return (
      <Fragment>
        <CardContent
          connected={isAnnouncementCompleted}
          title={isAnnouncementPending ? 'Let Your Team Know' : 'Announcement'}
        >
          Announce Assist AI to your team and employees.
        </CardContent>
        <CardAction>
          {isAnnouncementPending && <ActionButton onClick={() => navigate('announcement')}>Start Now</ActionButton>}
          {isAnnouncementInProgress && (
            <SecondaryButton size='sm' className={cx(styles.ActionButton, styles.ActionButtonNoHover)}>
              In Progress <InProgressIcon />
            </SecondaryButton>
          )}
          {isAnnouncementCompleted && (
            <ActionConnectedButton className={styles.ActionButtonNoHover}>Completed</ActionConnectedButton>
          )}
        </CardAction>
      </Fragment>
    )
  }

  return (
    <Card>
      {isSlackAccountType && <SlackCardContent />}
      {isMsTeamsAccountType && <MsTeamsCardContent />}
    </Card>
  )
}

const DashboardContent = () => {
  const [showTutVideo, setShowTutVideo] = useState(false)
  const { data: account = {} } = api.useGetAccountQuery()

  const { isLoading, isFetching, data: dashboard = {} } = api.useGetDashboardQuery()
  const { data: user } = api.useGetProfileQuery()
  const navigate = useNavigate()
  const { planName, isProPlan, isEnterprisePlan } = usePlanContext()
  const isSlackAccountType = account.account_type === ACCOUNT_TYPE.SLACK
  const isMsTeamsAccountType = account.account_type === ACCOUNT_TYPE.MS_TEAMS
  const isDMWorkflowEnabled = useFeature([CLIENT_FEATURE_FLAGS.DM_WORKFLOW_MODE])

  const getConnectedArticlesSubtext = () => {
    if (!dashboard.is_kb_integration_connected) {
      return ''
    }

    return `(${dashboard.indexed_kb_articles_count} articles synced)`
  }

  const getDescriptionForConnectExternalTicketingSystem = () => {
    const getDescription = (accountType) => `Raise tickets from within the familiar ${accountType} interface.`

    if (isSlackAccountType) {
      return getDescription('Slack')
    }
    if (isMsTeamsAccountType) {
      return getDescription('Microsoft Teams')
    }
  }

  return (
    <div className={styles.Container}>
      <div className={cx(styles.Cover, { [styles.CoverPro]: isProPlan, [styles.CoverEnterprise]: isEnterprisePlan })}>
        <Heading className={styles.Heading}>Welcome</Heading>
        <Heading className={styles.Heading}>{user.name}!</Heading>

        <div className={styles.Pricing}>
          <span className={styles.PlanBadge}>{planName} Plan</span>
          {!isEnterprisePlan && (
            <div className={styles.PlanButtonContainer}>
              <Button className={styles.PlanComparisionButton} onClick={() => openUrl(URLS.ASSIST_AI)}>
                Plan Comparison <RightAngleIcon width='1em' height='1em' />
              </Button>
              <Button className={styles.PlanComparisionButton} onClick={() => openUrl(URLS.ASSIST_AI)}>
                Upgrade Plan <RightAngleIcon width='1em' height='1em' />
              </Button>
            </div>
          )}
        </div>

        {isSlackAccountType && (
          <div className={styles.Tuts}>
            <Heading level={2} className={styles.Heading}>
              Getting started video
            </Heading>
            <TutImage className={styles.TutImage} onClick={() => setShowTutVideo(true)} />
            {showTutVideo && <YoutubeEmbed embedId='6tXRWfc5oHE' onClose={() => setShowTutVideo(false)} />}
          </div>
        )}
      </div>
      <div className={styles.Content}>
        <PageLoader isLoading={isLoading || isFetching}>
          <Heading className={styles.Heading}>
            Let's get Assist AI up <br /> and running!
          </Heading>

          <div className={styles.CardsContainer}>
            <Card>
              <CardContent
                title='Connect Knowledge Source'
                connected={dashboard.is_kb_integration_connected}
                subtext={getConnectedArticlesSubtext()}
              >
                Integrate your knowledge source with Assist AI to begin
                <br />
                answering questions.
              </CardContent>
              <CardAction>
                {dashboard.is_kb_integration_connected && (
                  <ActionConnectedButton onClick={() => navigate('/apps/category/knowledge-sources')}>
                    Connected
                  </ActionConnectedButton>
                )}
                {!dashboard.is_kb_integration_connected && (
                  <ActionButton onClick={() => navigate('/apps/category/knowledge-sources')}>Connect</ActionButton>
                )}
              </CardAction>
            </Card>

            <CardGroup title='Ticketing Systems'>
              <Card>
                <CardContent
                  title='Connect External Ticketing System'
                  connected={dashboard.is_ticketing_integration_connected}
                >
                  {getDescriptionForConnectExternalTicketingSystem()}
                </CardContent>
                <CardAction>
                  {dashboard.is_ticketing_integration_connected && (
                    <ActionConnectedButton onClick={() => navigate('/apps/category/ticketing-system')}>
                      Connected
                    </ActionConnectedButton>
                  )}
                  {!dashboard.is_ticketing_integration_connected && (
                    <ActionButton onClick={() => navigate('/apps/category/ticketing-system')}>Connect</ActionButton>
                  )}
                </CardAction>
              </Card>
              <Card>
                <CardContent title='Connect Conversational Ticketing'>
                  Add a human touch by integrating your conversational <br /> ticketing app to Assist AI.
                </CardContent>
                <CardAction>
                  <ConversationalTicketingAction />
                </CardAction>
              </Card>
            </CardGroup>
            {isDMWorkflowEnabled && <AnnouncementCard account={account} dashboard={dashboard} />}
          </div>
        </PageLoader>
      </div>
    </div>
  )
}

export default DashboardContent
