import React, { createContext, useContext, useEffect, useState } from 'react'

const WorkspaceContext = createContext()

export const WorkspaceProvider = ({ workspaces, children }) => {
  const [currentWorkspaceId, setCurrentWorkspaceId] = useState(() => {
    const savedWorkspaceId = localStorage.getItem('workspaceId')
    return savedWorkspaceId || null
  })

  useEffect(() => {
    if (!currentWorkspaceId || !workspaces.some((workspace) => workspace.id === currentWorkspaceId)) {
      const defaultWorkspace = workspaces.find((workspace) => workspace.settings?.default)
      if (defaultWorkspace) {
        setCurrentWorkspaceId(defaultWorkspace.id)
      }
    }
  }, [workspaces, currentWorkspaceId])

  useEffect(() => {
    if (currentWorkspaceId) {
      localStorage.setItem('workspaceId', currentWorkspaceId)
    }
  }, [currentWorkspaceId])

  const value = {
    workspaces,
    currentWorkspaceId,
    setCurrentWorkspaceId
  }

  return <WorkspaceContext.Provider value={value}>{children}</WorkspaceContext.Provider>
}

export const useWorkspace = () => {
  const context = useContext(WorkspaceContext)
  if (!context) {
    throw new Error('useWorkspace must be used within a WorkspaceProvider')
  }
  return context
}
