import { useState } from 'react'
import toast from 'react-hot-toast'

import api from 'Services/api'
import { useRedirectConfirmationContext } from 'Src/components/ConfirmationModal/RedirectConfirmationContext'
import { useWorkspace } from 'Utils/hooks/useWorkspace'
import { getDomainFromUrl } from 'Utils/url'

import ConfigureApp from '../../../components/ConfigureApp'
import InstallApp from '../../../components/InstallApp'
import { useAppDetailContext } from '../../AppDetailContext'

const InstallGDrive = (props) => {
  const [isInstalling, setIsInstalling] = useState(false)
  const [configureGDrive, configureGDriveResult] = api.useConfigureGDriveMutation()
  const { app } = props
  const { linked_email } = app
  const { title } = useAppDetailContext()
  const externalRedirect = useRedirectConfirmationContext()
  const { currentWorkspaceId } = useWorkspace()

  const url = new URL(app?.internal_redirect_url)
  url.searchParams.append('workspace_id', currentWorkspaceId)

  const handleInstall = () => {
    setIsInstalling(true)
    externalRedirect(url, {
      handleCancel: () => setIsInstalling(false),
      customDomain: getDomainFromUrl(app.external_redirect_url)
    })
  }

  const handleConfigure = async () => {
    try {
      const promise = configureGDrive({ workspace_id: currentWorkspaceId }).unwrap()
      toast.promise(promise, {
        loading: `Configuring ${title}`,
        success: `Configured ${title}`,
        error: `Unable to configure ${title}. Try again...`
      })
      await promise
    } catch {}
  }

  return linked_email ? (
    <ConfigureApp
      name={title}
      url={linked_email}
      onConfigure={handleConfigure}
      isConfiguring={configureGDriveResult.isLoading}
    />
  ) : (
    <InstallApp name={title} onInstall={handleInstall} isInstalling={isInstalling} />
  )
}

export default InstallGDrive
