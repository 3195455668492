import useToggleState from 'Utils/hooks/useToggleState'

import InstallApp from '../../../components/InstallApp'
import { useAppDetailContext } from '../../AppDetailContext'
import SimpplrAuthForm from './SimpplrAuthForm'

const InstallSimpplr = ({ app }) => {
  const { title } = useAppDetailContext()
  const [showCredentialsForm, toggleCredentialsForm] = useToggleState(false)

  if (showCredentialsForm) {
    return <SimpplrAuthForm onCancel={toggleCredentialsForm} app={app} />
  }

  return <InstallApp name={title} onInstall={toggleCredentialsForm} />
}

export default InstallSimpplr
