import { Button, Heading } from '@happyfoxinc/react-ui'
import { Fragment } from 'react'

import styles from './Common.module.css'

import { useFeature } from 'Components/Features'
import FEATURE_FLAGS from 'Constants/feature-flags'
import api from 'Services/api'

import { useAppDetailContext } from '../../AppDetails/AppDetailContext'
import LinkIcon from './link.svg'
import LinkedIcon from './linked.svg'

const UPGRADE_MESSAGES = {
  premium: {
    title: 'Your current plan does not support this integration',
    message: 'Upgrade your plan to connect.'
  },
  limitReached: {
    title: 'You have reached your Integration Limit.',
    message: 'Upgrade your plan to connect more integrations.'
  }
}

export const shouldShowUpgradePrompt = (planCategory, isPremiumAppsEnabled, account) => {
  if (planCategory === 'premium' && !isPremiumAppsEnabled) {
    return UPGRADE_MESSAGES.premium
  }
  if (account.is_integrations_limit_reached) {
    return UPGRADE_MESSAGES.limitReached
  }
  return null
}

export const ConnectedAppBase = ({ children, ...rest }) => {
  return (
    <div className={styles.Container} {...rest}>
      <LinkedIcon height='3em' width='3em' />
      {children}
    </div>
  )
}

export const AppBase = ({ name, message, buttonText, onAction, isLoading }) => (
  <div className={styles.Container}>
    <LinkIcon height='3em' width='3em' />
    <Fragment>
      <Heading level={2}>{message}</Heading>
      <Button variant='primary' onClick={onAction} disabled={isLoading}>
        {buttonText} {name}
      </Button>
    </Fragment>
  </div>
)

export const useAccountAndPlan = () => {
  const { data: account } = api.useGetAccountQuery()
  const { planCategory } = useAppDetailContext()
  const isPremiumAppsEnabled = useFeature([FEATURE_FLAGS.PREMIUM_INTEGRATIONS])

  return { account, planCategory, isPremiumAppsEnabled }
}
