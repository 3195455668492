import { Text } from '@happyfoxinc/react-ui'

import styles from './UserGroupBadge.module.scss'

import RightAngleIcon from 'Icons/arrow-right.svg'

import Badge from 'Components/Badge'
import { SecondaryButton } from 'Components/Buttons'
import { usePlanContext } from 'Components/Plan'
import Tooltip, { TooltipContent, TooltipTrigger } from 'Components/Tooltip'
import { URLS } from 'Constants/urls'
import { KNOWLEDGE_GROUP_VISIBILITY_STATES } from 'Constants/user-groups'
import { openUrl } from 'Utils/url'

const UserGroupBadge = ({ visibility, mapped_user_groups = [] }) => {
  const { isFreePlan } = usePlanContext()

  let text = 'No Users'
  let variant = 'purple'

  const { SPECIFIC, ALL, SAME_AS_PARENT, SAME_AS_ORIGIN } = KNOWLEDGE_GROUP_VISIBILITY_STATES

  if (visibility === SPECIFIC) {
    text = `${mapped_user_groups.length} User groups`
    variant = 'brown'
  } else if (visibility === ALL) {
    text = 'All Users'
    variant = 'green'
  } else if (visibility === SAME_AS_PARENT) {
    text = 'Same as parent'
    variant = 'green'
  } else if (visibility === SAME_AS_ORIGIN) {
    text = 'Same as origin'
    variant = 'green'
  }

  const userGroupNames = mapped_user_groups.map((ug) => ug.name).join(', ')

  return (
    <Tooltip delayDuration={1000}>
      <TooltipTrigger className={styles.TooltipTrigger}>
        {isFreePlan && <Badge text={text} />}
        {!isFreePlan && <Badge text={text} variant={variant} />}
      </TooltipTrigger>
      {isFreePlan && (
        <TooltipContent side='left' className={styles.UpgradeTooltip}>
          <Text variant='muted'>
            User Groups available only <br />
            in Pro and Enterprise plans.
          </Text>
          <SecondaryButton
            size='sm'
            className={styles.UpgradeButton}
            onClick={(e) => {
              e.stopPropagation()
              openUrl(URLS.ASSIST_AI)
            }}
          >
            Upgrade Plan <RightAngleIcon width='1em' height='1em' />
          </SecondaryButton>
        </TooltipContent>
      )}
      {userGroupNames && !isFreePlan && <TooltipContent side='left'>{userGroupNames}</TooltipContent>}
    </Tooltip>
  )
}

export default UserGroupBadge
