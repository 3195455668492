import { Button, FormControl, FormErrorText, Input, Label } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import Sheet from 'Layout/Sheet'
import api from 'Services/api'
import parseErrorMessage from 'Utils/error-message-parser'

import inviteAdminValidationSchema from './invite-admin-validation-schema'

const InviteAdminModal = (props) => {
  const { onHide } = props

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm({
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(inviteAdminValidationSchema)
  })

  const [inviteAdmin, inviteAdminResult] = api.useInviteAdminMutation()

  const handleInviteFormSubmit = async (data) => {
    try {
      const payload = {
        email: data.email
      }

      const promise = inviteAdmin(payload).unwrap()

      toast.promise(promise, {
        loading: 'Inviting admin',
        success: `Invited admin '${data.email}'. Successfully...`,
        error: parseErrorMessage(`Error while inviting admin '${data.email}'. Try again.`)
      })
      await promise
      onHide()
    } catch (error) {
      onHide()
    }
  }

  const disableSubmitButton = isSubmitting || inviteAdminResult.isLoading

  return (
    <Sheet title='Invite admin' onHide={onHide}>
      <form onSubmit={handleSubmit(handleInviteFormSubmit)}>
        <FormControl isInvalid={errors.email}>
          <Label>Email</Label>
          <Input {...register('email')} placeholder='' />
          {errors.email && <FormErrorText>{errors.email.message}</FormErrorText>}
        </FormControl>
        <Button variant='primary' type='submit' disabled={disableSubmitButton}>
          Submit
        </Button>
      </form>
    </Sheet>
  )
}

export default InviteAdminModal
