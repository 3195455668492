import { Flex, FormText } from '@happyfoxinc/react-ui'

import styles from './FormWarningText.module.scss'

import AlertIcon from 'Icons/alert.svg'

const FormWarningText = ({ children }) => {
  return (
    <Flex align='center' gap='sm' className={styles.Container}>
      <AlertIcon className={styles.Icon} />
      <FormText className={styles.Text}>{children}</FormText>
    </Flex>
  )
}

export default FormWarningText
