import { ACCOUNT_TYPE } from 'Constants/account'

export const FEATURE_FLAGS = {
  USER_GROUPS: 'user_groups',
  CONVERSATIONAL_TICKETING: 'conversational_ticketing',
  PREMIUM_INTEGRATIONS: 'premium_integrations',
  REPORTS: 'reports'
}

// Enabled prefix represents computed property from boolean flag
// may not sound well for a feature flag, but 🤷
export const CLIENT_FEATURE_FLAGS = {
  ENABLE_TICKETING: 'enable_ticketing',
  DM_WORKFLOW_MODE: 'dm_workflow_mode',
  SLACK_ACCOUNT: ACCOUNT_TYPE.SLACK,
  MS_TEAMS_ACCOUNT: ACCOUNT_TYPE.MS_TEAMS,
  SETTINGS: 'settings',
  CUSTOMISE_CHANNELS: 'customise_channels',
  ACTIONS: 'enable_ai_actions',
  WORKSPACES: 'workspaces'
}

export default FEATURE_FLAGS
