import { Button, styled } from '@happyfoxinc/react-ui'

export const SecondaryButton = styled(Button, {
  borderColor: '$accentSolid',
  color: 'black',
  fontWeight: '$normal',
  backgroundColor: 'white',

  '&:hover': {
    backgroundColor: '$accentSolid',
    color: 'white'
  }
})

SecondaryButton.defaultProps = {
  type: 'button'
}

export const SuccessButton = styled(Button, {
  fontWeight: '$normal',

  defaultVariants: {
    variant: 'success'
  }
})

SuccessButton.defaultProps = {
  type: 'button'
}
