import React, { useState } from 'react'
import ReactSelect, { components } from 'react-select'

import styles from './Filters.module.scss'

import { WorkspaceIcon } from '../assets'

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <WorkspaceIcon className={styles.Icon} />
      {children}
    </components.Control>
  )
}

const WorkspaceDropdown = (props) => {
  const {
    onChange,
    workspaces,
    default_value = null,
    placeholder = 'Workspace',
    isClearable = false,
    ...restProps
  } = props

  const [selectedWorkspace, setSelectedWorkspace] = useState(default_value)

  const customStyles = {
    container: (base) => ({
      ...base,
      width: '210px'
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? 'var(--primary-solid)' : 'var(--border-primary-color)',
      '&:hover': { borderColor: state.isFocused ? 'var(--primary-solid)' : 'var(--border-primary-color)' },
      height: '36px',
      minHeight: '36px',
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
      boxShadow: 'none'
    }),
    valueContainer: (base) => ({
      ...base,
      marginLeft: '22px',
      padding: '0 8px 0 0'
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? 'var(--primary-light)' : 'white',
      color: 'black',
      '&:hover': { backgroundColor: state.isSelected ? 'var(--primary-light)' : 'var(--primary-lighter)' }
    }),
    singleValue: (base) => ({
      ...base,
      maxWidth: 'calc(100% - 22px)'
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: '100%'
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none'
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: '0 8px'
    })
  }

  const reactSelectProps = {
    isSearchable: false,
    styles: customStyles,
    classNamePrefix: 'ws-dropdown',
    value: workspaces.find((option) => option.value === selectedWorkspace),
    options: workspaces,
    placeholder,
    isClearable,
    onChange: (option) => {
      setSelectedWorkspace(option?.value)
      onChange(option?.value)
    },
    getOptionLabel: (option) => option.label,
    getOptionValue: (option) => option.value,
    components: { Control },
    ...restProps
  }

  return <ReactSelect {...reactSelectProps} />
}

export default WorkspaceDropdown
