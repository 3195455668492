// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pYEQvp470Cj9kCKjGGh5{display:flex;justify-content:space-between}.jOzvOq1dXrUO4wicOCQz{margin-top:1rem}.g2IqphAXsgfYiIAmbeiP{width:50%}.No_dqZRURUxqQSVZyZDG{margin-top:2rem}.tEb_XZjfyrXlMbkMIKKc{display:flex;flex-direction:column;gap:.7rem;align-items:center}.IJdHafdgopkPjYCv5ROs{display:flex;align-items:center;gap:.4rem}.w0kGdJNOqeQ1SksO7lwe{border:none;background-color:unset;cursor:not-allowed}.w0kGdJNOqeQ1SksO7lwe>*{cursor:not-allowed}.LxJftPmsOafOQ9y0QPl6[disabled]{cursor:not-allowed}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/AppDetails/apps/HappyFoxHelpdesk/ManageHappyFoxHelpdesk.module.css"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CAGF,sBACE,eAAA,CAGF,sBACE,SAAA,CAGF,sBACE,eAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,SAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,SAAA,CAGF,sBACE,WAAA,CACA,sBAAA,CACA,kBAAA,CAGF,wBACE,kBAAA,CAGF,gCACE,kBAAA","sourcesContent":[".FlexLabel {\n  display: flex;\n  justify-content: space-between;\n}\n\n.SaveButton {\n  margin-top: 1rem;\n}\n\n.Status {\n  width: 50%;\n}\n\n.Toggle {\n  margin-top: 2rem;\n}\n\n.UpgradeTooltip {\n  display: flex;\n  flex-direction: column;\n  gap: 0.7rem;\n  align-items: center;\n}\n\n.UpgradeButton {\n  display: flex;\n  align-items: center;\n  gap: 0.4rem;\n}\n\n.TooltipTrigger {\n  border: none;\n  background-color: unset;\n  cursor: not-allowed;\n}\n\n.TooltipTrigger > * {\n  cursor: not-allowed;\n}\n\n.Switch[disabled] {\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlexLabel": "pYEQvp470Cj9kCKjGGh5",
	"SaveButton": "jOzvOq1dXrUO4wicOCQz",
	"Status": "g2IqphAXsgfYiIAmbeiP",
	"Toggle": "No_dqZRURUxqQSVZyZDG",
	"UpgradeTooltip": "tEb_XZjfyrXlMbkMIKKc",
	"UpgradeButton": "IJdHafdgopkPjYCv5ROs",
	"TooltipTrigger": "w0kGdJNOqeQ1SksO7lwe",
	"Switch": "LxJftPmsOafOQ9y0QPl6"
};
export default ___CSS_LOADER_EXPORT___;
