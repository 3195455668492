import * as yup from 'yup'

import noQueryParamsInUrl from 'Utils/inputValidation/noQueryParamsInUrl'

const schema = yup
  .object()
  .shape({
    accountUrl: yup
      .string()
      .trim()
      .required('Account URL is required')
      .url('Invalid account url')
      .test(noQueryParamsInUrl(`Account URL`))
  })
  .required()

export default schema
