export const openUrl = (url) => {
  return window.open(url, '_blank')
}

export const externalRedirect = (url) => {
  window.location.href = url
}

export const getDomainFromUrl = (url) => (url ? new URL(url).origin : '')

export const redirect = (path) => {
  const normalizedPath = `app/${path}`.replace(/\/{2,}/gi, '/')
  const url = new URL(normalizedPath, window.location.origin)
  window.location.href = url.href
}
