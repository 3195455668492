import { Link as HfLink, styled } from '@happyfoxinc/react-ui'

const Link = styled(HfLink, {
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline'
  },

  variants: {
    variant: {
      'primary-dark': {
        color: 'var(--primary-extra-dark)'
      }
    },
    noUnderline: {
      true: {
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'none'
        }
      }
    }
  }
})

export default Link
