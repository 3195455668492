// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sT33PBJwlcK4meo4xLwG{font-size:var(--hrx-fontSizes-sm)}.dMA8gNCR05G82J2LWFSm{background-color:#fff5f6;padding:1rem;margin-bottom:1rem;border-radius:var(--hrx-radii-rounded);display:flex;gap:1rem}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/AppDetails/apps/HappyFoxHelpdesk/HappyFoxHelpdesk.module.css"],"names":[],"mappings":"AAAA,sBACE,iCAAA,CAGF,sBACE,wBAAA,CACA,YAAA,CACA,kBAAA,CACA,sCAAA,CACA,YAAA,CACA,QAAA","sourcesContent":[".EnableSyncContainer {\n  font-size: var(--hrx-fontSizes-sm);\n}\n\n.SyncAlert {\n  background-color: #fff5f6;\n  padding: 1rem;\n  margin-bottom: 1rem;\n  border-radius: var(--hrx-radii-rounded);\n  display: flex;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EnableSyncContainer": "sT33PBJwlcK4meo4xLwG",
	"SyncAlert": "dMA8gNCR05G82J2LWFSm"
};
export default ___CSS_LOADER_EXPORT___;
