import { TableOption, TableOptions } from '@happyfoxinc/react-ui'
import React, { Fragment, useCallback, useState } from 'react'
import toast from 'react-hot-toast'

import ConfirmationModal from 'Components/ConfirmationModal'
import { CONFIRM_MESSAGES } from 'Constants/messages'
import api from 'Services/api'
// import EditAdmin from '../EditAdmin'

const AdminOptions = (props) => {
  const { id, admin } = props
  // const { id } = props

  // const [showEditAdminModal, setShowEditAdminModal] = useState(false)
  const [deleteAdmin] = api.useDeleteAdminMutation()
  const [resendInvitation] = api.useResendInvitationMutation()
  // const { data: currentUser } = api.useGetProfileQuery()

  const handleResendInvitation = useCallback(() => {
    const promise = resendInvitation(id).unwrap()

    toast.promise(promise, {
      loading: 'Resending invitation...',
      success: 'Invitation resent successfully.',
      error: 'Unable to resend invitation. Try again....'
    })
  }, [id, resendInvitation])

  const [isDeletelModalOpen, setIsDeleteModalOpen] = useState(false)
  const handleDeleteClick = useCallback(() => {
    const promise = deleteAdmin(id).unwrap()
    promise.then(() => setIsDeleteModalOpen(false))

    toast.promise(promise, {
      loading: 'Deleting admin...',
      success: 'Admin deleted successfully.',
      error: 'Unable to delete admin. Try again....'
    })
  }, [id, deleteAdmin])

  // const handleEditAdminClick = () => {
  //   setShowEditAdminModal(true)
  // }

  // const handleHideEditAdminModal = () => {
  //   setShowEditAdminModal(false)
  // }

  return (
    <Fragment>
      {/* {showEditAdminModal && <EditAdmin onHide={handleHideEditAdminModal} admin={admin} />} */}
      <TableOptions>
        {/* {currentUser.id === id && <TableOption onClick={handleEditAdminClick}>Edit</TableOption>} */}
        {admin.status === 'expired' && <TableOption onClick={handleResendInvitation}>Resend Invitation</TableOption>}
        <TableOption onClick={() => setIsDeleteModalOpen(true)}>Delete</TableOption>
      </TableOptions>
      <ConfirmationModal
        isOpen={isDeletelModalOpen}
        variant='alert'
        message={CONFIRM_MESSAGES.DELETE_ADMIN}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteClick}
      />
    </Fragment>
  )
}

export default AdminOptions
