// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PtmSC7WAUtgTIctwafiW{margin-top:2rem}._ihQjVmgTChrxsOQdwpn{display:flex;align-items:center;gap:20px;margin-right:auto}.gJFA_vndKsIXWC67_0wf{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.O_BVB8J42RdCJnxvh7A8{width:250px}.czB_76Yc6uX1wPtyEX5C{width:500px}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Actions/ActionsList/ActionsList.module.css"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,iBAAA,CAGF,sBACE,kBAAA,CACA,eAAA,CACA,sBAAA,CAGF,sBACE,WAAA,CAGF,sBACE,WAAA","sourcesContent":[".Container {\n  margin-top: 2rem;\n}\n\n.SearchInputContainer {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  margin-right: auto;\n}\n\n.TruncateCell {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.NameCell {\n  width: 250px;\n}\n\n.DescriptionCell {\n  width: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "PtmSC7WAUtgTIctwafiW",
	"SearchInputContainer": "_ihQjVmgTChrxsOQdwpn",
	"TruncateCell": "gJFA_vndKsIXWC67_0wf",
	"NameCell": "O_BVB8J42RdCJnxvh7A8",
	"DescriptionCell": "czB_76Yc6uX1wPtyEX5C"
};
export default ___CSS_LOADER_EXPORT___;
