import { Button, Flex, Heading, Text } from '@happyfoxinc/react-ui'
import cx from 'classnames'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useSearchParams } from 'react-router-dom'

import styles from './Reports.module.scss'

import { SecondaryButton } from 'Components/Buttons'
import DateRangePicker from 'Components/DateRangePicker'
import { getPredefinedDateRanges, RANGES } from 'Components/DateRangePicker/config'
import Modal, { ModalBody, ModalHeader } from 'Components/Modal'
import PageLoader from 'Components/PageLoader'
import { usePlanContext } from 'Components/Plan'
import { ACCOUNT_TYPE } from 'Constants/account'
import { TOAST_MESSAGES } from 'Constants/messages'
import { URLS } from 'Constants/urls'
import api from 'Services/api'
import { paramsSerializer } from 'Src/api'
import { useWorkspace } from 'Src/utils/hooks/useWorkspace'
import { customDates } from 'Utils/date'
import dayjs from 'Utils/dayjs-helper'
import { openUrl } from 'Utils/url'

import { ExportIcon, RightAngleIcon } from './assets'
import TopicDropdown from './Filters/Topic'
import WorkspaceDropdown from './Filters/Workspace'
import { MetricTile, MetricTileTaller, MetricTileWider } from './MetricCards'
import metricsData from './metrics-data'

const getPrimaryButtonVariant = (variant) => {
  switch (variant) {
    case 'alert':
      return 'danger'
    default:
      return 'primary'
  }
}

const ConfirmationModal = ({
  onConfirm,
  message,
  title,
  isOpen,
  variant,
  confirmButtonText = 'Ok',
  width = 350,
  buttonAlignment = 'center'
}) => {
  if (!isOpen) {
    return null
  }

  return (
    <Modal
      onRequestClose={onConfirm}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc
      isOpen
      style={{ content: { width, padding: '2rem' } }}
    >
      <ModalHeader onClose={onConfirm}>
        {title && (
          <Text className={styles.Title} weight='bold'>
            {title}
          </Text>
        )}
      </ModalHeader>
      <ModalBody className={styles.ConfirmationModalBody}>
        <div className={styles.Message}>{message}</div>
        <div className={cx(styles.ButtonContainer, { [styles.LeftAlign]: buttonAlignment === 'left' })}>
          {confirmButtonText && (
            <Button onClick={onConfirm} variant={getPrimaryButtonVariant(variant)}>
              {confirmButtonText}
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

const formatDateRange = ({ from, to }) => {
  return {
    from: dayjs(from).format(),
    to: dayjs(to).format()
  }
}

const getFilterParams = (searchParams, last30DaysRange, predefinedDateRanges) => {
  const workspace = searchParams.get('workspace')
  const topic = searchParams.get('topic')
  const dateRangeKey = searchParams.get('date_range')
  // Date range "from date" and "to date" will be only required for custom date range because user will select the range
  // For other date ranges, "from date" and "to date" will be calculated based on the range key
  const dateRangeFrom = searchParams.get('from')
  const dateRangeTo = searchParams.get('to')

  let dateRange
  if (dateRangeKey === RANGES.CUSTOM) {
    dateRange = {
      ...predefinedDateRanges.find(({ key }) => key === RANGES.CUSTOM),
      range: {
        from: dateRangeFrom ? new Date(dateRangeFrom) : last30DaysRange.range.from,
        to: dateRangeTo ? new Date(dateRangeTo) : last30DaysRange.range.to
      }
    }
  } else {
    dateRange = predefinedDateRanges.find(({ key }) => key === dateRangeKey) || last30DaysRange
  }

  return {
    workspace,
    topic,
    dateRange
  }
}

const Reports = () => {
  const { isEnterprisePlan } = usePlanContext()
  const isNonEnterprisePlan = !isEnterprisePlan

  const { data: account = {} } = api.useGetAccountQuery()
  const accountType = account?.account_type === ACCOUNT_TYPE.SLACK ? 'Slack' : 'MS Teams'
  const isTicketingSystemConnected = account.is_ticketing_integration_connected
  const trackExternalTickets = account.track_external_tickets
  const showSuggestionsForExternalTickets = account.show_suggestion_for_external_tickets
  const isTicketingSystemConnectedAndEnabled = isTicketingSystemConnected && trackExternalTickets
  const showAutoResolutionMetrics =
    isEnterprisePlan && isTicketingSystemConnectedAndEnabled && showSuggestionsForExternalTickets
  const allowedStartDate = account.allowed_reporting_start_date

  const predefinedDateRanges = useMemo(() => getPredefinedDateRanges(allowedStartDate), [allowedStartDate])

  const last30DaysRange = useMemo(
    () => predefinedDateRanges.find(({ key }) => key === RANGES.LAST_30_DAYS),
    [predefinedDateRanges]
  )

  const [searchParams, setSearchParams] = useSearchParams()
  const { workspaces } = useWorkspace()
  const workspaceOptions = [
    { label: 'All Workspaces', value: null },
    ...workspaces.map(({ id, name }) => ({ label: name, value: id }))
  ]

  const { data: topics = [] } = api.useGetAllTopicsQuery()
  const topicOptions = [
    { label: 'All Topics', value: null },
    ...topics.map(({ id, name }) => ({ label: name, value: id }))
  ]

  const { workspace, topic, dateRange } = useMemo(
    () => getFilterParams(searchParams, last30DaysRange, predefinedDateRanges),
    [searchParams, last30DaysRange, predefinedDateRanges]
  )
  const [dateRangeObj, setDateRangeObj] = useState(dateRange)
  const [workspaceFilter, setWorkspaceFilter] = useState(workspace)
  const [topicFilter, setTopicFilter] = useState(topic)

  const getDefaultQueryParams = useCallback(() => {
    const dateRangeParams = formatDateRange(dateRangeObj.range)
    return {
      ...dateRangeParams,
      workspace: workspaceFilter,
      topic: topicFilter
    }
  }, [dateRangeObj, workspaceFilter, topicFilter])

  const [queryParams, setQueryParams] = useState(getDefaultQueryParams)
  const [showExportMsgModel, setShowExportMsgModel] = useState(false)

  const [exportReports] = api.useExportReportMetricsMutation()
  const { isLoading, isFetching, data = {} } = api.useGetReportMetricsQuery({ ...queryParams })
  const reports = { ...data }

  if (!isTicketingSystemConnectedAndEnabled) {
    reports.requests_from_other_sources_disabled = true
    reports.tickets_created_from_other_sources_disabled = true
  }

  if (isNonEnterprisePlan) {
    reports.suggestions_from_other_sources_not_applicable = true
    reports.resolved_requests_with_feedback_from_other_sources_not_applicable = true
  } else if (!(isTicketingSystemConnectedAndEnabled && showSuggestionsForExternalTickets)) {
    reports.suggestions_from_other_sources_disabled = true
    reports.resolved_requests_with_feedback_from_other_sources_disabled = true
  }

  const updateSearchParams = useCallback(
    (dateRangeKey, workspace, topic, range = null) => {
      const data = {
        date_range: dateRangeKey
      }
      if (workspace) {
        data.workspace = workspace
      }
      if (topic) {
        data.topic = topic
      }
      if (dateRangeKey === RANGES.CUSTOM && range) {
        data.from = new Date(range.from)
        data.to = new Date(range.to)
      }
      setSearchParams(data)
    },
    [setSearchParams]
  )

  useEffect(() => {
    if (
      searchParams.get('workspace') === workspaceFilter &&
      searchParams.get('topic') === topicFilter &&
      searchParams.get('date_range') === dateRangeObj.key
    ) {
      return
    }

    updateSearchParams(dateRangeObj.key, workspaceFilter, topicFilter, dateRangeObj.range)
  }, [workspaceFilter, topicFilter, dateRangeObj, searchParams, updateSearchParams])

  const updateQueryParams = useCallback((newParams) => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      ...newParams
    }))
  }, [])

  const handleDateRangeChange = useCallback(
    (value) => {
      setDateRangeObj(value)
      const { range } = value
      updateQueryParams(formatDateRange(range))
      updateSearchParams(value.key, workspaceFilter, topicFilter, range)
    },
    [updateQueryParams, updateSearchParams, workspaceFilter, topicFilter]
  )

  const handleOnChangeWorkspace = useCallback(
    (id) => {
      setWorkspaceFilter(id)
      updateQueryParams({ workspace: id })
      updateSearchParams(dateRangeObj.key, id, topicFilter, dateRangeObj.range)
    },
    [updateQueryParams, updateSearchParams, dateRangeObj.key, dateRangeObj.range, topicFilter]
  )

  const handleOnChangeTopic = useCallback(
    (id) => {
      setTopicFilter(id)
      updateQueryParams({ topic: id })
      updateSearchParams(dateRangeObj.key, workspaceFilter, id, dateRangeObj.range)
    },
    [updateQueryParams, updateSearchParams, dateRangeObj.key, dateRangeObj.range, workspaceFilter]
  )

  const onExport = useCallback(() => {
    const payload = {
      from: queryParams.from,
      to: queryParams.to,
      tz_offset: queryParams.tz_offset
    }

    if (queryParams.workspace) {
      payload.workspace = queryParams.workspace
    }

    if (queryParams.topic) {
      payload.topic = queryParams.topic
    }

    const promise = exportReports(payload).unwrap()
    let toast_id = toast.loading(TOAST_MESSAGES.EXPORT_REPORTS_LOADING)
    promise
      .then(() => {
        toast.dismiss(toast_id)
        setShowExportMsgModel(true)
      })
      .catch(() => {
        toast.dismiss(toast_id)
        toast_id = toast.error(TOAST_MESSAGES.EXPORT_REPORTS_ERROR)
      })
  }, [queryParams, exportReports])

  const getFilteredResultText = () => {
    const { from, to } = dateRangeObj.range
    const fromFormatted = dayjs(from).format('MMM DD, YYYY')
    const toFormatted = dayjs(to).format('MMM DD, YYYY')

    const topicName = topics.find(({ id }) => id === topicFilter)?.name?.toLowerCase()
    const workspaceName = workspaces.find(({ id }) => id === workspaceFilter)?.name?.toLowerCase()

    let message = `Showing data`

    if (workspaceName && topicName) {
      message += ` for the ${workspaceName} workspace on the ${topicName} topic`
    } else if (workspaceName) {
      message += ` for the ${workspaceName} workspace`
    } else if (topicName) {
      message += ` for the ${topicName} topic`
    }

    message += ` from ${fromFormatted}, to ${toFormatted}`

    return message + '.'
  }

  const closeExportMsgModel = () => {
    setShowExportMsgModel(false)
  }

  const getSuccessMessage = () => {
    const { from, to } = dateRangeObj.range
    const fromFormatted = dayjs(from).format('MMM DD, YYYY')
    const toFormatted = dayjs(to).format('MMM DD, YYYY')
    const workspaceName = workspaces.find(({ id }) => id === workspaceFilter)?.name?.toLowerCase()
    const topicName = topics.find(({ id }) => id === topicFilter)?.name?.toLowerCase()
    const messages = TOAST_MESSAGES.EXPORT_REPORTS(fromFormatted, toFormatted, accountType, workspaceName, topicName)
    return messages.success
  }

  const downloadUrlForArticlesRequested = `/api/v1/requested-kb/export?${paramsSerializer(queryParams)}`

  const options = useMemo(
    () => ({
      mode: 'range',
      numberOfMonths: 1,
      disableMatcher: { after: customDates.now(), before: dayjs(allowedStartDate).toDate() },
      showPredefinedRanges: true,
      minDate: dayjs(allowedStartDate).toDate(),
      classNames: {
        dropdown: styles.DateRangeDropdown
      }
    }),
    [allowedStartDate]
  )

  return (
    <Fragment>
      <Heading>Reports</Heading>
      <Flex align='center' justify='between'>
        <Text variant='muted'>Overview of various metrics tied to your account.</Text>
      </Flex>
      <Flex direction='r' align='start' className={styles.FiltersWrapper}>
        <div className={styles.FilterContainer}>
          {account?.is_workspaces_enabled && (
            <WorkspaceDropdown
              placeholder='Workspace'
              workspaces={workspaceOptions}
              default_value={workspaceFilter}
              className={styles.FilterWorkspaceDropdown}
              onChange={handleOnChangeWorkspace}
            />
          )}
          {topics && topics.length > 0 && (
            <TopicDropdown
              placeholder='Topic'
              topics={topicOptions}
              default_value={topicFilter}
              className={styles.FilterTopicDropdown}
              onChange={handleOnChangeTopic}
            />
          )}
          <DateRangePicker value={dateRangeObj} onChange={handleDateRangeChange} {...options} />
        </div>
        <div className={styles.ExportBtnContainer}>
          <SecondaryButton type='button' className={styles.ExportBtn} size='sm' as='a' onClick={onExport}>
            Export
            <ExportIcon className={styles.ExportIcon} />
          </SecondaryButton>
        </div>
      </Flex>
      <Flex align='center' justify='between'>
        <Text variant='muted' style={{ marginTop: '1rem' }}>
          {getFilteredResultText()}
        </Text>
      </Flex>
      <PageLoader isLoading={isLoading || isFetching}>
        <div className={styles.TileContainer}>
          {metricsData(reports, accountType, downloadUrlForArticlesRequested, showAutoResolutionMetrics, styles).map(
            (metric, index) => {
              const MetricComponent =
                {
                  MetricTileWider,
                  MetricTileTaller,
                  MetricTile
                }[metric.type] || MetricTile
              return <MetricComponent key={index} {...metric} />
            }
          )}
        </div>

        {isNonEnterprisePlan && (
          <div className={styles.CallToAction}>
            <span className={styles.Badge}>N/A*</span> <span>Available only in Enterprise plan.</span>
            <SecondaryButton
              size='sm'
              className={styles.UpgradeButton}
              onClick={() => {
                openUrl(URLS.ASSIST_AI)
              }}
            >
              Upgrade Plan <RightAngleIcon width='1em' height='1em' />
            </SecondaryButton>
          </div>
        )}
      </PageLoader>
      <ConfirmationModal
        isOpen={showExportMsgModel}
        title='Export Reports'
        message={getSuccessMessage()}
        width={380}
        onConfirm={closeExportMsgModel}
      />
    </Fragment>
  )
}

export default Reports
