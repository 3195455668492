// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kSGx3_ORO8R_AnTLEyhv{padding:1rem 1.2rem}.TkTQDBAdCs17iFa8kxY5{color:var(--default-text)}.ifSMhnIJhyeM14KS1mZo{opacity:58%}.ifSMhnIJhyeM14KS1mZo .oYF8lGyfHOwJBF3V9oSd{height:1px;background-color:#000;opacity:10%;width:95%;display:inline-block}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Channels/TipToAddInChannels/TipToAddInChannels.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CAGF,sBACE,yBAAA,CAGF,sBACE,WAAA,CACA,4CACE,UAAA,CACA,qBAAA,CACA,WAAA,CACA,SAAA,CACA,oBAAA","sourcesContent":[".TooltipContent {\n  padding: 1rem 1.2rem;\n}\n\n.StrongText {\n  color: var(--default-text);\n}\n\n.DividerContainer {\n  opacity: 58%;\n  .Divider {\n    height: 1px;\n    background-color: black;\n    opacity: 10%;\n    width: 95%;\n    display: inline-block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TooltipContent": "kSGx3_ORO8R_AnTLEyhv",
	"StrongText": "TkTQDBAdCs17iFa8kxY5",
	"DividerContainer": "ifSMhnIJhyeM14KS1mZo",
	"Divider": "oYF8lGyfHOwJBF3V9oSd"
};
export default ___CSS_LOADER_EXPORT___;
