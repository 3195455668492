import { Button } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Navigate, useParams } from 'react-router-dom'

import styles from './Actions.module.css'

import PageLoader from 'Components/PageLoader'
import PageTitle from 'Components/PageTitle'
import api from 'Services/api'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

import ActionForm from './ActionForm'
import { actionFormValidationSchema } from './ActionForm/action-form-validation-schema'

const EditActionFormHandler = (props) => {
  const { action } = props
  const { currentWorkspaceId } = useWorkspace()

  const formMethods = useForm({
    defaultValues: {
      title: action.title,
      description: action.description,
      inputs: action.inputs,
      outputs: action.outputs,
      steps: action.steps,
      is_active: action.is_active,
      parameters: [],
      message: '',
      workspace_id: currentWorkspaceId
    },
    resolver: yupResolver(actionFormValidationSchema)
  })

  const [updateAIAction, updateAIActionResult] = api.useUpdateAIActionMutation()

  const handleFormSubmit = useCallback(
    (data) => {
      const payload = {
        title: data.title,
        description: data.description,
        steps: data.steps,
        inputs: data.inputs.filter((input) => input.name.trim() !== ''),
        outputs: data.outputs,
        is_active: data.is_active,
        workspace_id: data.workspace_id
      }

      const promise = updateAIAction({ id: action.id, payload }).unwrap()

      toast.promise(promise, {
        loading: 'Updating AI Action',
        success: 'Successfully Updated AI Action',
        error: 'Unable to update AI Action'
      })
    },
    [action.id, updateAIAction]
  )

  return (
    <FormProvider {...formMethods}>
      <ActionForm isEditMode onSubmit={handleFormSubmit} isLoading={updateAIActionResult.isLoading} />
    </FormProvider>
  )
}

const EditActions = () => {
  const { id } = useParams()
  const { data, isLoading } = api.useGetAIActionByIdQuery(id)

  const [toggleAIActionActivation] = api.useToggleAIActionActivationMutation()

  const handleEnabledStatusToggle = useCallback(() => {
    const status = data?.is_active
    const newStatus = !status ? 'activate' : 'deactivate'

    const promise = toggleAIActionActivation({ id, newStatus }).unwrap()

    toast.promise(promise, {
      loading: `${newStatus} AI action`,
      success: `AI action ${newStatus}d successfully`,
      error: `Unable to ${newStatus} AI action. Try again...`
    })
  }, [data?.is_active, id, toggleAIActionActivation])

  if (isLoading) {
    return <PageLoader />
  }

  if (!data) {
    return <Navigate to='..' />
  }
  return (
    <Fragment>
      <PageTitle backTo='../'>Edit Action</PageTitle>
      {!data.is_active && (
        <div className={styles.InfoContainer}>
          <p className={styles.InfoText}>
            This action is currently inactive. To make use of it, please activate the action first.
          </p>
          <Button size='sm' variant='primary' onClick={handleEnabledStatusToggle}>
            Activate Action
          </Button>
        </div>
      )}
      <div className={styles.Container}>
        <EditActionFormHandler action={data} />
      </div>
    </Fragment>
  )
}

export default EditActions
