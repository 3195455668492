const importAllServices = (r) => {
  const reducers = {}
  const middlewares = []

  r.keys().forEach((key) => {
    const api = r(key).default

    if (!api) {
      return
    }

    reducers[api.reducerPath] = api.reducer
    middlewares.push(api.middleware)
  })

  return {
    reducers,
    middlewares
  }
}

export const getServices = () => {
  const allReducers = {}
  const allMiddlewares = []

  const deepServices = importAllServices(require.context('./', true, /\.service.js$/))
  Object.assign(allReducers, deepServices.reducers)
  allMiddlewares.push(...deepServices.middlewares)

  const directServices = importAllServices(require.context('./services', false, /\.js$/))
  Object.assign(allReducers, directServices.reducers)
  allMiddlewares.push(...directServices.middlewares)

  return {
    reducers: allReducers,
    middlewares: allMiddlewares
  }
}

export default {
  getServices
}
