import _ from 'lodash'
import React, { createContext, useContext } from 'react'

import api from 'Services/api'

const defaultContextValue = {
  metaData: {},
  dropdownOptions: {
    fields: [],
    values: []
  },
  isLoading: true
}

const AccessConditionContext = createContext(defaultContextValue)

export const AccessConditionProvider = ({ children }) => {
  const { data = {}, isLoading } = api.useGetUserGroupMetaInfoQuery()
  const { condition_fields = null } = data

  const valueOptions = condition_fields
    ? condition_fields.reduce((acc, field) => {
        acc[field.id] = field.allowed_values.map((value) => ({
          id: value,
          name: _.startCase(value)
        }))
        return acc
      }, {})
    : []

  const fieldOptions = condition_fields
    ? condition_fields.map((field) => ({
        id: field.id,
        name: _.startCase(field.name)
      }))
    : []

  const context = {
    metaData: data,
    dropdownOptions: {
      fields: fieldOptions,
      values: valueOptions
    },
    isLoading
  }

  return <AccessConditionContext.Provider value={context}>{children}</AccessConditionContext.Provider>
}

export const useAccessCondition = () => useContext(AccessConditionContext)
