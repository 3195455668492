import { Route, Routes, useNavigate } from 'react-router-dom'

import PageLoader from 'Components/PageLoader'
import api from 'Services/api'

import InstallOutline from './InstallOutline'
import ManageOutline from './ManageOutline'
import OutlineAuthForm from './OutlineAuthForm'

const Outline = () => {
  const { isLoading, data: app = {} } = api.useGetOutlineAppQuery()
  const navigate = useNavigate()

  if (isLoading) {
    return <PageLoader />
  }

  if (!app?.is_linked) {
    return <InstallOutline app={app} />
  }

  const handleAuthFormCancel = () => {
    navigate('./')
  }

  return (
    <Routes>
      <Route
        path='auth'
        element={<OutlineAuthForm onCancel={handleAuthFormCancel} onAuth={handleAuthFormCancel} app={app} />}
      />
      <Route path='*' element={<ManageOutline app={app} />} />
    </Routes>
  )
}

export default Outline
