import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorText,
  Label,
  TableOption,
  TableOptions,
  Text,
  useBoolean
} from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import cx from 'classnames'
import React, { forwardRef, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import styles from './GDriveSyncTable.module.scss'

import AlertIcon from 'Icons/alert.svg'
import ArrowRightIcon from 'Icons/arrow-right.svg'

import Modal, { ModalBody, ModalHeader } from 'Components/Modal'
import PageLoader from 'Components/PageLoader'
import ReactSelect from 'Components/ReactSelect'
import UserGroupBadge from 'Components/UserGroupBadge'
import { GOOGLE_DRIVE_VISIBILITY_OPTIONS, KNOWLEDGE_GROUP_VISIBILITY_STATES } from 'Constants/user-groups'
import { api } from 'Services/api'
import knowledgeConfigurationValidationSchema from 'Src/pages/protected/Apps/KnowledgeConfiguration/knowledge-configuration-validation-schema'
import parseErrorMessage from 'Utils/error-message-parser'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

import { useAppDetailContext } from '../../../AppDetailContext'

export const Accordion = AccordionPrimitive.Root
export const AccordionItem = AccordionPrimitive.Item
const AccordionHeader = AccordionPrimitive.Header
export const AccordionTrigger = forwardRef((props, ref) => {
  return <AccordionPrimitive.Trigger {...props} className={cx(styles.AccordionTrigger, props.className)} ref={ref} />
})
AccordionTrigger.displayName = 'AccordionTrigger'
export const AccordionContent = AccordionPrimitive.Content

const VisibilityModal = ({ responseGroup, onClose, hasUserGroups, workspaceId }) => {
  const navigate = useNavigate()
  const appName = 'gdrive'
  const selectPortalRef = useRef()

  const {
    visibility,
    possible_visibilities: possibleVisibilities,
    mapped_user_groups: mappedUserGroups,
    user_groups_available_to_map: userGroupsAvailableToMap,
    title,
    id: responseGroupId
  } = responseGroup

  const [updateResponseGroup, updateResponseGroupResult] = api.useUpdateResponseGroupMutation()

  const [checkResponseGroupVisibilityUpdate, checkResponseGroupVisibilityUpdateResult] =
    api.useLazyCheckResponseGroupVisibilityUpdateQuery()

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm({
    defaultValues: {
      visibility,
      userGroups: mappedUserGroups
    },
    resolver: yupResolver(knowledgeConfigurationValidationSchema)
  })

  const visibilityStatuses = useMemo(() => {
    return GOOGLE_DRIVE_VISIBILITY_OPTIONS.filter((status) => {
      return possibleVisibilities.includes(status.value)
    })
  }, [possibleVisibilities])

  const handleFormSubmit = (data) => {
    const payload = {
      appName,
      id: responseGroupId,
      workspace_id: workspaceId,
      visibility: data.visibility
    }

    if (data.userGroups) {
      payload.mapped_user_groups = data.userGroups.map((usergroup) => usergroup.id)
    }

    const promise = updateResponseGroup(payload).unwrap()

    promise.then(() => onClose())
    toast.promise(promise, {
      loading: 'Updating user group visibility',
      success: 'Visibility status updated successfully',
      error: parseErrorMessage('Failed to update visibility status. Try again...')
    })
  }

  const handleCreateUserGroupClick = () => {
    navigate('/user-groups/create')
  }

  const visibilityStatus = watch('visibility')

  const disableSaveButton =
    isSubmitting || updateResponseGroupResult.isLoading || checkResponseGroupVisibilityUpdateResult.isLoading
  const showCreateUseGroupOption = visibilityStatus === KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC && !hasUserGroups

  const [forceUpdateChildren, setForceUpdateChildren] = useState(false)

  const submitButtonText = forceUpdateChildren ? 'Proceed' : 'Save'

  const handleVisibilityUpdate = useCallback(
    ({ visibility, userGroups }) => {
      setForceUpdateChildren(false)

      const checkVisibilityUpdateAPI = async () => {
        const params = {
          appName,
          id: responseGroupId,
          visibility
        }

        if (visibility === KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC) {
          if (!userGroups) {
            return
          }

          const mappedUserGroups = userGroups.map((group) => group.id)
          if (mappedUserGroups.length === 0) {
            return
          }
          params.mapped_user_groups = mappedUserGroups.toString()
        }

        try {
          const result = await checkResponseGroupVisibilityUpdate(params).unwrap()
          setForceUpdateChildren(result.will_force_update_children)
        } catch {}
      }

      checkVisibilityUpdateAPI()
    },
    [appName, checkResponseGroupVisibilityUpdate, responseGroupId]
  )

  useEffect(() => {
    const subscription = watch((formValue, { type }) => {
      if (type === 'change') {
        handleVisibilityUpdate(formValue)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, handleVisibilityUpdate])

  return (
    <ModalBody>
      <div ref={selectPortalRef} />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <FormControl>
          <Label>
            <strong>{title}</strong> Visible to
          </Label>
          <Controller
            name='visibility'
            control={control}
            shouldUnregister
            render={({ field }) => {
              return (
                <ReactSelect
                  {...field}
                  options={visibilityStatuses}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  value={visibilityStatuses.find(({ value }) => value === field.value)}
                  onChange={({ value }) => field.onChange(value)}
                  inDialog
                />
              )
            }}
          />
        </FormControl>
        <FormControl>
          {showCreateUseGroupOption && (
            <Text variant='muted'>
              Hey! Looks like you haven't created any user groups yet. Please create a user group and then set
              visibility for the added knowledge source.
            </Text>
          )}
          {visibilityStatus === KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC && !showCreateUseGroupOption && (
            <Fragment>
              <Label>Select user group(s)</Label>
              <Controller
                name='userGroups'
                control={control}
                shouldUnregister
                render={({ field }) => {
                  return (
                    <ReactSelect
                      {...field}
                      isMulti
                      isClearable={false}
                      options={userGroupsAvailableToMap}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      inDialog
                    />
                  )
                }}
              />
              {errors.userGroups && <FormErrorText>{errors.userGroups.message}</FormErrorText>}
            </Fragment>
          )}
        </FormControl>

        {forceUpdateChildren && (
          <div className={cx(styles.VisibilityAlert)}>
            <div className={cx(styles.AlertIconContainer)}>
              <AlertIcon />
            </div>
            <Text className={styles.AlertTextContainer} variant='muted'>
              Restricting the visibility on this level might affect the visibility of a few articles or sections under
              this section. Would you still like to proceed ?
            </Text>
          </div>
        )}

        <Flex align='start'>
          {showCreateUseGroupOption && (
            <Button variant='primary' onClick={handleCreateUserGroupClick}>
              Create User Groups
            </Button>
          )}
          {!showCreateUseGroupOption && (
            <Button type='submit' variant='primary' disabled={disableSaveButton}>
              {submitButtonText}
            </Button>
          )}
          <Button variant='link-muted' onClick={onClose}>
            Cancel
          </Button>
        </Flex>
      </form>
    </ModalBody>
  )
}

const VisibilityModalContainer = ({ id, onClose, onAfterSave }) => {
  const { data: account } = api.useGetAccountQuery()
  const { currentWorkspaceId } = useWorkspace()
  const accountType = account.account_type

  const { isLoading, data = {} } = api.useGetResponseGroupQuery({
    appName: 'gdrive',
    id,
    workspaceId: currentWorkspaceId
  })
  const { data: usergroups = {}, isLoading: isUgLoading } = api.useGetUserGroupsQuery({ accountType })

  const hasUserGroups = usergroups?.results?.length > 0

  return (
    <Modal isOpen style={{ content: { width: 480 } }}>
      <ModalHeader onClose={onClose}>
        <Text weight='bold'>Set Visibility</Text>
      </ModalHeader>
      <PageLoader isLoading={isLoading || isUgLoading}>
        <VisibilityModal
          responseGroup={data}
          onClose={onClose}
          onAfterSave={onAfterSave}
          hasUserGroups={hasUserGroups}
          workspaceId={currentWorkspaceId}
        />
      </PageLoader>
    </Modal>
  )
}

const ChildrenFolder = (props) => {
  const { id, depth } = props
  const { currentWorkspaceId } = useWorkspace()

  const { data: childFolders, isLoading: childrenLoading } = api.useGetGDriveFoldersQuery({
    folder_id: id,
    workspace_id: currentWorkspaceId
  })

  return (
    <Fragment>
      {childrenLoading ? (
        <PageLoader size='sm' />
      ) : (
        childFolders.map((childFolder) => <FolderRow key={childFolder.id} {...childFolder} depth={depth + 1} />)
      )}
    </Fragment>
  )
}

const FolderRow = (props) => {
  const { id, name, sync, depth = 0, has_children, bot_response_group_id } = props
  const { currentWorkspaceId } = useWorkspace()
  const [isModalOpen, modalStateFlags] = useBoolean(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const [syncGDriveFolder] = api.useSyncGDriveFolderMutation()
  const [excludeGDriveFolder] = api.useExcludeGDriveFolderMutation()

  const handleSetVisibilityClick = useCallback(() => {
    modalStateFlags.on()
  }, [modalStateFlags])

  const handleModalClose = useCallback(() => {
    modalStateFlags.off()
  }, [modalStateFlags])

  const handleSyncChange = async (prevState) => {
    const mutation = prevState ? excludeGDriveFolder : syncGDriveFolder
    try {
      await toast.promise(mutation({ folder_id: id, workspace_id: currentWorkspaceId }).unwrap(), {
        loading: prevState ? 'Excluding folder' : 'Syncing folder',
        success: prevState ? 'Folder excluded successfully' : 'Folder synced successfully',
        error: prevState ? 'Unable to exclude folder. Try again' : 'Unable to sync folder. Try again'
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error syncing/excluding folder:', error)
    }
  }

  useEffect(() => {
    if (!sync) {
      setIsExpanded(false)
    }
  }, [sync])

  const handleAccordionChange = (value) => {
    if (sync) {
      setIsExpanded(value === id)
    }
  }

  return (
    <Fragment>
      {isModalOpen && <VisibilityModalContainer id={bot_response_group_id} onClose={handleModalClose} />}
      <Accordion
        type='single'
        collapsible
        className={cx(styles.Accordion, { [styles.AccordionRoot]: depth === 0 })}
        value={isExpanded ? id : ''}
        onValueChange={handleAccordionChange}
      >
        <AccordionItem value={id}>
          <AccordionHeader className={styles.AccordionHeader}>
            <AccordionTrigger disabled={!sync} className={cx({ [styles.Hidden]: !has_children })}>
              <span className={styles.AccordionTriggerIcon}>
                <ArrowRightIcon width='1em' height='1em' />
              </span>
            </AccordionTrigger>
            <div className={styles.ContentTitle}>{name}</div>
            <div>
              <UserGroupBadge {...props} />
            </div>
            <div className={styles.TableOptionsContainer}>
              <Checkbox checked={sync} onCheckedChange={() => handleSyncChange(sync)} />
            </div>
            <div className={styles.TableOptionsContainer}>
              {bot_response_group_id && (
                <TableOptions>
                  <TableOption onClick={handleSetVisibilityClick}>Set visibility</TableOption>
                </TableOptions>
              )}
            </div>
          </AccordionHeader>
          {sync && (
            <AccordionContent className={styles.AccordionContent}>
              <ChildrenFolder id={id} depth={depth + 1} />
            </AccordionContent>
          )}
        </AccordionItem>
      </Accordion>
    </Fragment>
  )
}

const GDriveSyncTable = () => {
  const { title } = useAppDetailContext()
  const { currentWorkspaceId } = useWorkspace()
  const { isLoading, data: folderData } = api.useGetGDriveFoldersQuery({ workspace_id: currentWorkspaceId })

  if (isLoading) {
    return <PageLoader />
  }

  if (!folderData || folderData.length === 0) {
    return (
      <div className={styles.ZeroState}>
        <Text>
          Hey! It looks like you haven't synced any folders from <br />
          <Text isInline variant='muted'>
            {title}
          </Text>
        </Text>
      </div>
    )
  }

  return (
    <div className={styles.TableContainer} role='table'>
      <div className={styles.TableHeader}>
        <div>Folders</div>
        <div>Visibility</div>
        <div>Sync</div>
      </div>
      <div className={styles.TableBody}>
        {folderData.map((folder) => (
          <FolderRow key={folder.id} {...folder} />
        ))}
      </div>
    </div>
  )
}

export default GDriveSyncTable
