import { Fragment } from 'react'

import styles from './YoutubeEmbed.module.css'

const YoutubeEmbed = ({ embedId, onClose }) => (
  <Fragment>
    <div className={styles.Overlay} onClick={() => onClose()} />
    <div className={styles.VideoContainer}>
      <iframe
        width='750'
        height='430'
        src={`https://www.youtube.com/embed/${embedId}?rel=0`}
        frameBorder='0'
        allow='encrypted-media'
        allowFullScreen
        title='Embedded youtube'
      />
    </div>
  </Fragment>
)

export default YoutubeEmbed
