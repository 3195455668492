// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZAIuKvSpFkcDEReNjdrX{border-bottom:1px solid var(--border-color-light);padding:25px 0 22px;margin:0 20px}.ZAIuKvSpFkcDEReNjdrX:first-of-type{padding-top:0}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/components/QuickActionsConfig/QuickActionsConfigModal/QuickActionsConfigModal.module.scss"],"names":[],"mappings":"AAAA,sBACE,iDAAA,CACA,mBAAA,CACA,aAAA,CACA,oCACE,aAAA","sourcesContent":[".ContentItem {\n  border-bottom: 1px solid var(--border-color-light);\n  padding: 25px 0 22px;\n  margin: 0 20px;\n  &:first-of-type {\n    padding-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContentItem": "ZAIuKvSpFkcDEReNjdrX"
};
export default ___CSS_LOADER_EXPORT___;
