import { Flex, Text } from '@happyfoxinc/react-ui'

import EmptyChannels from 'Icons/channels-list.svg'

import NoContent from 'Layout/NoContent'

import TipToAddInChannels from './TipToAddInChannels'

const description = (
  <Flex direction='c' gap='md'>
    <Text>
      Add Assist AI to the required Slack channel(s) and then come here to configure Assist AI behaviour in the channel.
      Slack Channels can either be public or private.
    </Text>
    <Text>
      <TipToAddInChannels triggerText='Learn' /> how to add Assist AI to your Slack channel
    </Text>
  </Flex>
)

const noContentProps = {
  title: 'Include Assist AI in Slack channels to respond to queries and create tickets',
  description,
  icon: EmptyChannels
}

const NoChannels = () => {
  return <NoContent {...noContentProps} />
}

export default NoChannels
