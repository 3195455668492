import cx from 'classnames'

import styles from './MetricCards.module.scss'

import DownloadIcon from 'Icons/download.svg'

import { SecondaryButton } from 'Components/Buttons'
import ProgressBar from 'Components/ProgressBar'

import ContentList from '../ContentList'
import ContentListWithCount from '../ContentListWithCount'
import ContentOutline from '../ContentOutline'
import Tile from '../Tile'
import MetricTable from './MetricTable'

const BigNumber = ({ value, isPercent = false }) => {
  return (
    <div className={styles.BigTextContainer}>
      <div className={styles.BigNumber}>
        {value}
        {isPercent && `%`}
      </div>
      {isPercent && (
        <div className={styles.ProgressBar}>
          <ProgressBar percent={value} />
        </div>
      )}
    </div>
  )
}

const DownloadButton = ({ downloadUrl }) => (
  <div className={styles.ActionRow}>
    <SecondaryButton type='button' className={styles.DownloadBtn} size='sm' as='a' href={downloadUrl} download>
      Download CSV
      <DownloadIcon className={styles.DownloadIcon} />
    </SecondaryButton>
  </div>
)

const ContentListContainer = ({ list, withCount, downloadUrl, classNames }) => (
  <div className={cx(styles.ContentListContainer, classNames?.ContentListContainer)}>
    {withCount ? <ContentListWithCount list={list} /> : <ContentList list={list} />}
    {downloadUrl && <DownloadButton downloadUrl={downloadUrl} />}
  </div>
)

const MetricTile = ({
  title,
  info,
  icon,
  summary,
  primaryCount,
  othersCount,
  totalCount,
  othersDisabled,
  othersNotApplicable,
  list,
  downloadUrl,
  isPercent,
  classNames,
  withCount = false
}) => (
  <div className={cx(styles.MetricTile, classNames?.MetricTileContainer)}>
    <Tile title={title} info={info} icon={icon}>
      {list ? (
        <ContentListContainer list={list} withCount={withCount} downloadUrl={downloadUrl} classNames={classNames} />
      ) : (
        <ContentOutline title={summary}>
          {totalCount !== undefined ? (
            <MetricTable
              primaryCount={primaryCount}
              othersCount={othersCount}
              totalCount={totalCount}
              othersDisabled={othersDisabled}
              othersNotApplicable={othersNotApplicable}
            />
          ) : (
            <BigNumber value={primaryCount} isPercent={isPercent} />
          )}
        </ContentOutline>
      )}
    </Tile>
  </div>
)

export default MetricTile
