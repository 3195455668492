// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BgxXPw3WduHHc1HljVHQ{height:100%;display:grid;grid-template-columns:var(--sidebar-width, 230px) 1fr;background-color:var(--main-bg)}.tZPxXzdjOFaDRdvLk6QE{padding:2rem 3rem;height:calc(100% - var(--topbar-height));overflow-y:auto;display:flex;flex-direction:column}.BI3wtzy8V4lHcGcCsVET{overflow:hidden;display:flex;flex-direction:column}", "",{"version":3,"sources":["webpack://./../src/layout/Main/Main.module.css"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,qDAAA,CACA,+BAAA,CAGF,sBACE,iBAAA,CACA,wCAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CAGF,sBACE,eAAA,CACA,YAAA,CACA,qBAAA","sourcesContent":[".MainContainer {\n  height: 100%;\n  display: grid;\n  grid-template-columns: var(--sidebar-width, 230px) 1fr;\n  background-color: var(--main-bg);\n}\n\n.Content {\n  padding: 2rem 3rem;\n  height: calc(100% - var(--topbar-height));\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n}\n\n.PageContainer {\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MainContainer": "BgxXPw3WduHHc1HljVHQ",
	"Content": "tZPxXzdjOFaDRdvLk6QE",
	"PageContainer": "BI3wtzy8V4lHcGcCsVET"
};
export default ___CSS_LOADER_EXPORT___;
