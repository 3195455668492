import { Flex, Heading, Text } from '@happyfoxinc/react-ui'

import styles from './TipToAddInChannels.module.scss'

import Link from 'Components/Link'
import Tooltip, { TooltipContent, TooltipTrigger } from 'Components/Tooltip'

const Content = () => (
  <TooltipContent align='start' alignOffset={-13} side='right' className={styles.TooltipContent}>
    <Heading weight='bold' size='xs'>
      Two Ways to add Assist AI to your Slack channel
    </Heading>

    <Flex direction='c' gap='sm'>
      <Text size='xs' variant='muted'>
        Go to your channel and mention{' '}
        <Text as='span' className={styles.StrongText}>
          @Assist AI
        </Text>
        . Slack will then prompt you to add Assist AI to the respective channel.
      </Text>

      <Flex className={styles.DividerContainer} justify='between' align='center'>
        <Text size='xs' variant='muted'>
          or
        </Text>
        <div className={styles.Divider} />
      </Flex>

      <Text size='xs' variant='muted'>
        Navigate to Channel settings {'>'} Integrations {'>'} Apps. <br /> Click{' '}
        <Text as='span' className={styles.StrongText}>
          Add an App
        </Text>{' '}
        and add Assist AI.
      </Text>
    </Flex>
  </TooltipContent>
)

const TipToAddInChannels = ({ triggerText }) => (
  <Tooltip delayDuration={100}>
    <TooltipTrigger asChild>
      <Link noUnderline variant='primary-dark'>
        {triggerText}
      </Link>
    </TooltipTrigger>

    <Content />
  </Tooltip>
)

export default TipToAddInChannels
