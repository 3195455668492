// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._M2TG3R_lYCeXCAxubBZ{display:flex;align-items:center;justify-content:space-between}._M2TG3R_lYCeXCAxubBZ .Ake9NHZdfjN3yLOkDlz9{white-space:nowrap;text-overflow:ellipsis;overflow:hidden}._M2TG3R_lYCeXCAxubBZ.Ntj2Y579kegTPxOlAgj7{flex-direction:column;justify-content:flex-start;align-items:flex-start}._M2TG3R_lYCeXCAxubBZ.Ntj2Y579kegTPxOlAgj7 .Ake9NHZdfjN3yLOkDlz9{white-space:normal}._M2TG3R_lYCeXCAxubBZ.Ntj2Y579kegTPxOlAgj7 .X4CvEU1PjID0BDWwZj9T{align-self:flex-end}", "",{"version":3,"sources":["webpack://./../src/components/ReadMore/ReadMore.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,4CACE,kBAAA,CACA,sBAAA,CACA,eAAA,CAEF,2CACE,qBAAA,CACA,0BAAA,CACA,sBAAA,CACA,iEACE,kBAAA,CAEF,iEACE,mBAAA","sourcesContent":[".ReadMoreContainer {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  .ReadMoreContent {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n  }\n  &.ShowMore {\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-start;\n    .ReadMoreContent {\n      white-space: normal;\n    }\n    .ReadMoreToggleButton {\n      align-self: flex-end;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ReadMoreContainer": "_M2TG3R_lYCeXCAxubBZ",
	"ReadMoreContent": "Ake9NHZdfjN3yLOkDlz9",
	"ShowMore": "Ntj2Y579kegTPxOlAgj7",
	"ReadMoreToggleButton": "X4CvEU1PjID0BDWwZj9T"
};
export default ___CSS_LOADER_EXPORT___;
