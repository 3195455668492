import { Text } from '@happyfoxinc/react-ui'

import InProgressCard from 'Components/InProgressCard'
import PageLoader from 'Components/PageLoader'
import api from 'Services/api'

import InstallAzureAD from './InstallAzureAD'
import ManageAzureAD from './ManageAzureAD'

const AzureAD = () => {
  const { isLoading, isFetching, data: app = {}, refetch } = api.useGetAzureADAppQuery()

  if (isLoading) {
    return <PageLoader />
  }

  const { is_installed, is_connected } = app

  if (!is_installed) {
    return <InstallAzureAD app={app} />
  }

  if (!is_connected) {
    return (
      <InProgressCard isRefreshing={isFetching} onRefresh={refetch}>
        <div> Integration is in progress</div>
        <Text variant='muted'>It'll be ready in a bit. Please come back later.</Text>
      </InProgressCard>
    )
  }

  return <ManageAzureAD app={app} />
}

export default AzureAD
