import { Button, Flex, FormControl, FormErrorText, Label } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import styles from './QuickActionsConfigForm.module.scss'

import ReactSelect from 'Components/ReactSelect'
import { SuccessSwitch } from 'Components/Switch'
import api from 'Services/api'
import { useAppDetailContext } from 'Src/pages/protected/Apps/AppDetails/AppDetailContext'
import parseErrorMessage from 'Utils/error-message-parser'

import quickActionsFormValidationSchema from './quick-actions-form-validation-schema'

const QuickActionsConfigForm = ({ data }) => {
  const formMethods = useForm({
    defaultValues: {
      enabled: data.enabled,
      agents: data.agents
    },
    resolver: yupResolver(quickActionsFormValidationSchema)
  })

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    resetField,
    reset,
    formState: { isDirty, errors }
  } = formMethods

  const enabled = watch('enabled')
  const { title: appTitle, id: appName } = useAppDetailContext()

  const [getAgents, getAgentsResult] = api.useLazyGetAgentsQuery()
  const [updateQuickActions, updateQuickActionsResult] = api.useUpdateQuickActionsMutation()

  const loadAgentOptions = (inputValue, cb) => {
    getAgents(inputValue)
      .unwrap()
      .then((data) => {
        const options = data.map(({ user_profile_id: id, name, email }) => ({ id, name, email }))
        cb(options)
      })
  }

  const handleAgentsReset = useCallback(() => {
    resetField('agents')
  }, [resetField])

  const handleEnabledStatusToggle = useCallback(
    ({ enabled: status }) => {
      handleAgentsReset()

      const promise = updateQuickActions({ appName, enabled: status }).unwrap()

      toast.promise(promise, {
        loading: `${status ? 'Enabling' : 'Disabling'} quick actions for ${appTitle}...`,
        success: `Quick actions for ${appTitle} ${status ? 'enabled' : 'disabled'} successfully`,
        error: parseErrorMessage(
          `Unable to ${status ? 'enable' : 'disable'} quick actions for ${appTitle}. Try again...`
        )
      })

      promise.catch(() => setValue('enabled', !status))
    },
    [setValue, updateQuickActions, handleAgentsReset, appName, appTitle]
  )

  useEffect(() => {
    const subscription = watch((formValue, { name, type }) => {
      if (name === 'enabled' && type === 'change') {
        handleEnabledStatusToggle(formValue)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, handleEnabledStatusToggle])

  const handleAgentsUpdate = ({ agents }) => {
    const agentIds = agents.map(({ id }) => id)

    const data = {
      agents: agentIds,
      enabled: true
    }

    const promise = updateQuickActions({ appName, ...data }).unwrap()

    toast.promise(promise, {
      loading: `Updating agents for ${appTitle} quick actions...`,
      success: `Agents for ${appTitle} quick actions updated successfully`,
      error: parseErrorMessage(`Unable to update agents for ${appTitle} quick actions. Try again...`)
    })
    promise.then((data) => reset(data)).catch(() => {})
  }

  const ButtonContainer = () => {
    const primaryButtonText = data.agents.length > 0 ? 'Update' : 'Save'
    const disableButton = updateQuickActionsResult.isLoading
    return (
      <div className={styles.ActionContainer}>
        <Button variant='primary' disabled={disableButton || !isDirty} onClick={handleSubmit(handleAgentsUpdate)}>
          {primaryButtonText}
        </Button>
        <Button variant='link-muted' disabled={disableButton} onClick={handleAgentsReset}>
          Cancel
        </Button>
      </div>
    )
  }

  const formatAgentsOptionLabel = ({ name, email }, { context }) => {
    if (context === 'menu') {
      return `${name} (${email})`
    }
    return name
  }

  return (
    <div className={styles.TableContainer}>
      <div className={styles.TableHeader}>
        <Flex direction='r' gap='md' align='start'>
          <Controller
            name='enabled'
            control={control}
            render={({ field: { value, onChange, ref } }) => {
              return (
                <SuccessSwitch className={styles.SwitchInput} checked={value} onCheckedChange={onChange} ref={ref} />
              )
            }}
          />

          <Label className={styles.SwitchInputLabel} variant='default'>
            Grant app action access
          </Label>
        </Flex>
      </div>
      {enabled && (
        <div className={styles.TableBody}>
          <FormControl isInvalid={errors.agents}>
            <Label> Select Agents</Label>
            <Controller
              control={control}
              name='agents'
              render={({ field }) => {
                return (
                  <ReactSelect
                    {...field}
                    formatOptionLabel={formatAgentsOptionLabel}
                    getOptionValue={({ id }) => id}
                    placeholder='Type to search'
                    isClearable={false}
                    isMulti
                    options={[]}
                    loadOptions={loadAgentOptions}
                    isLoading={getAgentsResult.isLoading}
                    loadingMessage={() => 'Searching for agents...'}
                    minCharsBeforeLoadingOptions={2}
                    noOptionsMessage={({ inputValue }) => {
                      if (inputValue.length < 2) {
                        return 'Type alteast 2 characters to start searching'
                      }
                      return `No agents found for input "${inputValue}"`
                    }}
                    components={{
                      DropdownIndicator: null
                    }}
                  />
                )
              }}
            />
            {errors.agents && <FormErrorText>{errors.agents.message}</FormErrorText>}
          </FormControl>
          <ButtonContainer />
        </div>
      )}
    </div>
  )
}
export default QuickActionsConfigForm
