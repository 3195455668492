import { Button, Text } from '@happyfoxinc/react-ui'
import cx from 'classnames'

import styles from './ConfirmationModal.module.scss'

import Modal, { ModalBody, ModalHeader } from 'Components/Modal'

const getPrimaryButtonVariant = (variant) => {
  switch (variant) {
    case 'alert':
      return 'danger'
    default:
      return 'primary'
  }
}

const modalBodyStyle = {
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center'
}

const ConfirmationModal = ({
  onCancel,
  onConfirm,
  message,
  title,
  isOpen,
  variant,
  confirmButtonText = 'Yes',
  cancelButtonText = 'Cancel',
  canClose = true,
  width = 350,
  buttonAlignment = 'center'
}) => {
  if (!isOpen) {
    return null
  }

  return (
    <Modal
      onRequestClose={onCancel}
      shouldCloseOnOverlayClick={canClose}
      shouldCloseOnEsc={canClose}
      isOpen
      style={{ content: { width, padding: 33, paddingTop: 41 } }}
    >
      <ModalHeader onClose={onCancel}>
        {title && (
          <Text className={styles.Title} weight='bold'>
            {title}
          </Text>
        )}
      </ModalHeader>
      <ModalBody style={modalBodyStyle}>
        <div className={styles.Message}>{message}</div>
        <div className={cx(styles.ButtonContainer, { [styles.LeftAlign]: buttonAlignment === 'left' })}>
          {confirmButtonText && (
            <Button onClick={onConfirm} variant={getPrimaryButtonVariant(variant)}>
              {confirmButtonText}
            </Button>
          )}
          {cancelButtonText && (
            <Button onClick={onCancel} variant='secondary-outline'>
              {cancelButtonText}
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmationModal
