import { Button, Heading } from '@happyfoxinc/react-ui'

import styles from './Common.module.css'

import { SecondaryButton } from 'Components/Buttons'
import { URLS } from 'Constants/urls'
import { openUrl } from 'Utils/url'

const UpgradeButtons = () => (
  <div className={styles.PlanButtonGroup}>
    <Button variant='primary' onClick={() => openUrl(URLS.ASSIST_AI)}>
      Upgrade Plan
    </Button>
    <SecondaryButton onClick={() => openUrl(URLS.ASSIST_AI)}>Plan comparison</SecondaryButton>
  </div>
)

const UpgradePrompt = ({ title, message }) => (
  <div className={styles.Container}>
    <Heading level={2}>{title}</Heading>
    <Heading level={2} variant='muted'>
      {message}
    </Heading>
    <UpgradeButtons />
  </div>
)

export default UpgradePrompt
