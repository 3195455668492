import { TableOption, TableOptions } from '@happyfoxinc/react-ui'

const Options = (props) => {
  const { rowData, onConfigure } = props

  return (
    <TableOptions>
      <TableOption onClick={() => onConfigure(rowData)}>Configure</TableOption>
    </TableOptions>
  )
}

export default Options
