// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X6owWUBDzz0tSeDwry7Q{margin-top:-2.3rem}.j1QZ6vXUo1gnP07oAqo5{width:100%;border-collapse:collapse;overflow:hidden;border-radius:var(--border-radius, 6px);box-shadow:0 0 3px 0 rgba(0,0,0,.2);margin-bottom:1rem}.SaQhdFMZeB9qFucgeQ7s,.CkUqjJqmdhHYSn42iQw5,.oo2DHNGR1l9UQ1R6VySk{padding:.5rem;text-align:inherit;text-align:-webkit-match-parent}.oo2DHNGR1l9UQ1R6VySk{background-color:#eee}.d7xSl_Ge49gU6GZvZ5Wj{display:flex;align-items:center;gap:.5rem;color:inherit}.XpS5PVCrGw_LWQbH17CP{text-align:center}.bvo7ZrBPnSGdaXbzwRGU{margin:1rem 0}.Ifj686XS6V37GncT55UA{display:flex;gap:1rem}.pofXZxxJemlNvNqauYcO{width:50%}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/AppDetails/apps/Simpplr/SimpplrSites/SimpplrSites.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,sBACE,UAAA,CACA,wBAAA,CACA,eAAA,CACA,uCAAA,CACA,mCAAA,CACA,kBAAA,CAGF,kEACE,aAAA,CACA,kBAAA,CACA,+BAAA,CAGF,sBAEE,qBAAA,CAOF,sBACE,YAAA,CACA,kBAAA,CACA,SAAA,CACA,aAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,aAAA,CAGF,sBACE,YAAA,CACA,QAAA,CAGF,sBACE,SAAA","sourcesContent":[".TableContainer {\n  margin-top: -2.3rem;\n}\n\n.Table {\n  width: 100%;\n  border-collapse: collapse;\n  overflow: hidden;\n  border-radius: var(--border-radius, 6px);\n  box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);\n  margin-bottom: 1rem;\n}\n\n.Cell {\n  padding: 0.5rem;\n  text-align: inherit;\n  text-align: -webkit-match-parent;\n}\n\n.Th {\n  @extend .Cell;\n  background-color: #eee;\n}\n\n.Td {\n  @extend .Cell;\n}\n\n.SiteLink {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n  color: inherit;\n}\n\n.NoSites {\n  text-align: center;\n}\n\n.AddSiteContainer {\n  margin: 1rem 0;\n}\n\n.AddSiteForm {\n  display: flex;\n  gap: 1rem;\n}\n\n.SearchSite {\n  width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TableContainer": "X6owWUBDzz0tSeDwry7Q",
	"Table": "j1QZ6vXUo1gnP07oAqo5",
	"Cell": "SaQhdFMZeB9qFucgeQ7s",
	"Td": "CkUqjJqmdhHYSn42iQw5",
	"Th": "oo2DHNGR1l9UQ1R6VySk",
	"SiteLink": "d7xSl_Ge49gU6GZvZ5Wj",
	"NoSites": "XpS5PVCrGw_LWQbH17CP",
	"AddSiteContainer": "bvo7ZrBPnSGdaXbzwRGU",
	"AddSiteForm": "Ifj686XS6V37GncT55UA",
	"SearchSite": "pofXZxxJemlNvNqauYcO"
};
export default ___CSS_LOADER_EXPORT___;
