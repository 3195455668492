import { Box, Heading, Link, Text } from '@happyfoxinc/react-ui'
import { useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import styles from './TimeZone.module.scss'

import PageLoader from 'Components/PageLoader'
import ReactSelect from 'Components/ReactSelect'
import api from 'Services/api'
import { getGMTOffsetStringFromSeconds } from 'Utils/time'

const TimeZoneFormHandler = ({ timeZones, userTimeZone }) => {
  const navigate = useNavigate()

  const [updateTimeZone, updateTimeZoneResult] = api.useUpdateUserTimeZoneMutation()

  const timeZoneOptions = useMemo(
    () =>
      timeZones.map(({ label, name, offset }) => {
        const formattedOffset = getGMTOffsetStringFromSeconds(offset)
        return {
          label: `${formattedOffset} - ${label}`,
          value: name
        }
      }),
    [timeZones]
  )

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      timezone: userTimeZone.name
    }
  })
  const { handleSubmit, watch, control } = formMethods

  const onFormSubmit = useCallback(
    ({ timezone }) => {
      const input = {
        timezone
      }
      const promise = updateTimeZone(input).unwrap()
      toast.promise(promise, {
        loading: 'Updating Time Zone preference',
        success: 'Successfully updated time zone preference',
        error: 'Unable to update time zone preference. Try again...'
      })
    },
    [updateTimeZone]
  )

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onFormSubmit)())
    return () => subscription.unsubscribe()
  }, [watch, handleSubmit, onFormSubmit])

  return (
    <div className={styles.ContentContainer}>
      <Box className={styles.DescriptionColumn}>
        <Text>Set time zone</Text>
        <Text variant='muted'>
          Choose your time zone. This timezone is reflected in both the{' '}
          <Link className={styles.Link} onClick={() => navigate('/reports')}>
            dashboard report
          </Link>{' '}
          and the weekly report posted on Teams.
        </Text>
      </Box>
      <Box className={styles.UserInputColumn}>
        <Controller
          name='timezone'
          control={control}
          render={({ field }) => (
            <ReactSelect
              placeholder='Select Time Zone'
              {...field}
              value={timeZoneOptions.find((opt) => opt.value === field.value)}
              onChange={(opt) => field.onChange(opt.value)}
              isDisabled={updateTimeZoneResult.isLoading}
              options={timeZoneOptions}
            />
          )}
        />
      </Box>
    </div>
  )
}

const TimeZone = () => {
  const { data: timeZones = [], isLoading: isAllTimeZonesLoading } = api.useGetAllTimeZonesQuery()
  const { data: userTimeZone = {}, isLoading: isUserTimeZoneLoading } = api.useGetUserTimeZoneQuery()

  const isLoading = isAllTimeZonesLoading || isUserTimeZoneLoading

  return (
    <div className={styles.Container}>
      <Heading className={styles.SubHeading} level={2} weight='bold'>
        Time Zone
      </Heading>
      <PageLoader isLoading={isLoading}>
        <TimeZoneFormHandler timeZones={timeZones} userTimeZone={userTimeZone} />
      </PageLoader>
    </div>
  )
}

export default TimeZone
