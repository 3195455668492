// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SsTZcnThGIEVQp7FKr34{margin-top:-2.3rem}.s7qK8aYl93vpKk7PbWFi{width:100%;border-collapse:collapse;overflow:hidden;border-radius:var(--border-radius, 6px);box-shadow:0 0 3px 0 rgba(0,0,0,.2);margin-bottom:1rem}.Cq_vFc47KBua1myzy8fT,.ljmyIihPUmQNllzmwH2Z,.UGsWMCRhntsgerDdd_Pa{padding:.5rem;text-align:inherit;text-align:-webkit-match-parent}.UGsWMCRhntsgerDdd_Pa{background-color:#eee}.fID4XI_kEuvPxMpf731v{display:flex;align-items:center;gap:.5rem;color:inherit}.boLazNVDBLDiCY17oc0I{text-align:center}.IEHakSbXNWAlcX9OlXyQ{margin:1rem 0}.lrOimxiKdpvWXN3bAWgv{display:flex;gap:1rem}.u6LtyZd5NynuNeAln9wu{width:50%}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/AppDetails/apps/SharePoint/SharePointSites/SharePointSites.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,sBACE,UAAA,CACA,wBAAA,CACA,eAAA,CACA,uCAAA,CACA,mCAAA,CACA,kBAAA,CAGF,kEACE,aAAA,CACA,kBAAA,CACA,+BAAA,CAGF,sBAEE,qBAAA,CAOF,sBACE,YAAA,CACA,kBAAA,CACA,SAAA,CACA,aAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,aAAA,CAGF,sBACE,YAAA,CACA,QAAA,CAGF,sBACE,SAAA","sourcesContent":[".TableContainer {\n  margin-top: -2.3rem;\n}\n\n.Table {\n  width: 100%;\n  border-collapse: collapse;\n  overflow: hidden;\n  border-radius: var(--border-radius, 6px);\n  box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);\n  margin-bottom: 1rem;\n}\n\n.Cell {\n  padding: 0.5rem;\n  text-align: inherit;\n  text-align: -webkit-match-parent;\n}\n\n.Th {\n  @extend .Cell;\n  background-color: #eee;\n}\n\n.Td {\n  @extend .Cell;\n}\n\n.SiteLink {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n  color: inherit;\n}\n\n.NoSites {\n  text-align: center;\n}\n\n.AddSiteContainer {\n  margin: 1rem 0;\n}\n\n.AddSiteForm {\n  display: flex;\n  gap: 1rem;\n}\n\n.SearchSite {\n  width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TableContainer": "SsTZcnThGIEVQp7FKr34",
	"Table": "s7qK8aYl93vpKk7PbWFi",
	"Cell": "Cq_vFc47KBua1myzy8fT",
	"Td": "ljmyIihPUmQNllzmwH2Z",
	"Th": "UGsWMCRhntsgerDdd_Pa",
	"SiteLink": "fID4XI_kEuvPxMpf731v",
	"NoSites": "boLazNVDBLDiCY17oc0I",
	"AddSiteContainer": "IEHakSbXNWAlcX9OlXyQ",
	"AddSiteForm": "lrOimxiKdpvWXN3bAWgv",
	"SearchSite": "u6LtyZd5NynuNeAln9wu"
};
export default ___CSS_LOADER_EXPORT___;
