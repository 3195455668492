import { useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import styles from './MergeTrixEditor.module.scss'

import InsertMergeFields from 'Components/InsertMergeFields'
import TrixEditor from 'Components/TrixEditor'
import { getConcatenatedMergeFieldText } from 'Utils/mergeFields'

const MergeTrixEditor = ({ name, mergeFields }) => {
  const fieldRef = useRef(null)

  const { control } = useFormContext()

  const handleAddMergeFields = (addedFields) => {
    const cursorPosition = fieldRef.current.editor.getPosition()
    const currentTextLength = fieldRef.current.innerText.length

    const isTheCursorAtTheStart = cursorPosition === 0
    const isTheCursorAtTheEnd = cursorPosition === currentTextLength

    const canAddSpacerAtStart = !isTheCursorAtTheStart
    const canAddSpacerAtEnd = !isTheCursorAtTheEnd

    const concatenatedMergeFieldText = getConcatenatedMergeFieldText(
      addedFields,
      canAddSpacerAtStart,
      canAddSpacerAtEnd
    )

    fieldRef.current.editor.insertString(concatenatedMergeFieldText)
  }

  return (
    <div className={styles.FieldContainer}>
      <div className={styles.MergeFieldContainer}>
        <InsertMergeFields fields={mergeFields} onAdd={handleAddMergeFields} />
      </div>

      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <TrixEditor defaultValue={value} onChange={onChange} ref={fieldRef} />
        )}
      />
    </div>
  )
}

export default MergeTrixEditor
