import { TableOption, TableOptions } from '@happyfoxinc/react-ui'
import { Fragment, useState } from 'react'
import toast from 'react-hot-toast'

import ConfirmationModal from 'Components/ConfirmationModal'
import api from 'Services/api'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

const GDriveTableOptions = (props) => {
  const { id } = props
  const { currentWorkspaceId } = useWorkspace()
  const [syncGDriveFolder] = api.useSyncGDriveFolderMutation()
  const [isSyncModalOpen, setIsSyncModalOpen] = useState(false)

  const handleSyncClick = () => {
    setIsSyncModalOpen(false)
    const promise = syncGDriveFolder({ folder_id: id, workspace_id: currentWorkspaceId }).unwrap()

    toast.promise(promise, {
      loading: 'Syncing folder',
      success: 'Folder synced successfully',
      error: 'Unable to sync folder. Try again'
    })
  }

  return (
    <Fragment>
      <TableOptions>
        <TableOption onClick={() => setIsSyncModalOpen(true)}>Sync</TableOption>
      </TableOptions>
      <ConfirmationModal
        isOpen={isSyncModalOpen}
        variant='alert'
        message='Are you sure you want to sync this folder?'
        onCancel={() => setIsSyncModalOpen(false)}
        onConfirm={handleSyncClick}
      />
    </Fragment>
  )
}

export default GDriveTableOptions
