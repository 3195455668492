import { Button } from '@happyfoxinc/react-ui'
import cx from 'classnames'
import { useEffect, useRef, useState } from 'react'

import styles from './ReadMore.module.scss'

const ReadMore = ({ content, count }) => {
  const [lineOverFlow, setLineOverFlow] = useState(false)
  const [showMore, setShowMore] = useState(false)

  const contentRef = useRef(null)

  useEffect(() => {
    function onWindoResizeSize() {
      if (contentRef.current?.offsetWidth < contentRef.current?.scrollWidth) {
        setLineOverFlow(true)
      } else {
        setLineOverFlow(false)
      }
    }
    window.addEventListener('resize', onWindoResizeSize)

    onWindoResizeSize()
    return () => window.removeEventListener('resize', onWindoResizeSize)
  }, [])

  const handleReadMoreToggle = () => {
    setShowMore(!showMore)
  }

  let mainContent = content
  if (count) {
    mainContent = `${content} (${count})`
  }

  return (
    <div className={cx(styles.ReadMoreContainer, { [styles.ShowMore]: showMore })}>
      <div ref={contentRef} className={styles.ReadMoreContent}>
        {mainContent}
      </div>
      {count && lineOverFlow && !showMore && <div>{`(${count})`}</div>}
      {lineOverFlow && (
        <Button size='xs' variant='link-muted' className={styles.ReadMoreToggleButton} onClick={handleReadMoreToggle}>
          {showMore ? 'less' : 'more'}
        </Button>
      )}
    </div>
  )
}
export default ReadMore
