import { createBrowserHistory } from 'history'
import { useCallback, useEffect, useRef, useState } from 'react'

import ConfirmationModal from 'Components/ConfirmationModal'
import { CONFIRM_MESSAGES } from 'Constants/messages'

const history = createBrowserHistory()

export const Prompt = (props) => {
  const { when: isBlocking = false, message = CONFIRM_MESSAGES.DEFAULT_PROMPT_MESSAGE } = props

  const [transition, setTransition] = useState(null)
  const unblock = useRef()

  const clearBlocker = useCallback(() => {
    if (unblock.current) {
      unblock.current()
    }

    unblock.current = null
  }, [])

  const createBlocker = useCallback(() => {
    const unblocker = history.block((tx) => setTransition(tx))

    return unblocker
  }, [])

  const handleConfirmation = () => {
    clearBlocker()
    transition.retry()
  }

  useEffect(() => {
    if (!isBlocking) {
      return clearBlocker()
    }

    if (!unblock.current) {
      unblock.current = createBlocker()
    }
  }, [createBlocker, isBlocking, clearBlocker])

  return (
    <ConfirmationModal
      isOpen={transition}
      onCancel={() => setTransition(null)}
      onConfirm={handleConfirmation}
      message={message}
    />
  )
}

export default history
