import dayjs from 'dayjs'
import durationPlugin from 'dayjs/plugin/duration'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import relativeTimePlugin from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import toArray from 'dayjs/plugin/toArray'
import toObject from 'dayjs/plugin/toObject'
import utcPlugin from 'dayjs/plugin/utc'

dayjs.extend(utcPlugin)
dayjs.extend(relativeTimePlugin)
dayjs.extend(durationPlugin)
dayjs.extend(toObject)
dayjs.extend(toArray)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)

export default dayjs
