import styles from './Topbar.module.css'

import HelpIcon from 'Icons/help.svg'
import SlackLogo from 'Images/brands/slack.svg'

// import BellIcon from 'Icons/bell.svg'
import { ACCOUNT_TYPE } from 'Constants/account'
import KB_LINKS from 'Constants/kb-links'
import api from 'Services/api'
// import GlobalSearch from './GlobalSearch'
import { openUrl } from 'Utils/url'

import UserActions from './UserActions'

const Topbar = () => {
  const { data: account } = api.useGetAccountQuery()
  const isSlackAccountType = account.account_type === ACCOUNT_TYPE.SLACK
  const isMsTeamsAccountType = account.account_type === ACCOUNT_TYPE.MS_TEAMS

  const handleAccountNameClick = () => {
    openUrl(account.app_url)
  }

  return (
    <div className={styles.Container}>
      {/* <GlobalSearch />s */}
      <div className={styles.Actions}>
        {/* <div className={styles.IconAction}>
          <BellIcon width='1em' height='1em' />
        </div> */}
        <div className={styles.IconAction} onClick={() => openUrl(KB_LINKS.AAI_HELP)}>
          <HelpIcon width='1em' height='1em' />
        </div>
        <div className={styles.IconAction}>
          <UserActions />
        </div>
      </div>
      <div className={styles.AccountItem} onClick={handleAccountNameClick}>
        {isSlackAccountType && <SlackLogo height='20' width='20' />}
        {isMsTeamsAccountType && <div className={styles.MicrosoftLogo} />}
        {account.account_name}
      </div>
    </div>
  )
}

export default Topbar
