// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kpncwPgwHsIpEnVRUuCc{margin-top:1rem}.VTUsKzIekkJuW6Ez1XNN{display:flex;align-self:flex-end}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/AppList/AppsList.module.css"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,YAAA,CACA,mBAAA","sourcesContent":[".PlanUpgradeButton {\n  margin-top: 1rem;\n}\n\n.WorkspaceDropdownContainer {\n  display: flex;\n  align-self: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PlanUpgradeButton": "kpncwPgwHsIpEnVRUuCc",
	"WorkspaceDropdownContainer": "VTUsKzIekkJuW6Ez1XNN"
};
export default ___CSS_LOADER_EXPORT___;
