// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Q8LjsnKLlixm10h7u_dG{margin-top:2rem}._BWyi8WVjj9ACWmIyaUA{font-weight:bold;margin:1rem 0}.B7UKvMhs7tHwTy1aBGjc{list-style:inherit;padding:0 0 0 1rem;color:var(--muted);font-size:var(--text-sm)}.xTeqC93MSE6ojAvB7VU5{margin-bottom:.7rem}", "",{"version":3,"sources":["webpack://./../src/components/Tips/Tips.module.css"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,gBAAA,CACA,aAAA,CAGF,sBACE,kBAAA,CACA,kBAAA,CACA,kBAAA,CACA,wBAAA,CAGF,sBACE,mBAAA","sourcesContent":[".TipContainer {\n  margin-top: 2rem;\n}\n\n.TipTitle {\n  font-weight: bold;\n  margin: 1rem 0;\n}\n\n.TipContent {\n  list-style: inherit;\n  padding: 0 0 0 1rem;\n  color: var(--muted);\n  font-size: var(--text-sm);\n}\n\n.Tip {\n  margin-bottom: 0.7rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TipContainer": "Q8LjsnKLlixm10h7u_dG",
	"TipTitle": "_BWyi8WVjj9ACWmIyaUA",
	"TipContent": "B7UKvMhs7tHwTy1aBGjc",
	"Tip": "xTeqC93MSE6ojAvB7VU5"
};
export default ___CSS_LOADER_EXPORT___;
