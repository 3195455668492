import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { normalize, reset } from '@happyfoxinc/react-ui'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import './styles/index.scss'

import App from './App'
import { createStore } from './store'

const store = createStore()
const resetStyles = reset()
const normalizeStyles = normalize()

const Main = () => {
  resetStyles()
  normalizeStyles()

  return (
    <StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </StrictMode>
  )
}

const rootEl = document.getElementById('__root')
const root = createRoot(rootEl)

root.render(<Main />)
