import {
  Heading,
  PaginationControls,
  Table,
  TableActions,
  TableBody,
  TableHead,
  TableOption,
  TableOptions,
  TableRow,
  Td,
  Text,
  Th
} from '@happyfoxinc/react-ui'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { usePagination, useTable } from 'react-table'

import styles from './CollectionsConfig.module.css'

import { SecondaryButton } from 'Components/Buttons'
import ConfirmationModal from 'Components/ConfirmationModal'
import PageLoader from 'Components/PageLoader'
import ReactSelect from 'Components/ReactSelect'
import api from 'Services/api'
import debounce from 'Utils/debounce'

const PAGE_SIZE = 5
const defaultApiParams = { offset: 0, limit: PAGE_SIZE }

const CollectionsTable = ({ onDelete }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [collectionToDelete, setCollectionToDelete] = useState(null)
  const [queryParams, setQueryParams] = useState(defaultApiParams)

  const { data = {}, isLoading } = api.useGetSyncedCollectionsQuery(queryParams)
  const { results: syncedCollections = [], meta: paginationDetails = {} } = data

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Collections',
        accessor: 'collection_name'
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: syncedCollections,
      initialState: {
        pageIndex: 0,
        pageSize: PAGE_SIZE
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / PAGE_SIZE)
    },
    usePagination
  )

  useEffect(() => {
    const apiParams = {
      offset: pageIndex * PAGE_SIZE,
      limit: PAGE_SIZE
    }

    debouncedSetQueryParams(apiParams)
  }, [pageIndex, debouncedSetQueryParams])

  const openDeleteModal = (id) => {
    setCollectionToDelete(id)
    setShowDeleteModal(true)
  }

  const handleRemoveCollection = useCallback(() => {
    onDelete(collectionToDelete)
    setShowDeleteModal(false)
    setCollectionToDelete(null)
  }, [collectionToDelete, onDelete])

  return (
    <Fragment>
      <Heading level={6}>Collections currently syncing from</Heading>
      <PageLoader isLoading={isLoading}>
        <div className={styles.TableContainer}>
          <TableActions>
            <PaginationControls
              currentPage={pageIndex + 1}
              pageSize={PAGE_SIZE}
              totalItems={paginationDetails.total}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              previousPage={previousPage}
              nextPage={nextPage}
            />
          </TableActions>
          <Table className={styles.CollectionsTable} {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => {
                const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

                return (
                  <TableRow key={key} {...headerGroupProps} style={{ backgroundColor: '#EEEEEE' }}>
                    <Fragment>
                      {headerGroup.headers.map((column) => {
                        const { key, ...headerProps } = column.getHeaderProps()

                        return (
                          <Th key={key} {...headerProps} className={styles.TableCell}>
                            <Text variant='muted'>{column.render('Header')} </Text>
                          </Th>
                        )
                      })}
                      <Th isOption />
                    </Fragment>
                  </TableRow>
                )
              })}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                const { key, ...rowProps } = row.getRowProps()

                return (
                  <TableRow key={key} {...rowProps} clickable>
                    {row.cells.map((cell) => {
                      const { key, ...cellProps } = cell.getCellProps()
                      return (
                        <Td className={styles.TableCell} key={key} {...cellProps}>
                          {cell.render('Cell')}
                        </Td>
                      )
                    })}
                    <Td className={styles.TableCell}>
                      <TableOptions>
                        <TableOption onClick={() => openDeleteModal(row.original.collection_id)}>Delete</TableOption>
                      </TableOptions>
                    </Td>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </PageLoader>

      <ConfirmationModal
        isOpen={showDeleteModal}
        variant='alert'
        message='Are you sure you want to delete this collection?'
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleRemoveCollection}
      />
    </Fragment>
  )
}

const CollectionsConfig = () => {
  const [selectedCollections, setSelectedCollections] = useState([])
  const { data = {}, isLoading: isCollectionsLoading } = api.useGetCollectionsQuery()
  const { results: collections = [] } = data
  const [addCollections, addCollectionsResult] = api.useAddCollectionsMutation()
  const [removeCollection] = api.useRemoveSyncedCollectionMutation()

  const handleAddCollections = useCallback(() => {
    const collectionIds = selectedCollections.map((collection) => collection.id)
    const promise = addCollections({ collection_ids: collectionIds }).unwrap()
    promise.then(() => setSelectedCollections([]))

    toast.promise(promise, {
      loading: 'Adding collections',
      success: 'Collections added successfully',
      error: 'Unable to add collections. Try again'
    })
  }, [addCollections, selectedCollections])

  const handleRemoveCollection = useCallback(
    async (id) => {
      const promise = removeCollection(id).unwrap()

      toast.promise(promise, {
        loading: 'Deleting collection',
        success: 'Collection deleted successfully',
        error: 'Unable to delete collection. Try again...'
      })
    },
    [removeCollection]
  )

  if (isCollectionsLoading) {
    return <PageLoader />
  }

  const disableAddButton = selectedCollections.length === 0 || addCollectionsResult.isLoading

  return (
    <div className={styles.Container}>
      <Text>Add collections to sync from</Text>
      <div className={styles.SelectCollectionsContainer}>
        <ReactSelect
          isMulti
          value={selectedCollections}
          onChange={(options) => setSelectedCollections(options)}
          styles={{
            control: (provided) => ({
              ...provided,
              width: '300px'
            })
          }}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          options={collections}
          placeholder='Select collections'
        />
        <SecondaryButton onClick={handleAddCollections} className={styles.AddButton} disabled={disableAddButton}>
          Add +
        </SecondaryButton>
      </div>
      <div className={styles.SyncedCollectionsContainer}>
        <CollectionsTable onDelete={handleRemoveCollection} />
      </div>
    </div>
  )
}

export default CollectionsConfig
