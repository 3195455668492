import { Heading } from '@happyfoxinc/react-ui'
import { Fragment } from 'react'

import styles from './Sheet.module.css'

import CloseIcon from 'Icons/close.svg'

const Sheet = (props) => {
  const { title, onHide, children, show = true } = props

  if (!show) {
    return null
  }

  return (
    <Fragment>
      <div className={styles.Overlay} onClick={onHide} />
      <div className={styles.Container}>
        <div className={styles.Header}>
          <div className={styles.Close} onClick={onHide}>
            <CloseIcon width='18' height='18' />
          </div>
          <Heading level={2} className={styles.Title}>
            {title}
          </Heading>
        </div>
        <div className={styles.Content}>{children}</div>
      </div>
    </Fragment>
  )
}

export default Sheet
