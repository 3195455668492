import { Text } from '@happyfoxinc/react-ui'

import styles from './SyncDisabledAlert.module.scss'

import { useAppDetailContext } from '../../AppDetails/AppDetailContext'

const SyncDisabledAlert = ({ message = '', onEnableSync }) => {
  const { title } = useAppDetailContext()
  const messageForDisplay = message || `${title} sync is disabled`

  return (
    <Text className={styles.SyncAlert} variant='muted'>
      {messageForDisplay}
      <a className={styles.EnableSync} onClick={onEnableSync}>
        Enable sync
      </a>
    </Text>
  )
}

export default SyncDisabledAlert
