// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gZJJvsYq0wkd9FHxJCAI{min-width:170px}", "",{"version":3,"sources":["webpack://./../src/components/ReactSelect/ReactSelect.module.css"],"names":[],"mappings":"AAAA,sBACE,eAAA","sourcesContent":[".Select {\n  min-width: 170px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Select": "gZJJvsYq0wkd9FHxJCAI"
};
export default ___CSS_LOADER_EXPORT___;
