// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yEMRkIWhHhBbsp8J5r_X{width:286px}.yEMRkIWhHhBbsp8J5r_X .z46GCLBlq29WWxojYWgC{padding:5px 13px 6px 20px}.yEMRkIWhHhBbsp8J5r_X .z46GCLBlq29WWxojYWgC .cBP1pLbsn5SeNF3ePPFw{margin-bottom:0}.yEMRkIWhHhBbsp8J5r_X .z46GCLBlq29WWxojYWgC .fBldLF7T_fnEISlZXqYA{cursor:pointer}.yEMRkIWhHhBbsp8J5r_X .tnX56y0dtkdcFJGFR3Gt{background-color:#ebebeb;height:1px}.yEMRkIWhHhBbsp8J5r_X .jfy0YvsHiFRNweqQsLHr{padding:11px 20px 10px 20px}.yEMRkIWhHhBbsp8J5r_X .jfy0YvsHiFRNweqQsLHr .OdeLeHtm0ziFuFcu2De3 button{padding:2.3px 19px}._Ec64ZDHm2m7wAmubRhy{float:right}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Actions/components/MergeFieldDropdown/MergeFieldDropdown.module.css"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAEA,4CACE,yBAAA,CAEA,kEACE,eAAA,CAGF,kEACE,cAAA,CAIJ,4CACE,wBAAA,CACA,UAAA,CAGF,4CACE,2BAAA,CAGE,yEACE,kBAAA,CAMR,sBACE,WAAA","sourcesContent":[".Container {\n  width: 286px;\n\n  .HeadingContainer {\n    padding: 5px 13px 6px 20px;\n\n    .Heading {\n      margin-bottom: 0;\n    }\n\n    .CloseIcon {\n      cursor: pointer;\n    }\n  }\n\n  .Divider {\n    background-color: #ebebeb;\n    height: 1px;\n  }\n\n  .Body {\n    padding: 11px 20px 10px 20px;\n\n    .ButtonContainer {\n      button {\n        padding: 2.3px 19px;\n      }\n    }\n  }\n}\n\n.MergeFieldIcon {\n  float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "yEMRkIWhHhBbsp8J5r_X",
	"HeadingContainer": "z46GCLBlq29WWxojYWgC",
	"Heading": "cBP1pLbsn5SeNF3ePPFw",
	"CloseIcon": "fBldLF7T_fnEISlZXqYA",
	"Divider": "tnX56y0dtkdcFJGFR3Gt",
	"Body": "jfy0YvsHiFRNweqQsLHr",
	"ButtonContainer": "OdeLeHtm0ziFuFcu2De3",
	"MergeFieldIcon": "_Ec64ZDHm2m7wAmubRhy"
};
export default ___CSS_LOADER_EXPORT___;
