export default {
  CREATE_USER_GROUP: 'https://support.happyfox.com/kb/article/1213-creating-user-groups-in-assist-ai/',
  SET_KNOWLEDGE_VISIBILITY:
    'https://support.happyfox.com/kb/article/1215-setting-knowledge-visibility-restrictions-in-assist-ai/',
  AAI_HELP: 'https://support.happyfox.com/kb/section/230/',
  SHARE_MS_TEAMS_AAI: 'https://support.happyfox.com/kb/article/1230-let-your-employees-use-assist-ai-on-teams/',
  SHAREPOINT_INTEGRATION:
    'https://support.happyfox.com/kb/article/1180-connecting-to-sharepoint-knowledge-source-in-assist-ai/',
  HAPPYFOX_HELPDESK_INTEGRATION:
    'https://support.happyfox.com/kb/article/1170-integrating-assist-ai-with-happyfox-helpdesk/',
  NOTION_INTEGRATION:
    'https://support.happyfox.com/kb/article/1178-connecting-to-notion-as-a-knowledge-source-for-assist-ai/',
  SIMPPLR_INTEGRATION:
    'https://support.happyfox.com/kb/article/1187-connecting-to-simpplr-as-a-knowledge-source-for-assist-ai/',
  CONFLUENCE_INTEGRATION:
    'https://support.happyfox.com/kb/article/1172-connect-to-confluence-as-a-knowledge-source-for-assist-ai/',
  GURU_INTEGRATION: 'https://support.happyfox.com/kb/article/1179-connect-to-getguru-knowledge-source-in-assist-ai/',
  OTHER_TICKETING_INTEGRATION:
    'https://support.happyfox.com/kb/article/1186-connecting-assist-ai-with-your-ticketing-systems/',
  OKTA_INTEGRATION: 'https://support.happyfox.com/kb/article/1235-okta-integration-with-assist-ai/',
  SLACK_CHANNEL_CONFIGURATION:
    'https://support.happyfox.com/kb/article/1245-channel-configuration-enabling-assist-ai-to-respond-to-queries-and-create-tickets-in-public-and-private-slack-channels/'
}
