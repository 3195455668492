// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BhAUiC6Cexg9eHejGjxv{margin-bottom:var(--hrx-space-3)}.unNiIhD2e_fCtXK29UeK{margin-top:var(--hrx-space-8);display:flex;align-items:center;justify-content:center;-moz-column-gap:14px;column-gap:14px}.unNiIhD2e_fCtXK29UeK.du1TVHjBanK7TUzd_1AH{align-self:flex-start}.vVszkcnXYJ7rogMrCHxA{font-size:var(--text-heading2);width:100%}", "",{"version":3,"sources":["webpack://./../src/components/ConfirmationModal/ConfirmationModal.module.scss"],"names":[],"mappings":"AAAA,sBACE,gCAAA,CAGF,sBACE,6BAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,oBAAA,CAAA,eAAA,CACA,2CACE,qBAAA,CAIJ,sBACE,8BAAA,CACA,UAAA","sourcesContent":[".Title {\n  margin-bottom: var(--hrx-space-3);\n}\n\n.ButtonContainer {\n  margin-top: var(--hrx-space-8);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  column-gap: 14px;\n  &.LeftAlign {\n    align-self: flex-start;\n  }\n}\n\n.Message {\n  font-size: var(--text-heading2);\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Title": "BhAUiC6Cexg9eHejGjxv",
	"ButtonContainer": "unNiIhD2e_fCtXK29UeK",
	"LeftAlign": "du1TVHjBanK7TUzd_1AH",
	"Message": "vVszkcnXYJ7rogMrCHxA"
};
export default ___CSS_LOADER_EXPORT___;
