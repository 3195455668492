const APPROVAL_STATUS_OPTIONS = [
  {
    label: 'Approval Required',
    value: true
  },
  {
    label: 'Approval Not Required',
    value: false
  }
]

const TICKET_CREATION_OPTIONS = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
]

const TICKET_SUBJECT_TEMPLATE = {
  'okta--unlock-user': 'Unlock Okta account for {{requester.name}}',
  'okta--reset-password': 'Reset Password for {{requester.name}}',
  'okta--reset-mfa': 'Reset MFA for {{requester.name}}',
  'azure-ad--reset-mfa': 'Reset MFA for {{requester.name}}'
}

const AI_ACTION_DESCRIPTIONS = {
  'okta--unlock-user': `when the user exceeds the number of login attempts defined in the login policy for the organization in the okta, the account will be moved to locked out status. This AI Action unlocks the user's account in Okta.`,
  'okta--reset-password': `This action allows users to initiate the process of resetting their Okta password when forgotten or for security updates, providing a secure and user-friendly password recovery process to regain account access`,
  'okta--reset-mfa': `This action involves resetting Multi-Factor Authentication settings to resolve issues or difficulties with the current MFA configuration. This process allows users to regain access to their accounts by addressing problems related to MFA setup.`
}

export { APPROVAL_STATUS_OPTIONS, TICKET_CREATION_OPTIONS, AI_ACTION_DESCRIPTIONS, TICKET_SUBJECT_TEMPLATE }
