import cx from 'classnames'
import { Fragment } from 'react'

import styles from './ListUserGroups.module.scss'

import Alert from 'Icons/alert.svg'
import Reload from 'Icons/reload.svg'

export const Name = (props) => {
  const { name, is_syncing, sync_failed_users_count = 0 } = props.cell.value
  return (
    <Fragment>
      <span className={styles.UserGroupNameCell}>{name}</span>
      {is_syncing && (
        <span className={cx(styles.Badge, styles.SyncingBadge)}>
          Sync in progress <Reload />
        </span>
      )}
      {!is_syncing && sync_failed_users_count > 0 && (
        <span className={cx(styles.Badge, styles.AlertBadge)}>
          Partial Import <Alert className={styles.AlertIcon} />
        </span>
      )}
    </Fragment>
  )
}
