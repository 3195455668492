import { useCallback, useRef, useState } from 'react'

const defaultOptions = {
  timeout: 3 * 1000
}

const useClipboard = (options = defaultOptions) => {
  const [copied, setCopied] = useState(false)
  const timeoutIndexRef = useRef(null)

  const copy = useCallback(
    async (text) => {
      clearTimeout(timeoutIndexRef.current)

      // var type = "text/plain";
      // var blob = new Blob([text], { type });
      // var data = [new ClipboardItem({ [type]: blob })];

      try {
        await navigator.clipboard.writeText(text)
        setCopied(true)
      } catch {
        setCopied(false)
      } finally {
        const timeoutIndex = setTimeout(() => {
          setCopied(false)
        }, options.timeout)

        timeoutIndexRef.current = timeoutIndex
      }
    },
    [options.timeout]
  )

  return [copied, copy]
}

export default useClipboard
