import { Fragment, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import PageLoader from 'Components/PageLoader'
import api from 'Services/api'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

import InstallNotion from './InstallNotion'
import ManageNotion from './ManageNotion'

const Notion = () => {
  const location = useLocation()
  const { currentWorkspaceId, setCurrentWorkspaceId } = useWorkspace()
  const { isLoading, data: app = {} } = api.useGetNotionAppQuery({ workspace_id: currentWorkspaceId })

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const urlWorkspaceId = searchParams.get('workspace_id')

    if (urlWorkspaceId && urlWorkspaceId !== currentWorkspaceId) {
      setCurrentWorkspaceId(urlWorkspaceId)
    }
  }, [location.search, currentWorkspaceId, setCurrentWorkspaceId])

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <Fragment>
      {!app.is_linked && <InstallNotion app={app} />}
      {app.is_linked && <ManageNotion app={app} />}
    </Fragment>
  )
}

export default Notion
