export const METRICS = {
  REQUEST_METRICS: {
    label: 'Request Metrics',
    summary: 'Number of requests',
    info: 'Number of questions asked by users.'
  },
  USERS: {
    label: 'Users',
    summary: 'Number of active users',
    info: ({ accountType }) =>
      accountType === 'Slack'
        ? 'Number of users who created requests through Slack.'
        : 'Number of users who created requests through MS Teams.'
  },
  TOTAL_SUGGESTIONS: {
    label: 'Total Suggestions',
    summary: 'Number of Suggestions',
    info: 'Number of requests where bot was able to suggest at-least one article.'
  },
  ARTICLES_REQUESTED: {
    label: 'Articles Requested',
    summary: '',
    info: 'Recent 5 requests where the user explicitly asked for a KB article.'
  },
  RESOLUTION_METRICS: {
    label: 'Resolution Metrics',
    summary: 'Resolved with feedback',
    info: (
      <span>
        <b>Resolved with feedback: </b>Number of requests where bot suggestions were provided and marked helpful. <br />{' '}
        <br /> <b>Resolved without feedback: </b>
        Number of requests were bot suggestions were provided but no feedback was given or a ticket was created.
      </span>
    )
  },
  TICKET_DEFLECTION_RATE: {
    label: 'Ticket Deflection Rate',
    summary: 'Automated Resolution Rate',
    info: 'The percentage at which Assist AI was able to prevent tickets from being raised.'
  },
  AUTO_TICKET_RES_RATE: {
    label: 'Auto ticket resolution rate',
    summary: 'Automated Resolution Rate',
    info: 'The percentage at which Assist AI was able to auto resolve tickets raised via other channels.'
  },
  OVERALL_AUTO_RES_RATE: {
    label: 'Overall Auto Resolution Rate',
    summary: 'Automated Resolution Rate',
    info: 'The percentage at which Assist AI was able to auto resolve and deflect tickets across all channels.'
  },
  TICKETS_CREATED: {
    label: 'Tickets Created',
    summary: 'Number of tickets created',
    info: 'Number of requests where a ticket was raised.'
  },
  TOP_CATEGORY: {
    label: 'Top Category',
    summary: 'Category with the most number of tickets created',
    info: 'Category with the most number of tickets raised.'
  },
  COVERAGE_RATE: {
    label: 'Coverage Rate',
    summary: 'Coverage Rate',
    info: 'Percentage at which the content can handle requests on its own.'
  },
  COVERAGE_OPPS: {
    label: 'Coverage Opportunities',
    summary: '',
    info: '5 most recent requests where Assist AI suggested no articles and no feedback was provided.'
  },
  MOST_HELPFUL_ANS: {
    label: 'Most Helpful Articles',
    summary: '',
    info: 'Top 5 articles that users found helpful.'
  },
  LEAST_HELPFUL_ANS: {
    label: 'Least Helpful Articles',
    summary: '',
    info: 'Top 5 articles that did not resolve user queries.'
  }
}
