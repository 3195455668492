import { Heading, Text } from '@happyfoxinc/react-ui'
import { Fragment } from 'react'

import styles from './AiActions.module.scss'

import PageLoader from 'Src/components/PageLoader'

import Table from './Table'

const AiActions = ({ data, isLoading }) => {
  if (isLoading) {
    return <PageLoader />
  }

  return (
    <Fragment>
      <div className={styles.Container}>
        <Heading level={2}>AI Actions</Heading>
        <Text variant='muted' className={styles.HelpText}>
          AI actions utilize Assist AI's capabilities to swiftly execute tasks, simplifying and expediting users'
          actions without the need for manual navigation through conventional processes.
        </Text>
        <Table data={data} />
      </div>
    </Fragment>
  )
}

export default AiActions
