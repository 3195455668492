// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KB_fa21LWxSpqI30YUkH{margin:1rem 0;padding-top:1rem;border-top:1px solid #eee}.TJvMOAKH1yeH3J4ONf27{margin-top:1rem}.cOb1504Hfi7E4LqeGbOR{text-align:right}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/AppDetails/apps/Notion/NotionOldSelectPages/NotionSelectPages.module.css"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,gBAAA,CACA,yBAAA,CAGF,sBACE,eAAA,CAGF,sBACE,gBAAA","sourcesContent":[".Container {\n  margin: 1rem 0;\n  padding-top: 1rem;\n  border-top: 1px solid #eee;\n}\n\n.CheckboxTreeContainer {\n  margin-top: 1rem;\n}\n\n.SyncButtonContainer {\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "KB_fa21LWxSpqI30YUkH",
	"CheckboxTreeContainer": "TJvMOAKH1yeH3J4ONf27",
	"SyncButtonContainer": "cOb1504Hfi7E4LqeGbOR"
};
export default ___CSS_LOADER_EXPORT___;
