import { useState } from 'react'

import { useRedirectConfirmationContext } from 'Components/ConfirmationModal/RedirectConfirmationContext'

import InstallApp from '../../../components/InstallApp'
import { useAppDetailContext } from '../../AppDetailContext'

const InstallSharePoint = (props) => {
  const [isInstalling, setIsInstalling] = useState()
  const { app } = props
  const { title } = useAppDetailContext()
  const externalRedirect = useRedirectConfirmationContext()

  const handleInstall = () => {
    setIsInstalling(true)
    externalRedirect(app.authorize_url, {
      handleCancel: () => setIsInstalling(false)
    })
  }

  return <InstallApp name={title} onInstall={handleInstall} isInstalling={isInstalling} />
}

export default InstallSharePoint
