import { Button } from '@happyfoxinc/react-ui'
import { Fragment, useState } from 'react'

import InviteAdminModal from './InviteAdminModal'

const InviteAdmin = () => {
  const [showModal, setShowModal] = useState(false)

  const handleInviteAdminClick = () => {
    setShowModal(true)
  }

  const handleHideModal = () => {
    setShowModal(false)
  }

  return (
    <Fragment>
      {showModal && <InviteAdminModal onHide={handleHideModal} />}
      <Button variant='primary' onClick={handleInviteAdminClick}>
        Invite Admin
      </Button>
    </Fragment>
  )
}

export default InviteAdmin
