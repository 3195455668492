import {
  Button,
  Checkbox,
  PaginationControls,
  Table,
  TableActions,
  TableBody,
  TableHead,
  TableRow,
  Td,
  Text,
  Th
} from '@happyfoxinc/react-ui'
import { Fragment, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { usePagination, useTable } from 'react-table'

import styles from './AddCollections.module.css'

import api from 'Services/api'
import PageLoader from 'Src/components/PageLoader'
import debounce from 'Utils/debounce'

const PAGE_SIZE = 5
const defaultApiParams = { offset: 0, limit: PAGE_SIZE }

const AddCollectionsTable = ({ checkedItems, setCheckedItems }) => {
  const [queryParams, setQueryParams] = useState(defaultApiParams)
  const { data = {}, isLoading } = api.useGetCollectionsQuery(queryParams)
  const { results: collections = [], meta: paginationDetails = {} } = data

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Collections',
        accessor: 'name'
      }
    ],
    []
  )

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: collections,
      initialState: {
        pageIndex: 0,
        pageSize: PAGE_SIZE
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / PAGE_SIZE)
    },
    usePagination
  )

  useEffect(() => {
    const apiParams = {
      offset: pageIndex * PAGE_SIZE,
      limit: PAGE_SIZE
    }

    debouncedSetQueryParams(apiParams)
  }, [pageIndex, debouncedSetQueryParams])

  const handleRowClick = (id) => {
    setCheckedItems((prev) => (prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]))
  }

  const isChecked = (id) => checkedItems.includes(id)

  return (
    <div className={styles.AddCollectionsContainer}>
      <Text>Add collections to sync from</Text>
      <PageLoader isLoading={isLoading}>
        <div className={styles.TableContainer}>
          <TableActions>
            <PaginationControls
              currentPage={pageIndex + 1}
              pageSize={PAGE_SIZE}
              totalItems={paginationDetails.total}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              previousPage={previousPage}
              nextPage={nextPage}
            />
          </TableActions>
          <Table className={styles.AddCollectionsTable} {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => {
                const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

                return (
                  <TableRow key={key} {...headerGroupProps} style={{ backgroundColor: '#EEEEEE' }}>
                    {headerGroup.headers.map((column) => {
                      const { key, ...headerProps } = column.getHeaderProps()

                      return (
                        <Th key={key} {...headerProps} className={styles.TableCell}>
                          <Text variant='muted'>{column.render('Header')}</Text>
                        </Th>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                const { key, ...rowProps } = row.getRowProps()
                const rowId = row.original.id

                return (
                  <TableRow key={key} {...rowProps} clickable>
                    {row.cells.map((cell) => {
                      const { key, ...cellProps } = cell.getCellProps()
                      return (
                        <Td className={styles.TableCell} key={key} {...cellProps}>
                          <Checkbox
                            id={rowId}
                            checked={isChecked(rowId)}
                            label={cell.render('Cell')}
                            onCheckedChange={() => handleRowClick(rowId)}
                          />
                        </Td>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </PageLoader>
    </div>
  )
}

const AddCollections = () => {
  const [checkedItems, setCheckedItems] = useState([])
  const [addCollections, addCollectionsResult] = api.useAddCollectionsMutation()

  const handleAddCollections = () => {
    const promise = addCollections({ collection_ids: checkedItems }).unwrap()
    promise.then(() => setCheckedItems([]))

    toast.promise(promise, {
      loading: 'Adding collections',
      success: 'Collections added successfully',
      error: 'Unable to add collections. Try again'
    })
  }

  const disableAddButton = checkedItems.length === 0 || addCollectionsResult.isLoading

  return (
    <Fragment>
      <AddCollectionsTable checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
      <Button
        onClick={handleAddCollections}
        className={styles.AddCollectionsButton}
        variant='primary'
        disabled={disableAddButton}
      >
        Add Collections
      </Button>
    </Fragment>
  )
}

export default AddCollections
