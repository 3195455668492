import cx from 'classnames'
import { createContext, useContext } from 'react'
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom'

import styles from './PageTabs.module.scss'

const TabContext = createContext({
  pathSensitive: false
})

const useTabContext = () => useContext(TabContext)

const PageTabs = (props) => {
  const { pathSensitive = false, ...rest } = props

  return (
    <TabContext.Provider value={{ pathSensitive }}>
      <div className={styles.Tabs} {...rest} />
    </TabContext.Provider>
  )
}

export const PageTab = ({ title, to, disabled = false, pathSensitive }) => {
  const navigate = useNavigate()
  const { pathname } = useResolvedPath(to)
  const config = useTabContext()

  const match = useMatch({
    path: pathname,
    end: pathSensitive ?? config.pathSensitive
  })

  const isTabActive = Boolean(match)

  const handleTabClick = () => {
    navigate(to)
  }

  return (
    <li className={styles.TabItem}>
      <button
        className={cx(styles.Tab, { [styles.Active]: isTabActive && !disabled, [styles.Disabled]: disabled })}
        title={title}
        onClick={handleTabClick}
        role='tab'
      >
        {title}
      </button>
    </li>
  )
}

export const PageTabList = ({ className, ...rest }) => {
  return <ul className={cx(styles.TabList, className)} {...rest} role='tablist' />
}

export const PageTabPanel = (props) => {
  return <div {...props} role='tabpanel' />
}

export default PageTabs
