import { Button } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import styles from './UserGroup.module.scss'

import PageTitle from 'Components/PageTitle'
import api from 'Services/api'
import parseErrorMessage from 'Utils/error-message-parser'

import { createPayloadFromUserGroupForm } from '../usergroups-helper'
import UserGroupForm from './UserGroupForm'
import userGroupFormValidationSchema from './UserGroupForm/user-group-form-validation-schema'

const CreateUserGroup = () => {
  const navigate = useNavigate()
  const { data: account } = api.useGetAccountQuery()
  const accountType = account.account_type

  const formMethods = useForm({
    defaultValues: {
      name: '',
      users: [],
      addedFrom: null,
      syncedFromGroup: null,
      conditions: []
    },
    resolver: yupResolver(userGroupFormValidationSchema)
  })

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = formMethods

  const [createUserGroup, createUserGroupResult] = api.useCreateUserGroupMutation()

  const handleFormSubmit = async (formData) => {
    const payload = { ...createPayloadFromUserGroupForm(formData, accountType) }

    try {
      const promise = createUserGroup(payload).unwrap()

      toast.promise(promise, {
        loading: 'Creating user group',
        success: 'User group created successfully',
        error: parseErrorMessage('Unable to create user group. Try again...')
      })

      await promise
      navigate('../', { replace: true })
    } catch {}
  }

  const disableCreateButton = isSubmitting || createUserGroupResult.isLoading

  return (
    <div className={styles.FormContainer}>
      <div className={styles.PageTitle}>
        <PageTitle backTo='../'>Create User Group</PageTitle>
      </div>
      <FormProvider {...formMethods}>
        <UserGroupForm />
      </FormProvider>
      <div className={styles.ButtonContainer}>
        <Button variant='primary' onClick={handleSubmit(handleFormSubmit)} disabled={disableCreateButton}>
          Create
        </Button>
        <Button variant='link-muted' onClick={() => navigate('..')}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default CreateUserGroup
