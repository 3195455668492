import { Navigate, Outlet, Route, Routes } from 'react-router-dom'

import { FeatureProtected, ROUTE_FEATURE_GROUP } from 'Components/Features'

import Announcement from './Announcement'
import DashboardContent from './DashboardContent'

const Dashboard = () => {
  return (
    <Routes>
      <Route path='/' element={<Outlet />}>
        <Route path='' index element={<DashboardContent />} />
        <Route
          path='announcement'
          element={
            <FeatureProtected group={ROUTE_FEATURE_GROUP.dm_workflow_mode}>
              <Announcement />
            </FeatureProtected>
          }
        />
        <Route path='*' element={<Navigate to='' replace />} />
      </Route>
    </Routes>
  )
}

export default Dashboard
