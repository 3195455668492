import { Heading } from '@happyfoxinc/react-ui'
import { useNavigate } from 'react-router-dom'

import styles from './PageTitle.module.scss'

import BackIcon from 'Icons/back-arrow-circle.svg'

const PageTitle = ({ backTo, children, ...rest }) => {
  const navigate = useNavigate()

  const handleBackClick = () => {
    navigate(backTo)
  }

  return (
    <Heading {...rest} className={styles.Title}>
      {backTo && (
        <button className={styles.ButtonContainer} onClick={handleBackClick}>
          <BackIcon className={styles.BackButton} />
        </button>
      )}{' '}
      {children}
    </Heading>
  )
}

export default PageTitle
