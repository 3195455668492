// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MUA4FdgENWNxZHcEqZ4g{display:flex;font-size:13px;margin-top:auto;background-color:var(--main-bg);width:100%;padding:var(--button-padding-y) var(--button-padding-x);border-radius:2px}.Cf4ik9rAn8eo5IB73a6g{margin-left:1rem}.Dk0vdzF13ERSCpzasaV7{margin-left:auto;padding:0;font-weight:normal;display:flex;align-items:center;gap:.5rem}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/components/AppFooter/AppFooter.module.css"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,cAAA,CACA,eAAA,CACA,+BAAA,CACA,UAAA,CACA,uDAAA,CACA,iBAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,gBAAA,CACA,SAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,SAAA","sourcesContent":[".Container {\n  display: flex;\n  font-size: 13px;\n  margin-top: auto;\n  background-color: var(--main-bg);\n  width: 100%;\n  padding: var(--button-padding-y) var(--button-padding-x);\n  border-radius: 2px;\n}\n\n.Status {\n  margin-left: 1rem;\n}\n\n.UninstallButton {\n  margin-left: auto;\n  padding: 0;\n  font-weight: normal;\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "MUA4FdgENWNxZHcEqZ4g",
	"Status": "Cf4ik9rAn8eo5IB73a6g",
	"UninstallButton": "Dk0vdzF13ERSCpzasaV7"
};
export default ___CSS_LOADER_EXPORT___;
