import { Heading, PaginationControls, TableActions } from '@happyfoxinc/react-ui'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { usePagination, useTable } from 'react-table'

import styles from './NotionAccounts.module.scss'

import PageLoader from 'Components/PageLoader'
import api from 'Services/api'
import debounce from 'Utils/debounce'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

const PAGE_SIZE = 15
const defaultApiParams = { offset: 0, limit: PAGE_SIZE }

const getInitialApiQueryParams = (currentWorkspaceId) => {
  return {
    ...defaultApiParams,
    workspace_id: currentWorkspaceId
  }
}

const NotionAccounts = () => {
  const { currentWorkspaceId } = useWorkspace()
  const [queryParams, setQueryParams] = useState(getInitialApiQueryParams(currentWorkspaceId))

  const { isLoading, data = {} } = api.useGetNotionUserAccountsQuery(queryParams)
  const { results: accounts = [], meta: paginationDetails = {} } = data

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Workspace Name',
        accessor: 'workspace_name',
        disableSortBy: true
      },
      {
        Header: 'Account Email ID',
        accessor: 'email_id',
        disableSortBy: false
      },
      {
        Header: 'Agent Name',
        accessor: 'agent_name',
        disableSortBy: false
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: accounts,
      initialState: {
        pageIndex: 0,
        pageSize: PAGE_SIZE
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / PAGE_SIZE)
    },
    usePagination
  )

  useEffect(() => {
    const params = {
      workspace_id: currentWorkspaceId
    }

    const apiParams = {
      offset: pageIndex * PAGE_SIZE,
      limit: PAGE_SIZE,
      ...params
    }

    debouncedSetQueryParams(apiParams)
  }, [pageIndex, debouncedSetQueryParams, currentWorkspaceId])

  return (
    <Fragment>
      <Heading level={2}>Linked Notion Accounts</Heading>
      <PageLoader isLoading={isLoading}>
        <div className={styles.TableContainer}>
          <TableActions>
            <PaginationControls
              currentPage={pageIndex + 1}
              pageSize={PAGE_SIZE}
              totalItems={paginationDetails.total}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              previousPage={previousPage}
              nextPage={nextPage}
            />
          </TableActions>
          <table className={styles.Table} {...getTableProps()}>
            <thead className={styles.Th}>
              {headerGroups.map((headerGroup) => {
                const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

                return (
                  <tr key={key} {...headerGroupProps}>
                    {headerGroup.headers.map((column) => {
                      const { key, ...headerProps } = column.getHeaderProps()

                      return (
                        <th className={styles.Th} key={key} {...headerProps}>
                          {column.render('Header')}
                        </th>
                      )
                    })}
                  </tr>
                )
              })}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                const { key, ...rowProps } = row.getRowProps()

                return (
                  <tr key={key} {...rowProps}>
                    {row.cells.map((cell) => {
                      const { key, ...cellProps } = cell.getCellProps()
                      return (
                        <td className={styles.Td} key={key} {...cellProps}>
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </PageLoader>
    </Fragment>
  )
}

export default NotionAccounts
