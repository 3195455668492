import {
  Heading,
  Input,
  PaginationControls,
  Table,
  TableActions,
  TableBody,
  TableHead,
  TableRow,
  Td,
  Text,
  Th
} from '@happyfoxinc/react-ui'
import { Fragment, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { usePagination, useTable } from 'react-table'

import styles from './ManageGDrive.module.css'

import { SecondaryButton } from 'Components/Buttons'
import ConfirmationModal from 'Components/ConfirmationModal'
import PageLoader from 'Components/PageLoader'
import api from 'Services/api'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

import AppFooter from '../../../components/AppFooter'
import { ConnectedAppBase } from '../../../components/Common/CommonUpgrade'
import GDriveSyncTable from './GDriveSyncTable'
import GDriveTableOptions from './GDriveTableOptions'

const PAGE_SIZE = 5

const ManageGDrive = ({ app }) => {
  const { currentWorkspaceId } = useWorkspace()
  const defaultApiParams = { offset: 0, limit: PAGE_SIZE, workspace_id: currentWorkspaceId }
  const [queryParams, setQueryParams] = useState(defaultApiParams)

  const [folderId, setFolderId] = useState('')

  const [excludeGDriveFolder] = api.useExcludeGDriveFolderMutation()
  const { data = {}, isLoading } = api.useGetExcludedGDriveFoldersQuery(queryParams)
  const { results: excludedFolders = [], meta: paginationDetails = {} } = data

  const [isUninstallModalOpen, setIsUninstallModalOpen] = useState(false)
  const [uninstall, uninstallResult] = api.useUninstallGDriveMutation()

  const handleUninstall = () => {
    const promise = uninstall({ workspace_id: currentWorkspaceId }).unwrap()
    promise.then(() => setIsUninstallModalOpen(false))

    toast.promise(promise, {
      loading: 'Uninstalling Google Drive',
      success: 'Google Drive integration uninstalled successfully',
      error: 'Unable to uninstall Google Drive. Try again'
    })
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Folder ID',
        accessor: 'id'
      },
      {
        Header: 'Folder Name',
        accessor: 'name'
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: excludedFolders,
      initialState: {
        pageIndex: 0,
        pageSize: PAGE_SIZE
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / PAGE_SIZE)
    },
    usePagination
  )

  useEffect(() => {
    const apiParams = {
      offset: pageIndex * PAGE_SIZE,
      limit: PAGE_SIZE,
      workspace_id: currentWorkspaceId
    }

    setQueryParams(apiParams)
  }, [currentWorkspaceId, pageIndex])

  const handleExcludeClick = async () => {
    const toastId = toast.loading('Excluding folder')
    try {
      await excludeGDriveFolder({ folder_id: folderId, workspace_id: currentWorkspaceId }).unwrap()
      toast.success('Folder excluded successfully', { id: toastId })
    } catch (err) {
      const errData = err?.data?.error || `Unable to exclude folder. Try again`
      toast.error(errData, { id: toastId })
    }
  }

  return (
    <Fragment>
      <ConnectedAppBase>
        <Heading level={2}>
          Successfully connected to Google Drive.
          <br /> The account is tied to{' '}
          <Text isInline variant='muted'>
            {app.linked_email}
          </Text>
        </Heading>
      </ConnectedAppBase>
      <Fragment>
        <Text>Folders currently syncing from</Text>
        <GDriveSyncTable app={app} />
      </Fragment>

      <Fragment>
        <Text>Exclude folders </Text>
        <div className={styles.ExcludeContainer}>
          <Input
            placeholder='Enter folder ID to exclude'
            value={folderId}
            onChange={(e) => setFolderId(e.target.value)}
          />
          <SecondaryButton onClick={handleExcludeClick}>Exclude</SecondaryButton>
        </div>
      </Fragment>
      {excludedFolders.length > 0 && (
        <div className={styles.ExcludedTableContainer}>
          <Text>Excluded folders </Text>
          <PageLoader isLoading={isLoading}>
            <div className={styles.TableContainer}>
              <TableActions>
                <PaginationControls
                  currentPage={pageIndex + 1}
                  pageSize={PAGE_SIZE}
                  totalItems={paginationDetails.total}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                />
              </TableActions>
              <Table {...getTableProps()} className={styles.ExcludedFoldersTable}>
                <TableHead>
                  {headerGroups.map((headerGroup) => {
                    const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()
                    return (
                      <TableRow key={key} {...headerGroupProps}>
                        {headerGroup.headers.map((column) => {
                          const { key, ...headerProps } = column.getHeaderProps()

                          return (
                            <Th key={key} {...headerProps} className={styles.TableHeader}>
                              {column.render('Header')}
                            </Th>
                          )
                        })}
                        <Th className={styles.TableHeader} isOption />
                      </TableRow>
                    )
                  })}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row)
                    const { key, ...rowProps } = row.getRowProps()
                    const id = row.original.id
                    return (
                      <TableRow key={key} {...rowProps}>
                        {row.cells.map((cell) => {
                          const { key, ...cellProps } = cell.getCellProps()
                          return (
                            <Td key={key} {...cellProps} className={styles.ExcludedFoldersTableTD}>
                              {cell.render('Cell')}
                            </Td>
                          )
                        })}
                        <Td className={styles.ExcludedFoldersTableTD}>
                          <GDriveTableOptions id={id} />
                        </Td>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </div>
          </PageLoader>
        </div>
      )}
      <AppFooter
        onUninstall={() => setIsUninstallModalOpen(true)}
        isUninstalling={uninstallResult.isLoading}
        app={app}
      />
      <ConfirmationModal
        isOpen={isUninstallModalOpen}
        variant='alert'
        message='Are you sure you want to uninstall Google Drive?'
        onCancel={() => setIsUninstallModalOpen(false)}
        onConfirm={handleUninstall}
      />
    </Fragment>
  )
}

export default ManageGDrive
