import { Heading } from '@happyfoxinc/react-ui'
import { Fragment, useState } from 'react'
import toast from 'react-hot-toast'

import { SecondaryButton } from 'Components/Buttons'
import ConfirmationModal from 'Components/ConfirmationModal'
import { useRedirectConfirmationContext } from 'Components/ConfirmationModal/RedirectConfirmationContext'
import { useFeature } from 'Components/Features'
import { ACCOUNT_TYPE } from 'Constants/account'
import { CLIENT_FEATURE_FLAGS } from 'Constants/feature-flags'
import { CONFIRM_MESSAGES } from 'Constants/messages'
import api from 'Services/api'
import { useAppDetailContext } from 'Src/pages/protected/Apps/AppDetails/AppDetailContext'
import QuickActionsConfig from 'Src/pages/protected/Apps/components/QuickActionsConfig'
import parseErrorMessage from 'Utils/error-message-parser'

import AiActions from '../../../components/AiActions'
import AppFooter from '../../../components/AppFooter'
import { ConnectedAppBase } from '../../../components/Common/CommonUpgrade'

const ManageAzureAD = (props) => {
  const { app } = props
  const { title } = useAppDetailContext()

  const { data: account } = api.useGetAccountQuery()
  const { data: aiActionTableData = {}, isLoading: aiActionTableDataLoading } = api.useGetAllAzureADAIActionsQuery()

  const isTicketingSystemConnected = account.is_ticketing_integration_connected
  const isMSTeamAccountType = account.account_type === ACCOUNT_TYPE.MS_TEAMS
  const isDMWorkflowEnabled = useFeature([CLIENT_FEATURE_FLAGS.DM_WORKFLOW_MODE])
  const enableQuickActionsConfig = isDMWorkflowEnabled && isTicketingSystemConnected

  const [uninstall, uninstallResult] = api.useUninstallAzureADMutation()

  const [isUninstallModalOpen, setIsUninstallModalOpen] = useState(false)
  const handleUninstall = () => {
    const promise = uninstall().unwrap()
    promise.then(() => setIsUninstallModalOpen(false))

    toast.promise(promise, {
      loading: `Uninstalling ${title}`,
      success: `${title} integration uninstalled successfully`,
      error: parseErrorMessage(`Unable to uninstall ${title}. Try again`)
    })
  }

  const externalRedirect = useRedirectConfirmationContext()
  const handleReconnect = () => {
    externalRedirect(app.authorize_url)
  }

  const displayAiActions = isMSTeamAccountType && app.is_ai_actions_allowed

  return (
    <Fragment>
      <ConnectedAppBase>
        <Heading level={2}>Successfully connected to {title}.</Heading>
        <SecondaryButton onClick={handleReconnect}>Reconnect</SecondaryButton>
      </ConnectedAppBase>
      {enableQuickActionsConfig && <QuickActionsConfig data={app.quick_actions} />}
      {displayAiActions && <AiActions data={aiActionTableData} loading={aiActionTableDataLoading} />}
      <AppFooter onUninstall={() => setIsUninstallModalOpen(true)} isUninstalling={uninstallResult.isLoading} />
      <ConfirmationModal
        isOpen={isUninstallModalOpen}
        variant='alert'
        message={CONFIRM_MESSAGES.UNINSTALL_AZURE_AD}
        onCancel={() => setIsUninstallModalOpen(false)}
        onConfirm={handleUninstall}
      />
    </Fragment>
  )
}

export default ManageAzureAD
