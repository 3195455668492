import * as yup from 'yup'

import { KNOWLEDGE_GROUP_VISIBILITY_STATES } from 'Constants/user-groups'
import noHtmlTagTypeEnclosedText from 'Utils/inputValidation/noHtmlTagTypeEnclosedText'

const answerFormValidationSchema = yup
  .object({
    name: yup
      .string()
      .trim()
      .required('Name is required')
      .min(3, 'Name should contain at least ${min} characters')
      .test(noHtmlTagTypeEnclosedText),
    content: yup.string().trim().required('Content is required'),
    visibility: yup.string().oneOf(Object.values(KNOWLEDGE_GROUP_VISIBILITY_STATES)).required(),
    visibleToGroups: yup.array().when('visibility', {
      is: KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC,
      then: (schema) => schema.min(1, 'Please select at least 1 User group').required()
    })
  })
  .required()

export default answerFormValidationSchema
