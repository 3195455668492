import { Flex } from '@happyfoxinc/react-ui'

import styles from './BadgeContainer.module.scss'

import Badge from 'Components/Badge'

const BadgeContainer = ({ fields, selectedFields, onClick }) => {
  return (
    <Flex wrap='wrap' gap='sm' className={styles.BadgeContainer}>
      {fields.map((field) => {
        const isSelected = selectedFields.includes(field)

        return (
          <Badge
            key={field}
            text={field}
            isSelected={isSelected}
            variant={isSelected ? 'green' : 'brown'}
            onClick={() => onClick(field)}
          />
        )
      })}
    </Flex>
  )
}

export default BadgeContainer
