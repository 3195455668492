import { Button, FormControl, FormErrorText, Heading, Input, Label } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import api from 'Services/api'
import parseErrorMessage from 'Utils/error-message-parser'

import { useAppDetailContext } from '../../AppDetailContext'
import oktaAuthFormValidationSchema from './okta-auth-form-validation-schema'

const OktaAuthForm = ({ onCancel, app }) => {
  const { title } = useAppDetailContext()
  const [install, installResult] = api.useInstallOktaMutation()
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty }
  } = useForm({
    defaultValues: {
      accountUrl: app.account_url,
      token: ''
    },
    resolver: yupResolver(oktaAuthFormValidationSchema)
  })

  const handleConnect = async (data) => {
    const payload = {
      account_url: data.accountUrl,
      api_token: data.token
    }
    try {
      const promise = install(payload).unwrap()
      toast.promise(promise, {
        loading: `Connecting to ${title}`,
        success: `Connected to ${title}`,
        error: parseErrorMessage(`Unable to connect to ${title}. Try again...`)
      })
      await promise
      onCancel()
    } catch {}
  }

  const disableSubmitButton = isSubmitting || !isDirty || installResult.isLoading
  return (
    <Fragment>
      <Heading level={2}>Connect to {title}</Heading>
      <form onSubmit={handleSubmit(handleConnect)}>
        <FormControl isInvalid={errors.accountUrl}>
          <Label>Account URL</Label>
          <Input {...register('accountUrl')} />
          {errors.accountUrl && <FormErrorText>{errors.accountUrl.message}</FormErrorText>}
        </FormControl>
        <FormControl isInvalid={errors.apiKey}>
          <Label>API Token</Label>
          <Input {...register('token')} />
          {errors.token && <FormErrorText>{errors.token.message}</FormErrorText>}
        </FormControl>
        <Button variant='primary' type='submit' disabled={disableSubmitButton}>
          Authorize
        </Button>
        <Button variant='link-muted' onClick={onCancel}>
          Cancel
        </Button>
      </form>
    </Fragment>
  )
}

export default OktaAuthForm
