// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tYODNtCuVRablD4z51de{margin-top:2rem}.M0ppf7BXpzSQnS2rCfg2 th:not(:first-child):not(:last-child){width:150px}.W9UWEtqF84QGHT47E52c{text-align:center;margin:5rem 0}.EZUuuS8FcJVauxWuPoWp{margin-right:.5rem}.TtLqilStsAAIxDNxxSFK{border-radius:var(--border-radius);padding:.1rem .5rem;display:inline-flex;align-items:center}.TtLqilStsAAIxDNxxSFK.R134n6uNH05IMHdsvV3T{background-color:#f0f0f0;-moz-column-gap:.5rem;column-gap:.5rem}.TtLqilStsAAIxDNxxSFK.WXkiGll8DSd_FvQCJ7eW{-moz-column-gap:1.6rem;column-gap:1.6rem;background-color:var(--primary-lightest)}.fXG_KCCi2cSUgt3GuB2I{color:var(--primary);width:18px;height:18px}", "",{"version":3,"sources":["webpack://./../src/pages/protected/UserGroups/ListUserGroups/ListUserGroups.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,4DACE,WAAA,CAGF,sBACE,iBAAA,CACA,aAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,kCAAA,CACA,mBAAA,CACA,mBAAA,CACA,kBAAA,CACA,2CACE,wBAAA,CACA,qBAAA,CAAA,gBAAA,CAEF,2CACE,sBAAA,CAAA,iBAAA,CACA,wCAAA,CAIJ,sBACE,oBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".Container {\n  margin-top: 2rem;\n}\n\n.AnswerTable th:not(:first-child):not(:last-child) {\n  width: 150px;\n}\n\n.NoFilterResults {\n  text-align: center;\n  margin: 5rem 0;\n}\n\n.UserGroupNameCell {\n  margin-right: 0.5rem;\n}\n\n.Badge {\n  border-radius: var(--border-radius);\n  padding: 0.1rem 0.5rem;\n  display: inline-flex;\n  align-items: center;\n  &.SyncingBadge {\n    background-color: #f0f0f0;\n    column-gap: 0.5rem;\n  }\n  &.AlertBadge {\n    column-gap: 1.6rem;\n    background-color: var(--primary-lightest);\n  }\n}\n\n.AlertIcon {\n  color: var(--primary);\n  width: 18px;\n  height: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "tYODNtCuVRablD4z51de",
	"AnswerTable": "M0ppf7BXpzSQnS2rCfg2",
	"NoFilterResults": "W9UWEtqF84QGHT47E52c",
	"UserGroupNameCell": "EZUuuS8FcJVauxWuPoWp",
	"Badge": "TtLqilStsAAIxDNxxSFK",
	"SyncingBadge": "R134n6uNH05IMHdsvV3T",
	"AlertBadge": "WXkiGll8DSd_FvQCJ7eW",
	"AlertIcon": "fXG_KCCi2cSUgt3GuB2I"
};
export default ___CSS_LOADER_EXPORT___;
