// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".J2yUXuP1n88PwcamhK8n{color:#fff;height:var(--topbar-height);display:flex;align-items:center;flex-shrink:0;padding-left:1.75rem;box-shadow:var(--topbar-shadow)}", "",{"version":3,"sources":["webpack://./../src/components/Sidebar/Brand.module.css"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,2BAAA,CACA,YAAA,CACA,kBAAA,CACA,aAAA,CACA,oBAAA,CACA,+BAAA","sourcesContent":[".BrandContainer {\n  color: white;\n  height: var(--topbar-height);\n  display: flex;\n  align-items: center;\n  flex-shrink: 0;\n  padding-left: 1.75rem;\n  box-shadow: var(--topbar-shadow);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BrandContainer": "J2yUXuP1n88PwcamhK8n"
};
export default ___CSS_LOADER_EXPORT___;
