import { Button, FormControl, FormErrorText, Label, Text } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import styles from '../Announcement.module.scss'

import { SecondaryButton } from 'Components/Buttons'
import ConfirmationModal from 'Components/ConfirmationModal'
import PageLoader from 'Components/PageLoader'
import Tooltip, { TooltipContent, TooltipTrigger } from 'Components/Tooltip'
import TrixEditor from 'Components/TrixEditor'
import { ANNOUNCEMENT_STATUS } from 'Constants/announcement-status'
import { CONFIRM_MESSAGES } from 'Constants/messages'
import api from 'Services/api'
import { Prompt } from 'Src/history'
import parseErrorMessage from 'Utils/error-message-parser'

import announcementFormValidationSchema from './announcement-form-validation-schema'

const AnnouncementFormHandler = ({ announcement }) => {
  const formMethods = useForm({
    defaultValues: {
      content: announcement.content
    },
    resolver: yupResolver(announcementFormValidationSchema)
  })
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    getValues,
    reset
  } = formMethods

  const [isSendToAllConfirmationModalOpen, setIsSendToAllConfirmationModalOpen] = useState(false)
  const [sendToAllStatus, setSendToAllStatus] = useState(ANNOUNCEMENT_STATUS.PENDING)
  const navigate = useNavigate()

  const [save, saveResult] = api.useSaveAnnouncementMutation()
  const [preview, previewResult] = api.usePreviewAnnouncementMutation()
  const [sendToAll, sendToAllResult] = api.useSendAnnouncementToAllMutation()

  const handleSave = async (data) => {
    const promise = save(data).unwrap()
    toast.promise(promise, {
      loading: 'Saving the Announcement message...',
      success: 'Announcement message saved successfully.',
      error: parseErrorMessage('Unable to save the Announcement message.')
    })
    promise.then(() => reset(data))
  }

  const handlePreview = async (data) => {
    const promise = preview(data).unwrap()
    toast.promise(promise, {
      loading: 'Sending the Announcement message to you on Slack...',
      success: 'Announcement message sent to you successfully.',
      error: parseErrorMessage('Unable to send you the Announcement message.')
    })
    promise.then(() => reset(data))
  }

  const handleSendToAll = async () => {
    setIsSendToAllConfirmationModalOpen(false)
    const data = getValues()

    const toastId = toast.loading(`Triggering the Announcement...`)

    try {
      const { announcement_sent, is_announcement_being_sent } = await sendToAll(data).unwrap()
      toast.dismiss(toastId)
      reset(data)

      if (announcement_sent) {
        setSendToAllStatus(ANNOUNCEMENT_STATUS.COMPLETED)
      }
      if (is_announcement_being_sent) {
        setSendToAllStatus(ANNOUNCEMENT_STATUS.IN_PROGRESS)
      }
    } catch (err) {
      const errorMessage = parseErrorMessage(`Unable to trigger the Announcement. Try again...`)(err)
      toast.error(errorMessage, { id: toastId })
    }
  }

  const disableButton = isSubmitting || saveResult.isLoading || sendToAllResult.isLoading || previewResult.isLoading

  const ButtonContainer = () => (
    <div className={styles.ButtonContainer}>
      <SecondaryButton disabled={disableButton} onClick={handleSubmit(handleSave)}>
        Save
      </SecondaryButton>

      <Tooltip delayDuration={250}>
        <TooltipTrigger asChild className={styles.TooltipTrigger}>
          <SecondaryButton disabled={disableButton} onClick={handleSubmit(handlePreview)}>
            Preview
          </SecondaryButton>
        </TooltipTrigger>
        <TooltipContent side='top'>
          <Text variant='muted'>Assist AI will DM this message to you on Slack</Text>
        </TooltipContent>
      </Tooltip>

      <Button
        variant='primary'
        disabled={disableButton}
        onClick={handleSubmit(() => setIsSendToAllConfirmationModalOpen(true))}
      >
        Send DM to all
      </Button>

      <Button className={styles.CancelButton} variant='link-muted' onClick={() => navigate('..')}>
        Cancel
      </Button>
    </div>
  )

  const ModalContainer = () => {
    const isSendSuccessModalOpen = sendToAllStatus !== ANNOUNCEMENT_STATUS.PENDING
    const dispatch = useDispatch()

    const getSendSuccessModalMessage = () => {
      if (sendToAllStatus === ANNOUNCEMENT_STATUS.IN_PROGRESS) return CONFIRM_MESSAGES.ANNOUNCEMENT_IN_PROGRESS
      if (sendToAllStatus === ANNOUNCEMENT_STATUS.COMPLETED) return CONFIRM_MESSAGES.ANNOUNCEMENT_SUCCESS
    }

    const invalidateDashboardData = () => {
      dispatch(api.util.invalidateTags(['Dashboard']))
    }

    const handleSendSuccessModalConfirmation = () => {
      invalidateDashboardData()
      navigate('..')
    }

    return (
      <Fragment>
        <ConfirmationModal
          isOpen={isSendToAllConfirmationModalOpen}
          message={CONFIRM_MESSAGES.SEND_ANNOUNCEMENT}
          onCancel={() => setIsSendToAllConfirmationModalOpen(false)}
          onConfirm={handleSendToAll}
          confirmButtonText='Yes, Proceed'
        />
        <ConfirmationModal
          isOpen={isSendSuccessModalOpen}
          message={getSendSuccessModalMessage()}
          onConfirm={handleSendSuccessModalConfirmation}
          confirmButtonText='Back to Dashboard'
          cancelButtonText=''
          canClose={false}
        />
      </Fragment>
    )
  }

  return (
    <Fragment>
      <FormProvider {...formMethods}>
        <form>
          <FormControl isInvalid={errors.content}>
            <Label>Message</Label>
            <Controller
              control={control}
              name='content'
              render={({ field: { value, onChange } }) => {
                return <TrixEditor defaultValue={value} onChange={onChange} />
              }}
            />
            {errors.content && <FormErrorText>{errors.content.message}</FormErrorText>}
          </FormControl>
        </form>
        <ButtonContainer />
      </FormProvider>
      <ModalContainer />
      <Prompt when={isDirty} />
    </Fragment>
  )
}

const AnnouncementForm = () => {
  const { isLoading, data: announcement = {} } = api.useGetAnnouncementQuery()

  if (isLoading) {
    return <PageLoader />
  }

  return <AnnouncementFormHandler announcement={announcement} />
}

export default AnnouncementForm
