import React, { Fragment } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import AppLoader from 'Components/AppLoader'
import ErrorBoundary from 'Components/ErrorBoundary'
import FeaturesProvider, { canAllowFeature, FeatureProtected, ROUTE_FEATURE_GROUP } from 'Components/Features'
import { PlanProvider } from 'Components/Plan'
import Toast from 'Components/Toast'
import { ACCOUNT_TYPE } from 'Constants/account'
import { CLIENT_FEATURE_FLAGS } from 'Constants/feature-flags'
import Main from 'Layout/Main'
import api from 'Services/api'
import { WorkspaceProvider } from 'Src/utils/hooks/useWorkspace'

import Actions from './Actions'
import Answers from './Answers'
import Apps from './Apps'
import Channels from './Channels'
import Dashboard from './Dashboard'
// import Sass from './Saas'
import ManageAdmins from './ManageAdmins'
import Reports from './Reports'
import Settings from './Settings'
import UserGroups from './UserGroups'
import Workspaces from './Workspaces'

const getFeatureFlags = (account) => {
  if (!account) {
    return []
  }

  const {
    enabled_features,
    is_ticketing_integration_connected,
    is_dm_workflow_enabled,
    account_type,
    is_ai_actions_enabled,
    is_workspaces_enabled
  } = account

  const flags = [...enabled_features]

  if (is_ticketing_integration_connected) {
    flags.push(CLIENT_FEATURE_FLAGS.ENABLE_TICKETING)
  }

  if (is_dm_workflow_enabled) {
    flags.push(CLIENT_FEATURE_FLAGS.DM_WORKFLOW_MODE)
  }

  if (is_ai_actions_enabled) {
    flags.push(CLIENT_FEATURE_FLAGS.ACTIONS)
  }

  if (is_workspaces_enabled) {
    flags.push(CLIENT_FEATURE_FLAGS.WORKSPACES)
  }

  if (account_type === ACCOUNT_TYPE.SLACK) {
    flags.push(CLIENT_FEATURE_FLAGS.SLACK_ACCOUNT)
  }

  if (account_type === ACCOUNT_TYPE.MS_TEAMS) {
    flags.push(CLIENT_FEATURE_FLAGS.MS_TEAMS_ACCOUNT)
  }

  if (account_type === ACCOUNT_TYPE.MS_TEAMS || (is_dm_workflow_enabled && is_ticketing_integration_connected)) {
    flags.push(CLIENT_FEATURE_FLAGS.SETTINGS)
  }

  if (canAllowFeature(flags, [CLIENT_FEATURE_FLAGS.DM_WORKFLOW_MODE, CLIENT_FEATURE_FLAGS.SLACK_ACCOUNT])) {
    flags.push(CLIENT_FEATURE_FLAGS.CUSTOMISE_CHANNELS)
  }

  return flags
}

const ProtectedPages = () => {
  const profile = api.useGetProfileQuery()
  const account = api.useGetAccountQuery()
  const { data = {}, isLoading: isWorkspaceApiLoading } = api.useGetWorkspacesQuery()
  const { results: workspaces = [] } = data

  const isLoading = profile.isLoading || account.isLoading || isWorkspaceApiLoading

  const planDetails = {
    planName: account.data?.plan_name,
    integrationsLimit: account.data?.integrations_limit
  }

  const featureFlags = getFeatureFlags(account.data)

  return (
    <Fragment>
      <Toast />
      <WorkspaceProvider workspaces={workspaces}>
        <PlanProvider planDetails={planDetails}>
          <FeaturesProvider features={featureFlags}>
            <AppLoader isLoading={isLoading}>
              <Routes>
                <Route path='/' element={<Main />}>
                  <Route path='dashboard/*' element={<Dashboard />} />
                  <Route path='apps/*' element={<Apps />} />
                  <Route
                    path='channels'
                    element={
                      <FeatureProtected group={ROUTE_FEATURE_GROUP.channels}>
                        <Channels />
                      </FeatureProtected>
                    }
                  />
                  <Route path='answers/*' element={<Answers />} />
                  <Route
                    path='actions/*'
                    element={
                      <FeatureProtected group={ROUTE_FEATURE_GROUP.actions}>
                        <Actions />
                      </FeatureProtected>
                    }
                  />
                  {/* <Route path='saas' element={<Sass />} /> */}
                  <Route path='admins' element={<ManageAdmins />} />
                  <Route
                    path='user-groups/*'
                    element={
                      <FeatureProtected group={ROUTE_FEATURE_GROUP.user_groups}>
                        <UserGroups />
                      </FeatureProtected>
                    }
                  />
                  <Route
                    path='reports'
                    element={
                      <FeatureProtected group={ROUTE_FEATURE_GROUP.reports}>
                        <Reports />
                      </FeatureProtected>
                    }
                  />
                  <Route
                    path='workspace'
                    element={
                      <FeatureProtected group={ROUTE_FEATURE_GROUP.workspaces}>
                        <Workspaces />
                      </FeatureProtected>
                    }
                  />
                  <Route
                    path='settings'
                    element={
                      <FeatureProtected group={ROUTE_FEATURE_GROUP.settings}>
                        <Settings />
                      </FeatureProtected>
                    }
                  />
                  <Route path='*' element={<Navigate to='dashboard' replace />} />
                </Route>
              </Routes>
            </AppLoader>
          </FeaturesProvider>
        </PlanProvider>
      </WorkspaceProvider>
    </Fragment>
  )
}

const Protected = () => {
  const { isLoading } = api.useGetCsrfTokenQuery()

  return (
    <ErrorBoundary>
      <AppLoader isLoading={isLoading}>
        <ProtectedPages />
      </AppLoader>
    </ErrorBoundary>
  )
}

export default Protected
