import {
  Button,
  Heading,
  PaginationControls,
  Table,
  TableActions,
  TableBody,
  TableHead,
  TableRow,
  Td,
  Text,
  Th
} from '@happyfoxinc/react-ui'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { usePagination, useSortBy, useTable } from 'react-table'

import styles from './AnswersList.module.css'

import Tooltip, { TooltipContent, TooltipTrigger } from 'Components/Tooltip'
import PageLoader from 'Components/PageLoader'
import SearchInput from 'Components/SearchInput'
import UserGroupBadge from 'Components/UserGroupBadge'
import WorkspaceDropdown from 'Components/WorkspaceDropdown'
import api from 'Services/api'
import * as date from 'Utils/date'
import debounce from 'Utils/debounce'
import { useWorkspace } from 'Utils/hooks/useWorkspace'
import { getInitialApiQueryParamsForTable, getInitialParamsForTable } from 'Utils/query-params'

import AnswerOptions from './AnswerOptions'
import NoAnswers from './NoAnswers'

const defaultParams = {
  defaultSearch: '',
  supportedSortColumns: ['created_at', 'updated_at'],
  defaultSortedColumn: 'created_at',
  defaultSortOrder: 'desc'
}

const getInitialParams = () => {
  return getInitialParamsForTable(defaultParams)
}

const getInitialApiQueryParams = (currentWorkspaceId) => {
  const initialParams = getInitialParams()
  return {
    ...getInitialApiQueryParamsForTable(initialParams),
    workspace_id: currentWorkspaceId
  }
}

const AnswersTable = () => {
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()
  const { currentWorkspaceId } = useWorkspace()

  const initialParams = useMemo(getInitialParams, [])

  const [queryParams, setQueryParams] = useState(() => getInitialApiQueryParams(currentWorkspaceId))
  const [searchText, setSearchText] = useState(initialParams.search)
  const [pageSize] = useState(initialParams.size)

  const { data: account } = api.useGetAccountQuery()
  const { data = {}, isLoading } = api.useListAnswersQuery(queryParams, {
    skip: !currentWorkspaceId
  })
  const { results: answers = [], meta: paginationDetails = {} } = data

  const showWorkspaceDropdown = account?.is_workspaces_enabled

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
        disableSortBy: true
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        disableSortBy: false,
        Cell: ({ cell: { value } }) => {
          return date.ago(value)
        }
      },
      {
        Header: 'Updated By',
        disableSortBy: false,
        accessor: 'updated_by',
        Cell: ({ cell: { value } }) => {
          return value.name ?
            <Tooltip delayDuration={200}>
              <TooltipTrigger className={styles.TooltipTrigger}>
                <span>{value.name}</span>
              </TooltipTrigger>
              <TooltipContent side='bottom' className={styles.TooltipContent}>
              <span>{value.email}</span>
              </TooltipContent>
            </Tooltip> : '-'
        }
      },
      {
        Header: 'Last Update',
        disableSortBy: false,
        accessor: 'updated_at',
        Cell: ({ cell: { value } }) => {
          return value ? date.ago(value) : '-'
        }
      },
      {
        Header: 'Visibility',
        disableSortBy: true,
        accessor: (row) => row,
        Cell: ({ cell: { value } }) => <UserGroupBadge {...value} />
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex, sortBy }
  } = useTable(
    {
      columns,
      data: answers,
      initialState: {
        pageIndex: initialParams.page - 1,
        pageSize,
        sortBy: initialParams.sortBy
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / pageSize),
      // sort
      disableSortRemove: true,
      manualSortBy: true,
      autoResetSortBy: false
    },
    useSortBy,
    usePagination
  )

  useEffect(() => {
    const params = {
      sort_by: '',
      order_by: '',
      search: searchText
    }

    sortBy.forEach((sort) => {
      params.sort_by = sort.id
      params.order_by = sort.desc ? 'desc' : 'asc'
    })

    const apiParams = {
      offset: pageIndex * pageSize,
      limit: pageSize,
      workspace_id: currentWorkspaceId,
      ...params
    }

    const urlPrams = {
      page: pageIndex + 1,
      size: pageSize,
      ...params
    }

    debouncedSetQueryParams(apiParams)
    setSearchParams(urlPrams, { replace: true })
  }, [currentWorkspaceId, pageIndex, pageSize, searchText, sortBy, debouncedSetQueryParams, setSearchParams])

  const handleSearchInputChange = (e) => {
    gotoPage(0)
    setSearchText(e.target.value)
  }

  if (isLoading) {
    return <PageLoader />
  }

  if (data.results?.length === 0 && !showWorkspaceDropdown) {
    return <NoAnswers />
  }

  return (
    <div className={styles.Container}>
      <TableActions>
        <div className={styles.SearchInputContainer}>
          <SearchInput placeholder='Search answers' value={searchText} onChange={handleSearchInputChange} />
          {showWorkspaceDropdown && <WorkspaceDropdown />}
        </div>
        <PaginationControls
          currentPage={pageIndex + 1}
          pageSize={pageSize}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
        <Button variant='primary' onClick={() => navigate('create')}>
          New Answer
        </Button>
      </TableActions>
      <Table className={styles.AnswerTable} {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

            return (
              <TableRow key={key} {...headerGroupProps}>
                <Fragment>
                  {headerGroup.headers.map((column) => {
                    const { key, ...headerProps } = column.getHeaderProps(column.getSortByToggleProps())

                    return (
                      <Th key={key} isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} {...headerProps}>
                        {column.render('Header')}
                      </Th>
                    )
                  })}
                  <Th isOption />
                </Fragment>
              </TableRow>
            )
          })}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...rowProps } = row.getRowProps()
            const answer = row.original

            return (
              <TableRow key={key} {...rowProps} clickable>
                {row.cells.map((cell) => {
                  const { key, ...cellProps } = cell.getCellProps()
                  return (
                    <Td key={key} {...cellProps} onClick={() => navigate(answer.id)}>
                      {cell.render('Cell')}
                    </Td>
                  )
                })}
                <Td>
                  <AnswerOptions id={answer.id} />
                </Td>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {rows.length === 0 && <div className={styles.NoFilterResults}>No results found for given query</div>}
    </div>
  )
}

const AnswersList = () => {
  // const { currentWorkspaceId } = useWorkspace()
  // const queryParams = useMemo(() => getInitialApiQueryParams(currentWorkspaceId), [currentWorkspaceId])

  // const { isLoading } = api.useListAnswersQuery(queryParams)

  // if (isLoading) {
  //   return <PageLoader />
  // }

  // // disabled for now
  // if (data.results?.length === 0) {
  //   return <NoAnswers />
  // }

  return (
    <Fragment>
      <Heading>Answers</Heading>
      <Text variant='muted'>
        Answers let you create and manage all commonly asked questions within Assist AI. Answers complement other
        existing knowledge sources.
      </Text>
      <AnswersTable />
    </Fragment>
  )
}

export default AnswersList
