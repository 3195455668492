const test = (text) => {
  const regex = /<\S+>/
  if (text && regex.test(text)) {
    return false
  }
  return true
}
const message = "Enclosing text using '<' and '>' is not supported. Please use '(' and ')' instead."

const noHtmlTagTypeEnclosedText = {
  name: 'no-html-tag-type-enclosed-text',
  message,
  test
}

export default noHtmlTagTypeEnclosedText
