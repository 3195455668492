import { TableOption, TableOptions } from '@happyfoxinc/react-ui'
import { Fragment, useCallback, useState } from 'react'
import toast from 'react-hot-toast'

import ConfirmationModal from 'Components/ConfirmationModal'
import { CONFIRM_MESSAGES } from 'Constants/messages'
import api from 'Services/api'
import parseErrorMessage from 'Utils/error-message-parser'

const AnswerOptions = (props) => {
  const { id } = props

  const [deleteAnswer] = api.useDeleteAnswerMutation()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const handleDeleteClick = useCallback(() => {
    const promise = deleteAnswer(id).unwrap()
    promise.then(() => setIsDeleteModalOpen(false))

    toast.promise(promise, {
      loading: 'Deleting Answer...',
      success: 'Answer deleted successfully.',
      error: parseErrorMessage('Unable to delete answer. Try again...')
    })
  }, [deleteAnswer, id])

  return (
    <Fragment>
      <TableOptions>
        <TableOption onClick={() => setIsDeleteModalOpen(true)}>Delete</TableOption>
      </TableOptions>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        variant='alert'
        message={CONFIRM_MESSAGES.DELETE_ANSWER}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteClick}
      />
    </Fragment>
  )
}

export default AnswerOptions
