// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cJ0cxVhe1sN8MRDWA8_E{display:flex;justify-content:space-between}.TQmjOe85bbLj1qWaUCZg{margin-top:1rem}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/AppDetails/apps/HappyFoxServiceDesk/Manage/Manage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CAGF,sBACE,eAAA","sourcesContent":[".FlexLabel {\n  display: flex;\n  justify-content: space-between;\n}\n\n.SaveButton {\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlexLabel": "cJ0cxVhe1sN8MRDWA8_E",
	"SaveButton": "TQmjOe85bbLj1qWaUCZg"
};
export default ___CSS_LOADER_EXPORT___;
