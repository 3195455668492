import { Text } from '@happyfoxinc/react-ui'

import styles from './AdminsTable.module.css'

export const Status = (props) => {
  const user = props.cell.value

  let statusText = 'Deactivated'
  let variant = 'danger'

  if (user.status === 'invited') {
    statusText = 'Invited'
    variant = 'muted'
  } else if (user.status === 'expired') {
    statusText = 'Expired'
    variant = 'danger'
  } else if (user.status === 'active') {
    statusText = 'Active'
    variant = 'success'
  }

  return (
    <Text variant={variant} className={styles[statusText]}>
      {statusText}
    </Text>
  )
}
