const DEFAULT_ERR_MSG = 'Something went wrong. Please try again later.'

const getError = (inputError = {}) => {
  if (inputError.data?.type) {
    return inputError.data
  }

  if (inputError.data?.code) {
    return {
      type: inputError.data.code,
      message: inputError.data.error
    }
  }

  return inputError
}

// mostly used to get error message string from object
const parseErrorMessage = (defaultErrorMessage = DEFAULT_ERR_MSG) => {
  const getErrorMessage = (error) => {
    if (typeof error !== 'object') {
      return defaultErrorMessage
    }

    switch (error.type) {
      case 'VALIDATION_ERR': {
        const [validationError] = error.errors ?? []

        if (!validationError) {
          return defaultErrorMessage
        }

        switch (typeof validationError) {
          case 'string':
            return validationError
          case 'object':
            return getErrorMessage(validationError)
          default:
            return defaultErrorMessage
        }
      }

      case 'INTEGRATION_LIMIT_REACHED':
      case 'ANOTHER_TICKETING_INTEGRATION_CONNECTED':
      case 'BAD_REQUEST':
        return error.message

      // add here if required to return custom messages for given type
      case 'EXAMPLE_ERROR':
        return 'This is a custom message returned for type: EXAMPLE_ERROR'

      // if just needed to return server message. add a case here

      default:
        return defaultErrorMessage
    }
  }

  return (inputError) => {
    const error = getError(inputError)
    return getErrorMessage(error)
  }
}

export default parseErrorMessage
