import { Heading, Text } from '@happyfoxinc/react-ui'
import { Fragment, useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import ConfirmationModal from 'Components/ConfirmationModal'
import { CONFIRM_MESSAGES } from 'Constants/messages'
import api from 'Services/api'
import { SecondaryButton } from 'Src/components/Buttons'

import AppFooter from '../../../components/AppFooter'
import { ConnectedAppBase } from '../../../components/Common/CommonUpgrade'
import { useAppDetailContext } from '../../AppDetailContext'
import SitemapConfig from './ConfigScreens/SitemapConfig'
import SpecificUrlsConfig from './ConfigScreens/SpecificUrlsConfig'

const ManageWebsite = ({ app }) => {
  const { website_id, website_url, scraping_strategy } = app
  const [isUninstallModalOpen, setIsUninstallModalOpen] = useState(false)

  const [remove, removeResult] = api.useRemoveWebsiteMutation()

  const navigate = useNavigate()
  const { title } = useAppDetailContext()

  const handleReconnectClick = useCallback(() => {
    navigate('auth')
  }, [navigate])

  const handleRemoveWebsite = useCallback(() => {
    const promise = remove(website_id).unwrap()
    promise.then(() => {
      setIsUninstallModalOpen(false)
      navigate('../')
    })

    toast.promise(promise, {
      loading: 'Uninstalling app...',
      success: 'App uninstalled successfully',
      error: 'Failed to uninstall app'
    })
  }, [remove, website_id, navigate])

  const configScreen = useMemo(() => {
    if (scraping_strategy === 'only_sitemap' || scraping_strategy === 'full_website') {
      return <SitemapConfig app={app} />
    } else if (scraping_strategy === 'specific_urls') {
      return <SpecificUrlsConfig app={app} />
    }
  }, [scraping_strategy, app])

  const isRemoveApiLoading = removeResult.isLoading

  return (
    <Fragment>
      <ConnectedAppBase>
        <Heading level={2}>
          Successfully connected to {title} workspace. <br /> The account is tied to{' '}
          <Text isInline variant='muted'>
            {website_url}
          </Text>
        </Heading>
        <SecondaryButton onClick={handleReconnectClick} disabled={isRemoveApiLoading}>
          Reconnect
        </SecondaryButton>
      </ConnectedAppBase>
      {configScreen}
      <AppFooter onUninstall={() => setIsUninstallModalOpen(true)} isUninstalling={isRemoveApiLoading} />
      <ConfirmationModal
        isOpen={isUninstallModalOpen}
        variant='alert'
        message={CONFIRM_MESSAGES.UNINSTALL_APP}
        onCancel={() => setIsUninstallModalOpen(false)}
        onConfirm={handleRemoveWebsite}
      />
    </Fragment>
  )
}

export default ManageWebsite
