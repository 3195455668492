import {
  AiIcon,
  CheckIcon,
  CoverageIcon,
  HelpIcon,
  ListIcon,
  MessageCheckIcon,
  MessageIcon,
  NotHelpfulIcon,
  ThumbsUpIcon,
  TwoBarIcon,
  UserIcon
} from './assets'
import { METRICS } from './metric-list'

const metricsData = (reports, accountType, downloadUrlForArticlesRequested, showAutoResolutionMetrics, styles) => {
  const data = [
    {
      type: 'MetricTile',
      title: METRICS.REQUEST_METRICS.label,
      info: METRICS.REQUEST_METRICS.info,
      summary: METRICS.REQUEST_METRICS.summary,
      icon: <MessageIcon />,
      primaryCount: reports.requests_count_from_primary_sources,
      othersCount: reports.requests_count_from_other_sources,
      totalCount: reports.requests_count,
      othersDisabled: reports.requests_from_other_sources_disabled,
      order: 1
    },
    {
      type: 'MetricTile',
      title: METRICS.USERS.label,
      summary: METRICS.USERS.summary,
      info: METRICS.USERS.info({ accountType }),
      icon: <UserIcon />,
      primaryCount: reports.active_users_count,
      order: 2
    },
    {
      type: 'MetricTile',
      title: METRICS.TOTAL_SUGGESTIONS.label,
      info: METRICS.TOTAL_SUGGESTIONS.info,
      summary: METRICS.TOTAL_SUGGESTIONS.summary,
      icon: <AiIcon />,
      primaryCount: reports.suggestions_count_from_primary_sources,
      othersCount: reports.suggestions_count_from_other_sources,
      totalCount: reports.suggestions_count,
      othersDisabled: reports.suggestions_from_other_sources_disabled,
      othersNotApplicable: reports.suggestions_from_other_sources_not_applicable,
      order: 3
    },
    {
      type: 'MetricTileWider',
      title: METRICS.ARTICLES_REQUESTED.label,
      info: METRICS.ARTICLES_REQUESTED.info,
      icon: <HelpIcon />,
      list: reports.requested_kb_messages,
      downloadUrl: downloadUrlForArticlesRequested,
      order: 4
    },
    {
      type: 'MetricTileTaller',
      title: METRICS.RESOLUTION_METRICS.label,
      info: METRICS.RESOLUTION_METRICS.info,
      icon: <MessageCheckIcon />,
      primaryCount: reports.resolved_requests_count_with_feedback_from_primary_sources,
      othersCount: reports.resolved_requests_count_with_feedback_from_other_sources,
      totalCount: reports.resolved_requests_count_with_feedback,
      othersDisabled: reports.resolved_requests_with_feedback_from_other_sources_disabled,
      othersNotApplicable: reports.resolved_requests_with_feedback_from_other_sources_not_applicable,
      totalWithoutFeedback: reports.resolved_requests_count_without_feedback,
      classNames: {
        Tile: styles?.TileW100
      },
      order: 5
    },
    {
      type: 'MetricTile',
      title: METRICS.TICKET_DEFLECTION_RATE.label,
      info: METRICS.TICKET_DEFLECTION_RATE.info,
      summary: METRICS.TICKET_DEFLECTION_RATE.summary,
      icon: <CheckIcon />,
      primaryCount: reports.ticket_deflection_rate,
      isPercent: true,
      order: 6
    },
    // AUTO_TICKET_RES_RATE - order - 7
    // OVERALL_AUTO_RES_RATE - order - 8
    {
      type: 'MetricTile',
      title: METRICS.TICKETS_CREATED.label,
      info: METRICS.TICKETS_CREATED.info,
      summary: METRICS.TICKETS_CREATED.summary,
      icon: <ListIcon />,
      primaryCount: reports.tickets_created_from_primary_sources,
      othersCount: reports.tickets_created_from_other_sources,
      totalCount: reports.tickets_created,
      othersDisabled: reports.tickets_created_from_other_sources_disabled,
      order: 9
    },
    {
      type: 'MetricTile',
      title: METRICS.COVERAGE_RATE.label,
      info: METRICS.COVERAGE_RATE.info,
      summary: METRICS.COVERAGE_RATE.summary,
      icon: <AiIcon />,
      primaryCount: reports.coverage_rate,
      isPercent: true,
      order: 11
    },
    {
      type: 'MetricTile',
      title: METRICS.COVERAGE_OPPS.label,
      info: METRICS.COVERAGE_OPPS.info,
      summary: METRICS.COVERAGE_OPPS.summary,
      icon: <CoverageIcon />,
      list: reports.coverage_opportunities,
      classNames: {
        ContentListContainer: styles?.TileH100
      },
      order: 12
    },
    {
      type: 'MetricTile',
      title: METRICS.MOST_HELPFUL_ANS.label,
      info: METRICS.MOST_HELPFUL_ANS.info,
      summary: METRICS.MOST_HELPFUL_ANS.summary,
      icon: <ThumbsUpIcon />,
      list: reports.most_helpful_answers,
      withCount: true,
      order: 13
    },
    {
      type: 'MetricTile',
      title: METRICS.LEAST_HELPFUL_ANS.label,
      info: METRICS.LEAST_HELPFUL_ANS.info,
      summary: METRICS.LEAST_HELPFUL_ANS.summary,
      icon: <NotHelpfulIcon />,
      list: reports.least_helpful_answers,
      withCount: true,
      order: 14
    }
  ]

  if (showAutoResolutionMetrics) {
    data.push(
      {
        type: 'MetricTile',
        title: METRICS.AUTO_TICKET_RES_RATE.label,
        info: METRICS.AUTO_TICKET_RES_RATE.info,
        summary: METRICS.AUTO_TICKET_RES_RATE.summary,
        icon: <MessageCheckIcon />,
        primaryCount: reports.ticket_auto_resolution_rate,
        isPercent: true,
        styles: {
          width: '100%',
          height: '130px'
        },
        order: 7
      },
      {
        type: 'MetricTile',
        title: METRICS.OVERALL_AUTO_RES_RATE.label,
        info: METRICS.OVERALL_AUTO_RES_RATE.info,
        summary: METRICS.OVERALL_AUTO_RES_RATE.summary,
        icon: <MessageCheckIcon />,
        primaryCount: reports.overall_auto_resolution_rate,
        isPercent: true,
        styles: {
          width: '100%',
          height: '110px'
        },
        order: 8
      },
      {
        type: 'MetricTile',
        title: METRICS.TOP_CATEGORY.label,
        info: METRICS.TOP_CATEGORY.info,
        summary: METRICS.TOP_CATEGORY.summary,
        icon: <TwoBarIcon />,
        primaryCount: reports.top_category,
        order: 10
      }
    )
  }

  return data.sort((a, b) => a.order - b.order)
}

export default metricsData
