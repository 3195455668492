import { nanoid } from '@reduxjs/toolkit'
import cx from 'classnames'
import { Fragment } from 'react'
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom'

import styles from './Nav.module.scss'

import ActionsIcon from 'Icons/actions.svg'
import AnswersIcon from 'Icons/answers.svg'
import AppsIcon from 'Icons/apps.svg'
import ArrowDownIcon from 'Icons/arrow-down.svg'
import ArrowRightIcon from 'Icons/arrow-right.svg'
import SaasIcon from 'Icons/book.svg'
import ChannelsIcon from 'Icons/channels.svg'
import SettingsIcon from 'Icons/cog.svg'
import DashboardIcon from 'Icons/dashboard.svg'
import AdminsIcon from 'Icons/headphone.svg'
import ReportsIcon from 'Icons/reports.svg'
import UserGroupsIcon from 'Icons/users.svg'
import WorkspaceIcon from 'Icons/workspace.svg'

import { Feature } from 'Components/Features'
import { CLIENT_FEATURE_FLAGS, FEATURE_FLAGS } from 'Constants/feature-flags'

const DefaultIcon = () => null

const MenuIcons = {
  dashboard: DashboardIcon,
  apps: AppsIcon,
  answers: AnswersIcon,
  saas: SaasIcon,
  actions: ActionsIcon,
  admins: AdminsIcon,
  userGroups: UserGroupsIcon,
  reports: ReportsIcon,
  settings: SettingsIcon,
  channels: ChannelsIcon,
  workspace: WorkspaceIcon,
  fallback: DefaultIcon
}

const prepareMenu = (menus, parentPath = '', isSubmenu = false) => {
  return menus.map((menu) => {
    const menuWihKey = {
      ...menu,
      path: `${parentPath}${(isSubmenu ? '/' : '') + menu.path}`,
      key: nanoid()
    }

    if (menu.submenus) {
      menuWihKey.submenus = prepareMenu(menu.submenus, menu.path, true)
    }

    return menuWihKey
  })
}

const menus = prepareMenu([
  {
    label: 'Dashboard',
    path: 'dashboard',
    icon: 'dashboard'
  },
  {
    label: 'Apps',
    path: 'apps',
    icon: 'apps'
  },
  {
    label: 'Channels',
    path: 'channels',
    icon: 'channels',
    requiredFeatures: [CLIENT_FEATURE_FLAGS.CUSTOMISE_CHANNELS]
  },
  // {
  //   type: 'divider'
  // },
  {
    label: 'Answers',
    path: 'answers',
    icon: 'answers'
  },
  {
    label: 'Actions',
    path: 'actions',
    icon: 'actions',
    requiredFeatures: [CLIENT_FEATURE_FLAGS.ACTIONS]
  },
  // {
  //   label: 'SaaS Library',
  //   path: 'saas',
  //   icon: 'saas'
  // },
  {
    label: 'User Groups',
    path: 'user-groups',
    icon: 'userGroups',
    requiredFeatures: [FEATURE_FLAGS.USER_GROUPS]
  },
  {
    label: 'Manage Admins',
    path: 'admins',
    icon: 'admins'
  },
  {
    label: 'Reports',
    path: 'reports',
    icon: 'reports',
    requiredFeatures: [FEATURE_FLAGS.REPORTS]
  },
  {
    label: 'Manage Workspace',
    path: 'workspace',
    icon: 'workspace',
    requiredFeatures: [CLIENT_FEATURE_FLAGS.WORKSPACES]
  },
  {
    label: 'Settings',
    path: 'settings',
    icon: 'settings',
    requiredFeatures: [CLIENT_FEATURE_FLAGS.SETTINGS]
  }
])

const MenuItemContent = (props) => {
  const { menu, isActive, isSubMenu = false } = props
  const Icon = MenuIcons[menu.icon] || MenuIcons.fallback

  return (
    <Fragment>
      <span className={styles.Icon}>
        <Icon />
      </span>
      <span className={styles.MenuLabel}>
        {menu.label}
        {menu.submenus && isActive && (
          <span className={styles.ArrowContainer}>
            <ArrowDownIcon />
          </span>
        )}
        {!menu.submenus && isActive && !isSubMenu && (
          <span className={styles.ArrowContainer}>
            <ArrowRightIcon />
          </span>
        )}
      </span>
    </Fragment>
  )
}

const MenuItem = (props) => {
  const { menu, isSubMenu = false } = props
  const { pathname } = useResolvedPath(menu.path)

  const match = useMatch({
    path: pathname,
    end: false
  })

  return (
    <Feature features={menu.requiredFeatures}>
      <li className={styles.MenuItemContainer}>
        <NavLink to={menu.path} className={({ isActive }) => cx(styles.MenuItem, { [styles.Active]: isActive })}>
          {({ isActive }) => {
            return <MenuItemContent isActive={isActive} menu={menu} isSubMenu={isSubMenu} />
          }}
        </NavLink>

        {menu.submenus && (
          <ul className={cx(styles.SubMenu, { [styles.Hidden]: !match })}>
            {menu.submenus.map((submenu) => {
              return <MenuItem key={submenu.key} menu={submenu} isSubMenu />
            })}
          </ul>
        )}
      </li>
    </Feature>
  )
}

const Nav = () => {
  return (
    <ul role='navigation' className={styles.MenuContainer}>
      {menus.map((menu) => {
        if (menu.type === 'divider') {
          return <div key={menu.key} className={styles.Divider} />
        }

        return <MenuItem key={menu.key} menu={menu} />
      })}
    </ul>
  )
}

export default Nav
