import {
  Button,
  Heading,
  PaginationControls,
  Table,
  TableActions,
  TableBody,
  TableHead,
  TableRow,
  Td,
  Text,
  Th
} from '@happyfoxinc/react-ui'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { usePagination, useSortBy, useTable } from 'react-table'

import styles from './ListUserGroups.module.scss'

import PageLoader from 'Components/PageLoader'
import api from 'Services/api'
import debounce from 'Utils/debounce'
import { getInitialApiQueryParamsForTable, getInitialParamsForTable as getInitialParams } from 'Utils/query-params'

import NoUserGroups from '../NoUserGroups'
import * as Cells from './UserGroupCells'
import UserGroupOptions from './UserGroupOptions'

const getInitialApiQueryParams = () => {
  const initialParams = getInitialParams()
  return getInitialApiQueryParamsForTable(initialParams)
}

const UserGroupsTable = () => {
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()
  const { data: account } = api.useGetAccountQuery()
  const accountType = account.account_type

  const initialParams = useMemo(getInitialParams, [])

  const [queryParams, setQueryParams] = useState(getInitialApiQueryParams)
  const [pageSize] = useState(initialParams.size)
  const query = { ...queryParams, accountType }
  const { data = {}, isLoading } = api.useGetUserGroupsQuery(query)
  const { results: userGroups = [], meta: paginationDetails = {} } = data

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: (row) => row,
        Cell: Cells.Name,
        disableSortBy: true
      },
      {
        Header: 'Total Users',
        accessor: 'users_count',
        disableSortBy: true
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: userGroups,
      initialState: {
        pageIndex: initialParams.page - 1,
        pageSize
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / pageSize),
      // sort
      disableSortRemove: true,
      manualSortBy: true,
      autoResetSortBy: false
    },
    useSortBy,
    usePagination
  )

  useEffect(() => {
    const params = {}

    const apiParams = {
      offset: pageIndex * pageSize,
      limit: pageSize,
      ...params
    }

    const urlPrams = {
      page: pageIndex + 1,
      size: pageSize,
      ...params
    }

    debouncedSetQueryParams(apiParams)
    setSearchParams(urlPrams, { replace: true })
  }, [pageIndex, pageSize, debouncedSetQueryParams, setSearchParams])

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <div className={styles.Container}>
      <TableActions>
        <PaginationControls
          currentPage={pageIndex + 1}
          pageSize={pageSize}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
        <Button variant='primary' onClick={() => navigate('create')}>
          Create Group
        </Button>
      </TableActions>
      <Table className={styles.AnswerTable} {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

            return (
              <TableRow key={key} {...headerGroupProps}>
                <Fragment>
                  {headerGroup.headers.map((column) => {
                    const { key, ...headerProps } = column.getHeaderProps(column.getSortByToggleProps())

                    return (
                      <Th key={key} isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} {...headerProps}>
                        {column.render('Header')}
                      </Th>
                    )
                  })}
                  <Th isOption />
                </Fragment>
              </TableRow>
            )
          })}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...rowProps } = row.getRowProps()
            const userGroup = row.original

            return (
              <TableRow key={key} {...rowProps} clickable>
                {row.cells.map((cell) => {
                  const { key, ...cellProps } = cell.getCellProps()

                  return (
                    <Td key={key} {...cellProps} onClick={() => navigate(userGroup.id.toString())}>
                      {cell.render('Cell')}
                    </Td>
                  )
                })}
                <Td>
                  <UserGroupOptions {...userGroup} />
                </Td>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {rows.length === 0 && <div className={styles.NoFilterResults}>No results found for given query</div>}
    </div>
  )
}

const ListUserGroups = () => {
  const queryParams = useMemo(getInitialApiQueryParams, [])

  const { data: account } = api.useGetAccountQuery()
  const accountType = account.account_type

  const { data = {}, isLoading } = api.useGetUserGroupsQuery({ ...queryParams, accountType })

  if (isLoading) {
    return <PageLoader />
  }

  if (data.results?.length === 0) {
    return <NoUserGroups />
  }

  return (
    <Fragment>
      <Heading>User Groups</Heading>
      <Text variant='muted'>Users can be grouped together to restrict answers suggested to the users.</Text>
      <UserGroupsTable />
    </Fragment>
  )
}

export default ListUserGroups
