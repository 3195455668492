// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".P3PebP47U2h5MjfkTeYA{margin-top:var(--hrx-space-1)}.XNqBXqQ4iXNg8AOhLoae{color:var(--primary-solid-dark);width:20px}.KjzmdtKeYJTj9mM9GsS4{flex:1}", "",{"version":3,"sources":["webpack://./../src/components/FormWarningText/FormWarningText.module.scss"],"names":[],"mappings":"AAAA,sBACE,6BAAA,CAGF,sBACE,+BAAA,CACA,UAAA,CAGF,sBACE,MAAA","sourcesContent":[".Container {\n  margin-top: var(--hrx-space-1);\n}\n\n.Icon {\n  color: var(--primary-solid-dark);\n  width: 20px;\n}\n\n.Text {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "P3PebP47U2h5MjfkTeYA",
	"Icon": "XNqBXqQ4iXNg8AOhLoae",
	"Text": "KjzmdtKeYJTj9mM9GsS4"
};
export default ___CSS_LOADER_EXPORT___;
