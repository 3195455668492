// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xpFAXdvblVL9gA0l2oZE{border-radius:var(--border-radius);background-color:#fff;padding:.6rem 1rem;box-shadow:0px 1px 4px 1px rgba(0,0,0,.1);-webkit-user-select:none;user-select:none;max-width:350px;word-break:break-word}.Ks8U0yoWoIb2m5yaWknS{fill:#fff}", "",{"version":3,"sources":["webpack://./../src/components/Tooltip/Tooltip.module.css"],"names":[],"mappings":"AAAA,sBACE,kCAAA,CACA,qBAAA,CACA,kBAAA,CACA,yCAAA,CACA,wBAAA,CAAA,gBAAA,CACA,eAAA,CACA,qBAAA,CAGF,sBACE,SAAA","sourcesContent":[".TooltipContent {\n  border-radius: var(--border-radius);\n  background-color: white;\n  padding: 0.6rem 1rem;\n  box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 10%);\n  user-select: none;\n  max-width: 350px;\n  word-break: break-word;\n}\n\n.Arrow {\n  fill: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TooltipContent": "xpFAXdvblVL9gA0l2oZE",
	"Arrow": "Ks8U0yoWoIb2m5yaWknS"
};
export default ___CSS_LOADER_EXPORT___;
