// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g0fpbW8mpj0vXb1vt4MA{background-color:#fff5f6;padding:1rem;margin-bottom:1rem;border-radius:var(--hrx-radii-rounded);display:flex;gap:1rem}.Or0iFYPK_QEzQwwRBZ6y{color:#000;cursor:pointer;text-decoration:underline}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/components/SyncDisabledAlert/SyncDisabledAlert.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,YAAA,CACA,kBAAA,CACA,sCAAA,CACA,YAAA,CACA,QAAA,CAGF,sBACE,UAAA,CACA,cAAA,CACA,yBAAA","sourcesContent":[".SyncAlert {\n  background-color: #fff5f6;\n  padding: 1rem;\n  margin-bottom: 1rem;\n  border-radius: var(--hrx-radii-rounded);\n  display: flex;\n  gap: 1rem;\n}\n\n.EnableSync {\n  color: black;\n  cursor: pointer;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SyncAlert": "g0fpbW8mpj0vXb1vt4MA",
	"EnableSync": "Or0iFYPK_QEzQwwRBZ6y"
};
export default ___CSS_LOADER_EXPORT___;
