import dayjs from './dayjs-helper'

export const toUtc = (date) => {
  return dayjs(date).utc(date)
}

export const ago = (date, noSuffix = false, utc = false) => {
  const d = utc ? toUtc(date) : dayjs(date)
  return d.fromNow(noSuffix)
}

export const isValid = (date) => {
  return dayjs(date).isValid()
}

export const format = (date, template) => {
  return dayjs(date).format(template)
}

export const localDateFromUTC = (date) => {
  return dayjs.utc(date).local()
}

export const toISOString = (date) => {
  return dayjs(date).toISOString()
}

export const customDates = {
  now: () => {
    return dayjs().toDate()
  },
  startOfToday: () => {
    return dayjs().startOf('day').toDate()
  },
  endOfToday: () => {
    return dayjs().endOf('day').toDate()
  },
  pastDay: (noOfDaysFromToday = 1, eod = false) => {
    const date = dayjs().subtract(noOfDaysFromToday, 'days')
    return eod ? date.endOf('day').toDate() : date.startOf('day').toDate()
  },
  pastMonthStarting: (noOfMonthsFromCurrentMonth = 1) => {
    return dayjs().startOf('month').subtract(noOfMonthsFromCurrentMonth, 'months').toDate()
  },
  pastYearStarting: (noOfYearsFromCurrentYear = 1) => {
    return dayjs().startOf('year').subtract(noOfYearsFromCurrentYear, 'years').toDate()
  },
  pastYearEnding: (noOfYearsFromCurrentYear = 1) => {
    return dayjs().endOf('year').startOf('day').subtract(noOfYearsFromCurrentYear, 'years').toDate()
  },
  currentMonthStarting: () => {
    return dayjs().startOf('month').toDate()
  }
}

export const isSameDay = (dateA, dateB) => {
  return dayjs(dateA).isSame(dayjs(dateB), 'day')
}
