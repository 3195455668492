import { Heading, Text } from '@happyfoxinc/react-ui'
import { Fragment, useState } from 'react'

import styles from './QuickActionsConfig.module.scss'

import SparkIcon from 'Icons/spark.svg'

import { SecondaryButton } from 'Components/Buttons'
import { useAppDetailContext } from 'Src/pages/protected/Apps/AppDetails/AppDetailContext'

import QuickActionsConfigForm from './QuickActionsConfigForm'
import QuickActionsConfigModal from './QuickActionsConfigModal'

const QuickActionsConfig = ({ data }) => {
  const { title } = useAppDetailContext()

  const [showAllActionsModal, setShowAllActionsModal] = useState(false)

  return (
    <Fragment>
      <div className={styles.Container}>
        <Heading level={2}>Quick Actions</Heading>
        <Text variant='muted' className={styles.HelpText}>
          By enabling this toggle, you can grant access to {title} quick actions to specific agents.
        </Text>
        <SecondaryButton onClick={() => setShowAllActionsModal(true)} size='xs' className={styles.ActionButton}>
          View all actions <SparkIcon width='1em' height='1em' />
        </SecondaryButton>
        <QuickActionsConfigForm data={data} />
        {showAllActionsModal && <QuickActionsConfigModal onHide={() => setShowAllActionsModal(false)} />}
      </div>
    </Fragment>
  )
}

export default QuickActionsConfig
