import { Button, Heading, Text } from '@happyfoxinc/react-ui'
import { useNavigate } from 'react-router-dom'

import styles from './NoContent.module.css'

const defaultIcon = () => null

const NoContent = (props) => {
  const { title, description, ctaButtonText, ctaTarget, icon, onClick } = props
  const navigate = useNavigate()

  const Icon = icon ?? defaultIcon

  const handleCtaButtonClick = () => {
    if (onClick) {
      return onClick()
    }

    navigate(ctaTarget)
  }

  return (
    <div className={styles.Container}>
      <div className={styles.GridContainer}>
        <div className={styles.IconWrapper}>
          <div className={styles.IconContainer}>
            <div className={styles.IconCircle} />
            <Icon width='100' height='100' />
          </div>
        </div>
        <div className={styles.ContentWrapper}>
          <Heading level={1}>{title}</Heading>
          <Text variant='muted' size='sm' className={styles.Description}>
            {description}
          </Text>
          {ctaButtonText && (
            <div>
              <Button variant='primary' onClick={handleCtaButtonClick}>
                {ctaButtonText}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default NoContent
