import ReactModal from 'react-modal'

import styles from './Modal.module.css'

import CloseIcon from 'Icons/close.svg'

export const ModalHeader = ({ children, onClose }) => {
  return (
    <div className={styles.Header}>
      {children && <div className={styles.HeaderContent}>{children}</div>}
      {onClose && (
        <div className={styles.Close} onClick={onClose}>
          <CloseIcon width='18px' height='16px' />
        </div>
      )}
    </div>
  )
}

export const ModalBody = ({ children, style }) => {
  return (
    <div style={style} className={styles.ModalBody}>
      {children}
    </div>
  )
}

const Modal = (props) => {
  return <ReactModal parentSelector={() => document.getElementById('__modal-root')} {...props} />
}

export default Modal
