import { createContext, useContext, useRef, useState } from 'react'

import { CONFIRM_MESSAGES } from 'Constants/messages'
import { externalRedirect, getDomainFromUrl } from 'Utils/url'

import ConfirmationModal from './ConfirmationModal'

const RedirectConfirmationContext = createContext()
const useRedirectConfirmationContext = () => useContext(RedirectConfirmationContext)

const RedirectConfirmationModal = ({ children }) => {
  const [url, setUrl] = useState(null)
  const [customDomain, setCustomDomain] = useState(null)
  const doCancel = useRef(() => {})

  const value = (url, options = {}) => {
    setUrl(url)
    setCustomDomain(options.customDomain || null)
    if (options.handleCancel) {
      doCancel.current = options.handleCancel
    }
  }

  const domainToShow = customDomain || (url ? getDomainFromUrl(url) : '')
  const message = CONFIRM_MESSAGES.REDIRECT_TO_URL(domainToShow)

  return (
    <RedirectConfirmationContext.Provider value={value}>
      {children}
      <ConfirmationModal
        isOpen={!!url}
        onCancel={() => {
          doCancel.current()
          setUrl(null)
          setCustomDomain(null)
        }}
        onConfirm={() => externalRedirect(url)}
        message={message}
      />
    </RedirectConfirmationContext.Provider>
  )
}

export { RedirectConfirmationModal, useRedirectConfirmationContext }
