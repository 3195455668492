import { Outlet, Route, Routes } from 'react-router-dom'

import ListUserGroups from './ListUserGroups'
import UserGroup from './UserGroup'

const UserGroups = () => {
  return (
    <Routes>
      <Route path='/' element={<Outlet />}>
        <Route path='' index element={<ListUserGroups />} />
        <Route path='*' element={<UserGroup />} />
      </Route>
    </Routes>
  )
}

export default UserGroups
