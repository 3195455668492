import { Button, FormControl, FormErrorText, Heading, Input, Label, Text } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import styles from './SimpplrAuthForm.module.css'

import CopyIcon from 'Icons/copy.svg'
import CopiedIcon from 'Icons/tick-green.svg'

import { useRedirectConfirmationContext } from 'Components/ConfirmationModal/RedirectConfirmationContext'
import api from 'Services/api'
import parseErrorMessage from 'Utils/error-message-parser'
import useClipboard from 'Utils/hooks/useClipboard'

import { useAppDetailContext } from '../../AppDetailContext'
import simpplrAuthFormValidationSchema from './simpplr-auth-form-validation-schema'

const SimpplrAuthForm = (props) => {
  const { onCancel, app } = props
  const { title } = useAppDetailContext()
  const [install, installResult] = api.useInstallSimpplrMutation()
  const [copied, copy] = useClipboard()
  const externalRedirect = useRedirectConfirmationContext()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty }
  } = useForm({
    defaultValues: {
      consumerKey: '',
      clientSecret: ''
    },
    resolver: yupResolver(simpplrAuthFormValidationSchema)
  })

  const handleConnect = async (data) => {
    const payload = {
      consumer_key: data.consumerKey,
      client_secret: data.clientSecret
    }

    try {
      const promise = install(payload).unwrap()

      toast.promise(promise, {
        loading: `Connecting to ${title}`,
        success: `Redirecting to ${title} auth`,
        error: parseErrorMessage(`Unable to connect to ${title}. Try again...`)
      })
      const result = await promise
      externalRedirect(result.authorize_url)
    } catch {}
  }

  const disableSubmitButton = isSubmitting || !isDirty || installResult.isLoading

  return (
    <Fragment>
      <Heading level={2}>Connect to your {title} account</Heading>
      <form onSubmit={handleSubmit(handleConnect)}>
        <FormControl isInvalid={errors.accountUrl}>
          <Label>Consumer Key</Label>
          <Input {...register('consumerKey')} />
          {errors.consumerKey && <FormErrorText>{errors.consumerKey.message}</FormErrorText>}
        </FormControl>
        <FormControl isInvalid={errors.apiKey}>
          <Label>Client Secret</Label>
          <Input {...register('clientSecret')} />
          {errors.clientSecret && <FormErrorText>{errors.clientSecret.message}</FormErrorText>}
        </FormControl>
        <Button variant='primary' type='submit' disabled={disableSubmitButton}>
          Connect
        </Button>
        <Button variant='link-muted' onClick={onCancel}>
          Cancel
        </Button>
      </form>
      <div className={styles.CallbackURL}>
        Callback URL: <Text variant='muted'>{app.callback_url}</Text>
        <div
          className={styles.CopyButton}
          onClick={() => copy(app.callback_url)}
          title={(copied ? 'Copied' : 'Copy') + ' Callback URL'}
        >
          {!copied && <CopyIcon />}
          {copied && <CopiedIcon />}
        </div>
      </div>
    </Fragment>
  )
}

export default SimpplrAuthForm
