import * as yup from 'yup'

export const actionFormValidationSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
  workspace_id: yup.string().required('Workspace is required'),
  steps: yup.array().of(yup.object()).min(1, 'At least one step is required'),
  selectedStep: yup
    .object()
    .nullable()
    .when('showModal', {
      is: true,
      then: yup.object().shape({
        value: yup.string().required('Step type is required')
      })
    })
})

export const StepFormValidationSchema = (createTicketSettings) => {
  return yup.object().shape({
    selectedStep: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required()
    }),
    parameters: yup
      .array()
      .notRequired()
      .when('selectedStep.value', {
        is: 'create_ticket',
        then: (schema) =>
          schema.test({
            test: (value) => Object.keys(value).length === createTicketSettings?.inputs.length,
            message: 'All parameters are required'
          })
      }),
    message: yup
      .string()
      .notRequired()
      .when('selectedStep.value', {
        is: 'send_message',
        then: (schema) => schema.required('Message cannot be empty')
      })
  })
}
