import { TableOption, TableOptions } from '@happyfoxinc/react-ui'
import { Fragment, useCallback, useState } from 'react'
import toast from 'react-hot-toast'

import ConfirmationModal from 'Components/ConfirmationModal'
import { CONFIRM_MESSAGES } from 'Constants/messages'
import api from 'Services/api'

const ActionOptions = ({ id }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const [deleteAIAction] = api.useDeleteAIActionMutation()

  const handleDeleteClick = useCallback(() => {
    const promise = deleteAIAction(id).unwrap()

    toast.promise(promise, {
      loading: 'Deleting Action...',
      success: 'Action deleted successfully.',
      error: 'Unable to delete action. Try again...'
    })

    promise.then(() => setIsDeleteModalOpen(false))
  }, [deleteAIAction, id])

  return (
    <Fragment>
      <TableOptions>
        <TableOption onClick={() => setIsDeleteModalOpen(true)}>Delete</TableOption>
      </TableOptions>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        variant='alert'
        message={CONFIRM_MESSAGES.DELETE_ACTION}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteClick}
      />
    </Fragment>
  )
}

export default ActionOptions
