import styles from './Brand.module.css'

import BrandLogo from 'Images/assist-ai-brand-logo.svg'

const Brand = () => {
  return (
    <div className={styles.BrandContainer}>
      <BrandLogo />
    </div>
  )
}

export default Brand
