import { useEffect, useMemo, useRef, useState } from 'react'

const useInfinitePageData = (inputData) => {
  const arr = useRef([])
  const initialized = useRef(false)
  const [currentData, setCurrentData] = useState({})

  useEffect(() => {
    if (!initialized.current && typeof inputData?.meta?.total !== 'undefined') {
      initialized.current = true
      arr.current = new Array(inputData.meta.total)
    }

    if (initialized.current) {
      setCurrentData({
        offset: inputData.meta.offset,
        limit: inputData.meta.limit,
        items: inputData.results
      })
    }
  }, [inputData])

  const data = useMemo(() => {
    if (!currentData.items) {
      return []
    }

    arr.current.splice(currentData.offset, currentData.items.length, ...currentData.items)
    return arr.current.slice(0, currentData.offset + currentData.limit)
  }, [currentData])

  return data
}

export default useInfinitePageData
