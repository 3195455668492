// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Cw0uEaadM7Yb8E3mTc5d{display:flex;align-items:flex-end;margin-top:20px;margin-bottom:15px}.f0Qd8dKHvuclKIsNLzCt{margin-right:auto}.VJ2Y3X3n_JTRfPV2PGpk{box-shadow:rgba(0,0,0,.05) 0px 0px 0px 1px;border:1px solid}.rLN1jHWWKYVjfMnDKJV_{background-color:#eee}.PoMxENKsSKJA0qpmlwud{color:rgba(0,0,0,.57);font-weight:400}.XEORKm3CHK6Mc1PskfqY{font-size:var(--text-sm)}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/components/AiActions/Table/Table.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,oBAAA,CACA,eAAA,CACA,kBAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,0CAAA,CACA,gBAAA,CAGF,sBACE,qBAAA,CAGF,sBACE,qBAAA,CACA,eAAA,CAGF,sBACE,wBAAA","sourcesContent":[".TableHeadingContainer {\n  display: flex;\n  align-items: flex-end;\n  margin-top: 20px;\n  margin-bottom: 15px;\n}\n\n.TableHeading {\n  margin-right: auto;\n}\n\n.ActionTable {\n  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;\n  border: 1px solid;\n}\n\n.TableHead {\n  background-color: rgb(238, 238, 238);\n}\n\n.Th {\n  color: rgba(0, 0, 0, 0.57);\n  font-weight: 400;\n}\n\n.Td {\n  font-size: var(--text-sm);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TableHeadingContainer": "Cw0uEaadM7Yb8E3mTc5d",
	"TableHeading": "f0Qd8dKHvuclKIsNLzCt",
	"ActionTable": "VJ2Y3X3n_JTRfPV2PGpk",
	"TableHead": "rLN1jHWWKYVjfMnDKJV_",
	"Th": "PoMxENKsSKJA0qpmlwud",
	"Td": "XEORKm3CHK6Mc1PskfqY"
};
export default ___CSS_LOADER_EXPORT___;
