export const getOffsetFromPage = (pageNumber, itemsPerPage) => {
  return (pageNumber - 1) * itemsPerPage // page number starts from 1. for offset calculation, we need to start from 0
}

export const getPaginationQueryObject = (input) => {
  const query = {}

  if (input.currentPage && input.itemsPerPage) {
    query.offset = getOffsetFromPage(input.currentPage, input.itemsPerPage)
    query.limit = input.itemsPerPage
  }

  return query
}

export const getTotalPageCount = (totalItems, itemsPerPage) => {
  return Math.ceil(totalItems / itemsPerPage)
}

export const canGoToNextPage = (currentPage, totalItems, itemsPerPage) => {
  const totalPages = getTotalPageCount(totalItems, itemsPerPage)
  return currentPage < totalPages
}

export const canGoToPreviousPage = (currentPage) => {
  return currentPage > 1
}
