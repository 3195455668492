import * as yup from 'yup'

import { USER_MANAGEMENT_TYPE } from 'Constants/user-groups'
import noHtmlTagTypeEnclosedText from 'Utils/inputValidation/noHtmlTagTypeEnclosedText'

const { MANUAL, CONDITIONAL } = USER_MANAGEMENT_TYPE

const userGroupValidationSchema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required('Name is required')
      .min(2, 'Name should contain at least ${min} characters')
      .test(noHtmlTagTypeEnclosedText),

    users: yup
      .array()
      .notRequired()
      .when('addedFrom', {
        is: (data) => data && data.value === MANUAL,
        then: (schema) => schema.min(1, 'Atleast ${min} user should be added to the group')
      }),
    addedFrom: yup.object().required('Required'),
    syncedFromGroup: yup
      .object()
      .notRequired()
      .when('addedFrom', {
        is: (data) => data && data.value !== MANUAL && data.value !== CONDITIONAL,
        then: (rule) => rule.required('Group is required')
      }),
    conditions: yup
      .array()
      .notRequired()
      .when('addedFrom', {
        is: (data) => data && data.value === CONDITIONAL,
        then: (schema) => schema.min(1, 'Atleast ${min} condition should be added to the group')
      })
  })
  .required()

export default userGroupValidationSchema
