import { Button } from '@happyfoxinc/react-ui'
import { Fragment, useState } from 'react'
import toast from 'react-hot-toast'

import api from 'Services/api'

import WorkspaceModal from '../WorkspaceModal'

const CreateWorkspace = () => {
  const [showModal, setShowModal] = useState(false)
  const [createWorkspace, createWorkspaceResult] = api.useCreateWorkspaceMutation()

  const handleCreateWorkspaceClick = () => {
    setShowModal(true)
  }

  const handleHideModal = () => {
    setShowModal(false)
  }

  const handleCreateWorkspace = async (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      visibility: data.visibility
    }

    if (data.visibleToGroups) {
      payload.mapped_user_groups = data.visibleToGroups.map((group) => group.id)
    }

    try {
      const promise = createWorkspace(payload).unwrap()

      toast.promise(promise, {
        loading: 'Creating workspace',
        success: 'Workspace created successfully',
        error: (err) => {
          const errorMessage = err?.data?.error || 'Unable to create workspace'
          return errorMessage
        }
      })

      await promise
      setShowModal(false)
    } catch {}
  }

  const disableCreateButton = createWorkspaceResult.isLoading

  return (
    <Fragment>
      {showModal && (
        <WorkspaceModal
          onHide={handleHideModal}
          onSubmit={handleCreateWorkspace}
          disableSubmitButton={disableCreateButton}
        />
      )}
      <Button variant='primary' onClick={handleCreateWorkspaceClick}>
        Create Workspace
      </Button>
    </Fragment>
  )
}

export default CreateWorkspace
