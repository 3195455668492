// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Om282Xg0DGJ22jiJybrh{border:none;background-color:rgba(0,0,0,0);padding:.5rem;border-radius:50%;cursor:pointer}.Om282Xg0DGJ22jiJybrh:hover{background-color:var(--hrx-colors-secondaryBgHover)}.QR7Eq69iObTWQOr1v_rT{width:.9em;height:.9em}.tqXHWTUgzL7JoH7eYk8q{display:flex;align-items:center;gap:.8rem}", "",{"version":3,"sources":["webpack://./../src/components/PageTitle/PageTitle.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,8BAAA,CACA,aAAA,CACA,iBAAA,CACA,cAAA,CACA,4BACE,mDAAA,CAIJ,sBACE,UAAA,CACA,WAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,SAAA","sourcesContent":[".ButtonContainer {\n  border: none;\n  background-color: transparent;\n  padding: 0.5rem;\n  border-radius: 50%;\n  cursor: pointer;\n  &:hover {\n    background-color: var(--hrx-colors-secondaryBgHover);\n  }\n}\n\n.BackButton {\n  width: 0.9em;\n  height: 0.9em;\n}\n\n.Title {\n  display: flex;\n  align-items: center;\n  gap: 0.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ButtonContainer": "Om282Xg0DGJ22jiJybrh",
	"BackButton": "QR7Eq69iObTWQOr1v_rT",
	"Title": "tqXHWTUgzL7JoH7eYk8q"
};
export default ___CSS_LOADER_EXPORT___;
