// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uJTpmS0AdXI8by6e3ELt{margin-bottom:20px}", "",{"version":3,"sources":["webpack://./../src/components/InsertMergeFields/BadgeContainer/BadgeContainer.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA","sourcesContent":[".BadgeContainer {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BadgeContainer": "uJTpmS0AdXI8by6e3ELt"
};
export default ___CSS_LOADER_EXPORT___;
