import { AppBase, shouldShowUpgradePrompt, useAccountAndPlan } from '../Common/CommonUpgrade'
import UpgradePrompt from '../Common/UpgradePrompt'

const ConfigureApp = ({ name, url, onConfigure, isConfiguring = false }) => {
  const { account, planCategory, isPremiumAppsEnabled } = useAccountAndPlan()

  const upgradeMessage = shouldShowUpgradePrompt(planCategory, isPremiumAppsEnabled, account)

  if (upgradeMessage) {
    return <UpgradePrompt title={upgradeMessage.title} message={upgradeMessage.message} />
  }

  const message = `You haven't configured ${name} account for this workspace. The account is tied to ${url}.`
  return (
    <AppBase name={name} message={message} buttonText='Configure' onAction={onConfigure} isLoading={isConfiguring} />
  )
}

export default ConfigureApp
