import { BaseInput, styled, useFormControl } from '@happyfoxinc/react-ui'
import { forwardRef } from 'react'
import 'trix'
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from 'react-trix-rte'

import styles from './TrixEditor.module.scss'

const toolbarItems = [
  'bold',
  'italic',
  'strike',
  'link',
  'heading1',
  'quote',
  'code',
  'bullet',
  'number',
  'undo',
  'redo'
]

const TrixInput = styled(BaseInput, {
  minHeight: '22rem',
  backgroundClip: 'padding-box',
  overflowWrap: 'break-word',
  paddingBottom: '3.5rem',

  variants: {
    isInvalid: {
      true: {
        borderColor: '$dangerSolid'
      }
    }
  }
})

const TrixEditor = forwardRef((props, ref) => {
  const { isInvalid } = useFormControl()
  const { defaultValue, ...rest } = props

  const handleChange = (_event, newValue) => {
    props.onChange(newValue)
  }

  const preventFileUpload = (event) => {
    event.preventDefault()
  }

  return (
    <div className={styles.TrixContainer}>
      <ReactTrixRTEToolbar toolbarId='trix-editor' toolbarActions={toolbarItems} />
      <TrixInput
        as={ReactTrixRTEInput}
        toolbarId='trix-editor'
        defaultValue={defaultValue}
        onChange={handleChange}
        onFileAccepted={preventFileUpload}
        className={styles.TrixContent}
        isInvalid={isInvalid}
        trixInputRef={ref}
        {...rest}
      />
    </div>
  )
})

TrixEditor.displayName = 'TrixEditor'

export default TrixEditor
