import cx from 'classnames'

import styles from './MetricCards.module.scss'

import MetricTile from './MetricTile'

const MetricTileWider = ({ title, info, icon, list, downloadUrl, className }) => {
  return (
    <div className={cx(styles.MetricTileWider, className)}>
      <MetricTile
        title={title}
        info={info}
        icon={icon}
        list={list}
        downloadUrl={downloadUrl}
        classNames={{
          MetricTileContainer: styles.TileWH100
        }}
      />
    </div>
  )
}

export default MetricTileWider
