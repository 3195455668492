import * as yup from 'yup'

import noHtmlTagTypeEnclosedText from 'Utils/inputValidation/noHtmlTagTypeEnclosedText'

const schema = yup
  .object()
  .shape({
    ticketSubject: yup.string().trim().required('Ticket subject is required').test(noHtmlTagTypeEnclosedText),
    allowedTeams: yup.array().min(1, 'Allowed teams is required')
  })
  .required()

export default schema
