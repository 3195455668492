// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kCA0cUQZgzOPNTxMZiY7{position:relative;display:grid;height:100%;place-items:center;background-color:#fff}.sbx7PW9qtNaUdrUkdfMP{position:absolute}", "",{"version":3,"sources":["webpack://./../src/components/AppLoader/AppLoader.module.css"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,WAAA,CACA,kBAAA,CACA,qBAAA,CAGF,sBACE,iBAAA","sourcesContent":[".Container {\n  position: relative;\n  display: grid;\n  height: 100%;\n  place-items: center;\n  background-color: #fff;\n}\n\n.Logo {\n  position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "kCA0cUQZgzOPNTxMZiY7",
	"Logo": "sbx7PW9qtNaUdrUkdfMP"
};
export default ___CSS_LOADER_EXPORT___;
