import { Box, Dropdown, Flex, Input, useDropdownContext } from '@happyfoxinc/react-ui'
import cx from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { DayPicker } from 'react-day-picker'

import styles from './DateRangePicker.module.scss'
import rdpStyles from 'react-day-picker/dist/style.module.css'

import CalenderIcon from 'Icons/calender.svg'

import { customDates, format, isSameDay } from 'Utils/date'
import dayjs from 'Utils/dayjs-helper'

import { getPredefinedDateRanges, RANGES } from './config'

const DEFAULT_CONFIG = {
  mode: 'single',
  placeholder: 'Select Date',
  minDate: customDates.pastYearStarting(2),
  maxDate: customDates.now(),
  // If the "numberOfMonths" is greater than one then the "defaultMonth" should be customDates.currentMonthStarting()
  numberOfMonths: 1,
  defaultMonth: customDates.currentMonthStarting(),
  // Check https://daypicker.dev/api/type-aliases/Matcher to know more about the "Matcher" types
  disableMatcher: false,
  showPredefinedRanges: false,
  locale: 'es',
  timeZone: dayjs.tz.guess()
}

const DatePickerDropdown = ({
  value: { key: selectedRangeKey, range },
  onChange,
  mode = DEFAULT_CONFIG.mode,
  minDate = DEFAULT_CONFIG.minDate,
  maxDate = DEFAULT_CONFIG.maxDate,
  numberOfMonths = DEFAULT_CONFIG.numberOfMonths,
  defaultMonth = DEFAULT_CONFIG.defaultMonth,
  showPredefinedRanges = DEFAULT_CONFIG.showPredefinedRanges,
  disableMatcher = DEFAULT_CONFIG.disableMatcher,
  locale = DEFAULT_CONFIG.locale,
  timeZone = DEFAULT_CONFIG.timeZone
}) => {
  const [dateRange, setDateRange] = useState(range)

  const predefinedDateRanges = useMemo(() => getPredefinedDateRanges(minDate), [minDate])

  const findPredefinedRangeObj = useCallback(
    (rangeInput) => {
      const getPredefinedRange = () => {
        return predefinedDateRanges
          .filter(({ range }) => range)
          .find(({ range }) => {
            const isSameFrom = isSameDay(range.from, rangeInput.from)
            const isSameTo = isSameDay(range.to, rangeInput.to)
            return isSameFrom && isSameTo
          })
      }

      const matchedPredefinedRange = getPredefinedRange()

      if (matchedPredefinedRange) {
        return matchedPredefinedRange
      }

      return predefinedDateRanges.find(({ key }) => key === RANGES.CUSTOM)
    },
    [predefinedDateRanges]
  )

  const isValidRange = (range) => {
    return range && range.from && range.to
  }

  const handleSelection = (range) => {
    if (!range || !range.from || !range.to) return

    const { from, to } = range
    range.from = new Date(from) <= new Date(minDate) ? dayjs(minDate).toDate() : dayjs(from).startOf('day').toDate()
    range.to = dayjs(to).endOf('day').toDate()

    setDateRange(range)

    if (!isValidRange(range)) return
    const dateRangeObj = {
      ...findPredefinedRangeObj(range),
      range
    }

    onChange(dateRangeObj)
  }

  const handlePredefinedRangeClick = (selectedDateRangeObj) => {
    const { range } = selectedDateRangeObj

    setDateRange(range)

    onChange(selectedDateRangeObj)
  }

  const isPredefinedRangeSelected = (key) => selectedRangeKey === key

  const isCustomRange = (key) => key === RANGES.CUSTOM

  const classNames = {
    ...rdpStyles,
    root: cx(rdpStyles.root, styles.Root),
    caption: cx(rdpStyles.caption, styles.Caption),
    caption_label: cx(rdpStyles.caption_label, styles.CaptionLabel),
    nav_button: cx(rdpStyles.nav_button, styles.NavButton),
    day_selected: cx(rdpStyles.day_selected, styles.DaySelected)
  }

  return (
    <Flex>
      <DayPicker
        mode={mode}
        selected={dateRange}
        numberOfMonths={numberOfMonths}
        onSelect={handleSelection}
        startMonth={minDate}
        endMonth={maxDate}
        disabled={disableMatcher}
        defaultMonth={defaultMonth}
        classNames={classNames}
        className={styles.DayPicker}
        locale={locale}
        timeZone={timeZone}
      />
      {mode === 'range' && showPredefinedRanges && (
        <Box>
          <ul className={styles.PredefinedContainer}>
            {predefinedDateRanges.map((predefinedItem) => {
              const { label, key } = predefinedItem
              return (
                <li
                  className={cx(styles.PredefinedItem, {
                    [styles.Selected]: isPredefinedRangeSelected(key),
                    [styles.NoSelect]: isCustomRange(key),
                    [styles.Visibility]: !predefinedItem.show
                  })}
                  onClick={() => handlePredefinedRangeClick(predefinedItem)}
                  key={key}
                >
                  {label}
                </li>
              )
            })}
          </ul>
        </Box>
      )}
    </Flex>
  )
}

const Action = ({ value, placeholder }) => {
  const { getReferenceProps } = useDropdownContext()
  const getValue = () => {
    const { from, to } = value

    const formatTemplate = 'MMM D,YYYY'
    const startDate = format(from, formatTemplate)
    const endDate = format(to, formatTemplate)

    return `${startDate} - ${endDate}`
  }

  return (
    <div className={styles.DateRangeFieldContainer} {...getReferenceProps()}>
      <Input className={styles.DateRangeField} placeholder={placeholder} value={getValue()} readOnly />
      <CalenderIcon className={styles.CalenderIcon} />
    </div>
  )
}

const DateRangePicker = ({ value, onChange, ...rest }) => {
  const [dateRangeObj, setDateRangeObj] = useState(value)

  const {
    mode,
    placeholder,
    minDate,
    maxDate,
    numberOfMonths,
    defaultMonth,
    disableMatcher,
    showPredefinedRanges,
    locale,
    timeZone,
    classNames
  } = rest

  const handleClose = () => {
    onChange(dateRangeObj)
  }

  const { range: dateRange } = dateRangeObj
  return (
    <Dropdown
      id='date-range-picker-root'
      offset={8}
      className={cx(styles.Dropdown, classNames?.dropdown)}
      action={<Action placeholder={placeholder} value={dateRange} />}
      dropdown={
        <DatePickerDropdown
          mode={mode}
          minDate={minDate}
          maxDate={maxDate}
          value={dateRangeObj}
          numberOfMonths={numberOfMonths}
          defaultMonth={defaultMonth}
          onChange={(value) => {
            setDateRangeObj(value)
            onChange(value)
          }}
          disableMatcher={disableMatcher}
          showPredefinedRanges={showPredefinedRanges}
          timeZone={timeZone}
          locale={locale}
        />
      }
      onClose={handleClose}
    />
  )
}

export default DateRangePicker
