import { Flex, Label } from '@happyfoxinc/react-ui'
import { useState } from 'react'

import ReactSelect from 'Components/ReactSelect'
import api from 'Services/api'

const SlackTeamSelection = ({ teamId, onTeamChange }) => {
  const { data: slackTeams = [] } = api.useGetSlackTeamsQuery()
  const findTeamObj = () => slackTeams.find(({ team_id }) => team_id === teamId)

  const [slackTeam, setSlackTeam] = useState(findTeamObj)

  const handleSlackTeamChange = (team) => {
    setSlackTeam(team)
    onTeamChange(team.team_id)
  }

  return (
    <Flex align='center' gap='sm'>
      <Label css={{ marginBottom: 0 }}>Slack Workspace:</Label>
      <ReactSelect
        value={slackTeam}
        onChange={handleSlackTeamChange}
        isSearchable={false}
        options={slackTeams}
        getOptionLabel={(option) => option.team_name}
        getOptionValue={(option) => option.team_id}
      />
    </Flex>
  )
}

export default SlackTeamSelection
