import { Outlet, Route, Routes } from 'react-router-dom'

import ActionsList from './ActionsList'
import CreateActions from './CreateActions'
import EditActions from './EditActions'

const Actions = () => {
  return (
    <Routes>
      <Route path='/' element={<Outlet />}>
        <Route path='' index element={<ActionsList />} />
        <Route path='create' element={<CreateActions />} />
        <Route path=':id' element={<EditActions />} />
      </Route>
    </Routes>
  )
}

export default Actions
