import * as yup from 'yup'

const outlineAuthFormValidationSchema = yup
  .object()
  .shape({
    baseUrl: yup.string().trim().required('Base URL is required').url('Invalid URL'),
    token: yup.string().trim().required('Token is required')
  })
  .required()

export default outlineAuthFormValidationSchema
