// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mK__q55xEG8ghppp0_9h{background-color:#fff;padding:2rem 2rem 3rem 2rem;border-radius:var(--border-radius);min-height:163px}.mK__q55xEG8ghppp0_9h .VCboVWuUOH3v6xV56Bzz{width:max-content}.mK__q55xEG8ghppp0_9h .a5Lp8RTxW5CLRekcWaOS{display:grid;grid-template-columns:repeat(14, minmax(0, 1fr))}.mK__q55xEG8ghppp0_9h .a5Lp8RTxW5CLRekcWaOS .gxeAiDLjFmMRX1OrSpRK{grid-column:1/10}.mK__q55xEG8ghppp0_9h .a5Lp8RTxW5CLRekcWaOS .YblQeSfRh4LkcEAmxRik{grid-column:11/15}.A3UNmGoWN4dCZ6kvLmSO{color:var(--primary-extra-dark)}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Settings/TimeZone/TimeZone.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,2BAAA,CACA,kCAAA,CACA,gBAAA,CAEA,4CACE,iBAAA,CAGF,4CACE,YAAA,CACA,gDAAA,CACA,kEACE,gBAAA,CAEF,kEACE,iBAAA,CAKN,sBACE,+BAAA","sourcesContent":[".Container {\n  background-color: white;\n  padding: 2rem 2rem 3rem 2rem;\n  border-radius: var(--border-radius);\n  min-height: 163px;\n\n  .SubHeading {\n    width: max-content;\n  }\n\n  .ContentContainer {\n    display: grid;\n    grid-template-columns: repeat(14, minmax(0, 1fr));\n    .DescriptionColumn {\n      grid-column: 1/10;\n    }\n    .UserInputColumn {\n      grid-column: 11/15;\n    }\n  }\n}\n\n.Link {\n  color: var(--primary-extra-dark);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "mK__q55xEG8ghppp0_9h",
	"SubHeading": "VCboVWuUOH3v6xV56Bzz",
	"ContentContainer": "a5Lp8RTxW5CLRekcWaOS",
	"DescriptionColumn": "gxeAiDLjFmMRX1OrSpRK",
	"UserInputColumn": "YblQeSfRh4LkcEAmxRik",
	"Link": "A3UNmGoWN4dCZ6kvLmSO"
};
export default ___CSS_LOADER_EXPORT___;
