// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g8fLhFYgaPjkUoA_QVv5{border-radius:50%;-o-object-fit:cover;object-fit:cover}", "",{"version":3,"sources":["webpack://./../src/components/Topbar/UserActions.module.css"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,mBAAA,CAAA,gBAAA","sourcesContent":[".ProfilePic {\n  border-radius: 50%;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProfilePic": "g8fLhFYgaPjkUoA_QVv5"
};
export default ___CSS_LOADER_EXPORT___;
