import {
  Button,
  Flex,
  FormControl,
  FormErrorText,
  Label,
  PaginationControls,
  TableOption,
  TableOptions,
  Text,
  useBoolean,
  useConst
} from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import cx from 'classnames'
import { forwardRef, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import styles from './KnowledgeConfiguration.module.scss'

import AlertIcon from 'Icons/alert.svg'
import ArrowRightIcon from 'Icons/arrow-right.svg'

import Link from 'Components/Link'
import Modal, { ModalBody, ModalHeader } from 'Components/Modal'
import PageLoader from 'Components/PageLoader'
import { usePlanContext } from 'Components/Plan'
import ReactSelect from 'Components/ReactSelect'
import UserGroupBadge from 'Components/UserGroupBadge'
import { KNOWLEDGE_GROUP_VISIBILITY_OPTIONS, KNOWLEDGE_GROUP_VISIBILITY_STATES } from 'Constants/user-groups'
import api from 'Services/api'
import parseErrorMessage from 'Utils/error-message-parser'
import useInfinitePageData from 'Utils/hooks/useInfinitePageData'
import { useWorkspace } from 'Utils/hooks/useWorkspace'
import { canGoToNextPage, canGoToPreviousPage, getOffsetFromPage, getPaginationQueryObject } from 'Utils/pagination'

import { useAppDetailContext } from '../AppDetails/AppDetailContext'
import KBZeroIcon from './KB_Zero.svg'
import knowledgeConfigurationValidationSchema from './knowledge-configuration-validation-schema'

const ITEMS_PER_PAGE = 15

export const Accordion = AccordionPrimitive.Root
export const AccordionItem = AccordionPrimitive.Item
const AccordionHeader = AccordionPrimitive.Header
export const AccordionTrigger = forwardRef((props, ref) => {
  return <AccordionPrimitive.Trigger {...props} className={cx(styles.AccordionTrigger, props.className)} ref={ref} />
})
AccordionTrigger.displayName = 'AccordionTrigger'
export const AccordionContent = AccordionPrimitive.Content

const VisibilityModal = ({ responseGroup, onClose, hasUserGroups, workspaceId }) => {
  const navigate = useNavigate()
  const { id: appName } = useAppDetailContext()
  const selectPortalRef = useRef()

  const {
    visibility,
    possible_visibilities: possibleVisibilities,
    mapped_user_groups: mappedUserGroups,
    user_groups_available_to_map: userGroupsAvailableToMap,
    title,
    id: responseGroupId
  } = responseGroup

  const [updateResponseGroup, updateResponseGroupResult] = api.useUpdateResponseGroupMutation()

  const [checkResponseGroupVisibilityUpdate, checkResponseGroupVisibilityUpdateResult] =
    api.useLazyCheckResponseGroupVisibilityUpdateQuery()

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm({
    defaultValues: {
      visibility,
      userGroups: mappedUserGroups
    },
    resolver: yupResolver(knowledgeConfigurationValidationSchema)
  })

  const visibilityStatuses = useMemo(() => {
    return KNOWLEDGE_GROUP_VISIBILITY_OPTIONS.filter((status) => {
      return possibleVisibilities.includes(status.value)
    })
  }, [possibleVisibilities])

  const handleFormSubmit = (data) => {
    const payload = {
      appName,
      id: responseGroupId,
      workspace_id: workspaceId,
      visibility: data.visibility
    }

    if (data.userGroups) {
      payload.mapped_user_groups = data.userGroups.map((usergroup) => usergroup.id)
    }

    const promise = updateResponseGroup(payload).unwrap()

    promise.then(() => onClose())
    toast.promise(promise, {
      loading: 'Updating user group visibility',
      success: 'Visibility status updated successfully',
      error: parseErrorMessage('Failed to update visibility status. Try again...')
    })
  }

  const handleCreateUserGroupClick = () => {
    navigate('/user-groups/create')
  }

  const visibilityStatus = watch('visibility')

  const disableSaveButton =
    isSubmitting || updateResponseGroupResult.isLoading || checkResponseGroupVisibilityUpdateResult.isLoading
  const showCreateUseGroupOption = visibilityStatus === KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC && !hasUserGroups

  const [forceUpdateChildren, setForceUpdateChildren] = useState(false)

  const submitButtonText = forceUpdateChildren ? 'Proceed' : 'Save'

  const handleVisibilityUpdate = useCallback(
    ({ visibility, userGroups }) => {
      setForceUpdateChildren(false)

      const checkVisibilityUpdateAPI = async () => {
        const params = {
          appName,
          id: responseGroupId,
          visibility
        }

        if (visibility === KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC) {
          if (!userGroups) {
            return
          }

          const mappedUserGroups = userGroups.map((group) => group.id)
          if (mappedUserGroups.length === 0) {
            return
          }
          params.mapped_user_groups = mappedUserGroups.toString()
        }

        try {
          const result = await checkResponseGroupVisibilityUpdate(params).unwrap()
          setForceUpdateChildren(result.will_force_update_children)
        } catch {}
      }

      checkVisibilityUpdateAPI()
    },
    [appName, checkResponseGroupVisibilityUpdate, responseGroupId]
  )

  useEffect(() => {
    const subscription = watch((formValue, { type }) => {
      if (type === 'change') {
        handleVisibilityUpdate(formValue)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, handleVisibilityUpdate])

  return (
    <ModalBody>
      <div ref={selectPortalRef} />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <FormControl>
          <Label>
            <strong>{title}</strong> Visible to
          </Label>
          <Controller
            name='visibility'
            control={control}
            shouldUnregister
            render={({ field }) => {
              return (
                <ReactSelect
                  {...field}
                  options={visibilityStatuses}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  value={visibilityStatuses.find(({ value }) => value === field.value)}
                  onChange={({ value }) => field.onChange(value)}
                  inDialog
                />
              )
            }}
          />
        </FormControl>
        <FormControl>
          {showCreateUseGroupOption && (
            <Text variant='muted'>
              Hey! Looks like you haven't created any user groups yet. Please create a user group and then set
              visibility for the added knowledge source.
            </Text>
          )}
          {visibilityStatus === KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC && !showCreateUseGroupOption && (
            <Fragment>
              <Label>Select user group(s)</Label>
              <Controller
                name='userGroups'
                control={control}
                shouldUnregister
                render={({ field }) => {
                  return (
                    <ReactSelect
                      {...field}
                      isMulti
                      isClearable={false}
                      options={userGroupsAvailableToMap}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      inDialog
                    />
                  )
                }}
              />
              {errors.userGroups && <FormErrorText>{errors.userGroups.message}</FormErrorText>}
            </Fragment>
          )}
        </FormControl>

        {forceUpdateChildren && (
          <div className={cx(styles.VisibilityAlert)}>
            <div className={cx(styles.AlertIconContainer)}>
              <AlertIcon />
            </div>
            <Text className={styles.AlertTextContainer} variant='muted'>
              Restricting the visibility on this level might affect the visibility of a few articles or sections under
              this section. Would you still like to proceed ?
            </Text>
          </div>
        )}

        <Flex align='start'>
          {showCreateUseGroupOption && (
            <Button variant='primary' onClick={handleCreateUserGroupClick}>
              Create User Groups
            </Button>
          )}
          {!showCreateUseGroupOption && (
            <Button type='submit' variant='primary' disabled={disableSaveButton}>
              {submitButtonText}
            </Button>
          )}
          <Button variant='link-muted' onClick={onClose}>
            Cancel
          </Button>
        </Flex>
      </form>
    </ModalBody>
  )
}

const VisibilityModalContainer = ({ id, onClose, onAfterSave }) => {
  const { id: appName } = useAppDetailContext()
  const { data: account } = api.useGetAccountQuery()
  const { currentWorkspaceId } = useWorkspace()
  const accountType = account.account_type

  const { isLoading, data = {} } = api.useGetResponseGroupQuery({ appName, id, workspaceId: currentWorkspaceId })
  const { data: usergroups = {}, isLoading: isUgLoading } = api.useGetUserGroupsQuery({ accountType })

  const hasUserGroups = usergroups?.results?.length > 0

  return (
    <Modal isOpen style={{ content: { width: 480 } }}>
      <ModalHeader onClose={onClose}>
        <Text weight='bold'>Set Visibility</Text>
      </ModalHeader>
      <PageLoader isLoading={isLoading || isUgLoading}>
        <VisibilityModal
          responseGroup={data}
          onClose={onClose}
          onAfterSave={onAfterSave}
          hasUserGroups={hasUserGroups}
          workspaceId={currentWorkspaceId}
        />
      </PageLoader>
    </Modal>
  )
}

const ResponseGroupChildren = (props) => {
  const { id: appName } = useAppDetailContext()
  const [currentPage, setCurrentPage] = useState(1)
  const limit = useConst(15)

  const { data = {}, isLoading } = api.useGetResponseGroupChildrenQuery(
    {
      appName,
      id: props.id,
      params: {
        offset: getOffsetFromPage(currentPage, limit),
        limit
      }
    },
    { refetchOnMountOrArgChange: true }
  )

  const items = useInfinitePageData(data)

  const handleLoadMore = useCallback(() => {
    setCurrentPage((previousPage) => previousPage + 1)
  }, [])

  const canShowLoadmore = canGoToNextPage(currentPage, data.meta?.total, limit)

  if (isLoading) {
    return <PageLoader size='sm' />
  }

  return (
    <Fragment>
      {items.map((res) => {
        return <ResponseGroup key={res.id} {...res} depth={props.depth} />
      })}
      {canShowLoadmore && (
        <Link variant='primary-dark' onClick={handleLoadMore}>
          Load more...
        </Link>
      )}
    </Fragment>
  )
}

const ResponseGroup = (props) => {
  const depth = props.depth ?? 0

  const [isModalOpen, modalStateFlags] = useBoolean(false)
  const { isFreePlan } = usePlanContext()

  const handleSetVisibilityClick = useCallback(() => {
    modalStateFlags.on()
  }, [modalStateFlags])

  const handleModalClose = useCallback(() => {
    modalStateFlags.off()
  }, [modalStateFlags])

  const showOptions = !isFreePlan && props.parent_visibility !== KNOWLEDGE_GROUP_VISIBILITY_STATES.NONE

  return (
    <Fragment>
      {isModalOpen && <VisibilityModalContainer id={props.id} onClose={handleModalClose} />}
      <Accordion type='single' collapsible className={cx(styles.Accordion, { [styles.AccordionRoot]: depth === 0 })}>
        <AccordionItem value={props.id}>
          <AccordionHeader className={styles.AccordionHeader}>
            <AccordionTrigger className={cx({ [styles.Hidden]: !props.has_children })}>
              <span className={styles.AccordionTriggerIcon}>
                <ArrowRightIcon width='1em' height='1em' />
              </span>
            </AccordionTrigger>
            <div className={styles.ContentTitle}>{props.title}</div>
            <div>
              <UserGroupBadge {...props} />
            </div>
            <div className={styles.TableOptionsContainer}>
              {showOptions && (
                <TableOptions>
                  <TableOption onClick={handleSetVisibilityClick}>Set visibility</TableOption>
                </TableOptions>
              )}
            </div>
          </AccordionHeader>
          <AccordionContent className={styles.AccordionContent}>
            <ResponseGroupChildren id={props.id} depth={depth + 1} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Fragment>
  )
}

const KnowledgeConfiguration = ({ app, additionalTableOptions }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const { id: appName, title } = useAppDetailContext()
  const { currentWorkspaceId } = useWorkspace()

  const { data = {}, isLoading } = api.useGetResponseGroupsQuery({
    appName,
    params: {
      ...getPaginationQueryObject({ currentPage, itemsPerPage: ITEMS_PER_PAGE }),
      ...(appName === 'sharepoint' ? { workspace_id: currentWorkspaceId } : {})
    }
  })

  if (isLoading) {
    return <PageLoader />
  }

  const responseGroups = data.results ?? []
  const paginationDetails = data.meta ?? {}

  const handlePreviousPageClick = () => {
    setCurrentPage((currentValue) => currentValue - 1)
  }

  const handleNextPageClick = () => {
    setCurrentPage((currentValue) => currentValue + 1)
  }

  if (responseGroups.length === 0) {
    return (
      <div className={styles.ZeroState}>
        <KBZeroIcon />
        <Text>
          Hey! It looks like you don't have any pages from <br />
          <Text isInline variant='muted'>
            {title} KnowledgeBase
          </Text>
        </Text>
      </div>
    )
  }

  return (
    <Fragment>
      <div className={styles.TableTop}>
        <Text>Synced Articles ({app.total_synced_articles})</Text>
        <div className={styles.PaginationContainer}>
          <PaginationControls
            currentPage={currentPage}
            pageSize={ITEMS_PER_PAGE}
            totalItems={paginationDetails.total}
            canPreviousPage={canGoToPreviousPage(currentPage)}
            canNextPage={canGoToNextPage(currentPage, paginationDetails.total, ITEMS_PER_PAGE)}
            previousPage={handlePreviousPageClick}
            nextPage={handleNextPageClick}
          />
          {additionalTableOptions && additionalTableOptions}
        </div>
      </div>
      <div className={styles.TableContainer} role='table'>
        <div className={styles.TableHeader}>
          <div>Sections</div>
          <div>Visibility</div>
          <div />
        </div>
        <div className={styles.TableBody}>
          {responseGroups.map((res) => {
            return <ResponseGroup key={res.id} {...res} depth={0} />
          })}
        </div>
      </div>
    </Fragment>
  )
}

export default KnowledgeConfiguration
