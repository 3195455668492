// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AJvEbFMcrOY_ZJ5PUC1D{margin-top:-3.3rem}.yIgECYWjJiHVEqORIU9A{width:100%;border-collapse:collapse;overflow:hidden;border-radius:var(--border-radius, 6px);box-shadow:0 0 3px 0 rgba(0,0,0,.2);margin-bottom:1rem}.XrBOdV6jOtTgaNpePm8S,.ZRhQ6yDqRHhaPrv_4S_k,.pMAsl5Q2x_2qxTOuV8lN{padding:.5rem;text-align:inherit;text-align:-webkit-match-parent}.pMAsl5Q2x_2qxTOuV8lN{background-color:#eee}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/AppDetails/apps/Notion/NotionAccounts/NotionAccounts.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,sBACE,UAAA,CACA,wBAAA,CACA,eAAA,CACA,uCAAA,CACA,mCAAA,CACA,kBAAA,CAGF,kEACE,aAAA,CACA,kBAAA,CACA,+BAAA,CAGF,sBAEE,qBAAA","sourcesContent":[".TableContainer {\n  margin-top: -3.3rem;\n}\n\n.Table {\n  width: 100%;\n  border-collapse: collapse;\n  overflow: hidden;\n  border-radius: var(--border-radius, 6px);\n  box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);\n  margin-bottom: 1rem;\n}\n\n.Cell {\n  padding: 0.5rem;\n  text-align: inherit;\n  text-align: -webkit-match-parent;\n}\n\n.Th {\n  @extend .Cell;\n  background-color: #eee;\n}\n\n.Td {\n  @extend .Cell;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TableContainer": "AJvEbFMcrOY_ZJ5PUC1D",
	"Table": "yIgECYWjJiHVEqORIU9A",
	"Cell": "XrBOdV6jOtTgaNpePm8S",
	"Td": "ZRhQ6yDqRHhaPrv_4S_k",
	"Th": "pMAsl5Q2x_2qxTOuV8lN"
};
export default ___CSS_LOADER_EXPORT___;
