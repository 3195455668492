// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ctxuPg_OaLHGLMZiiwCk{display:grid;height:100%}.NBg6yIr3FreSs5rHjl13{width:100%;display:grid;grid-template-columns:repeat(12, minmax(0, 1fr));gap:1rem}.kWjgllq4Up59Uqzl4BQi{display:flex;align-items:center;justify-content:center;grid-column:2/6}.IhvtBGYIkwz9UK2PDYU4{display:flex;flex-direction:column;justify-content:center;grid-column:6/10}.oNVbIy_QFTEDVSTGhXpA{height:20rem;width:20rem;display:flex;align-items:center;justify-content:center;position:relative}.RFHw64NuWVGAvOwizx6w{background-color:var(--primary-lightest);height:100%;width:100%;position:absolute;border-radius:50%}.gQe7Zl6gxJqfS4UTtzPw{margin-bottom:1rem}", "",{"version":3,"sources":["webpack://./../src/layout/NoContent/NoContent.module.css"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CAGF,sBACE,UAAA,CACA,YAAA,CACA,gDAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CAGF,sBACE,wCAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,iBAAA,CAGF,sBACE,kBAAA","sourcesContent":[".Container {\n  display: grid;\n  height: 100%;\n}\n\n.GridContainer {\n  width: 100%;\n  display: grid;\n  grid-template-columns: repeat(12, minmax(0, 1fr));\n  gap: 1rem;\n}\n\n.IconWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  grid-column: 2/6;\n}\n\n.ContentWrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  grid-column: 6/10;\n}\n\n.IconContainer {\n  height: 20rem;\n  width: 20rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n}\n\n.IconCircle {\n  background-color: var(--primary-lightest);\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  border-radius: 50%;\n}\n\n.Description {\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "ctxuPg_OaLHGLMZiiwCk",
	"GridContainer": "NBg6yIr3FreSs5rHjl13",
	"IconWrapper": "kWjgllq4Up59Uqzl4BQi",
	"ContentWrapper": "IhvtBGYIkwz9UK2PDYU4",
	"IconContainer": "oNVbIy_QFTEDVSTGhXpA",
	"IconCircle": "RFHw64NuWVGAvOwizx6w",
	"Description": "gQe7Zl6gxJqfS4UTtzPw"
};
export default ___CSS_LOADER_EXPORT___;
