// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".w6byNcs0J9Vf0tzB32Hy{display:flex;gap:5px}.g1z1eRHvKJbfITzzIKwU{border:1px solid #f1bc23;border-radius:20px;padding:2px;font-size:12px;background:rgba(241,188,35,.1294117647);padding:1px 8px;height:22px}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Workspaces/WorkspaceTable/WorkspaceTable.module.css"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,OAAA,CAGF,sBACE,wBAAA,CACA,kBAAA,CACA,WAAA,CACA,cAAA,CACA,uCAAA,CACA,eAAA,CACA,WAAA","sourcesContent":[".WorkspaceNameCell {\n  display: flex;\n  gap: 5px;\n}\n\n.DefaultLabel {\n  border: 1px solid #f1bc23;\n  border-radius: 20px;\n  padding: 2px;\n  font-size: 12px;\n  background: #f1bc2321;\n  padding: 1px 8px;\n  height: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WorkspaceNameCell": "w6byNcs0J9Vf0tzB32Hy",
	"DefaultLabel": "g1z1eRHvKJbfITzzIKwU"
};
export default ___CSS_LOADER_EXPORT___;
