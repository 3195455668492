// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DMYowtniBHg_lzBYUz8E{padding-left:1.75rem;margin-top:auto;margin-bottom:1rem}", "",{"version":3,"sources":["webpack://./../src/components/Sidebar/Product.module.css"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":[".ProductContainer {\n  padding-left: 1.75rem;\n  margin-top: auto;\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProductContainer": "DMYowtniBHg_lzBYUz8E"
};
export default ___CSS_LOADER_EXPORT___;
