// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uMPG3dD01ayxlUF7TvvK{display:flex;flex-direction:column;height:100%;padding-bottom:1rem}.uMPG3dD01ayxlUF7TvvK .kMWWcmcyLyZnhZwJsdsv{display:flex;margin-top:auto;-moz-column-gap:10px;column-gap:10px}.uMPG3dD01ayxlUF7TvvK .kMWWcmcyLyZnhZwJsdsv button{min-width:auto;width:92px;box-sizing:border-box}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Channels/ChannelsTable/EditChannelModal/EditChannelModal.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,mBAAA,CACA,4CACE,YAAA,CACA,eAAA,CACA,oBAAA,CAAA,eAAA,CACA,mDACE,cAAA,CACA,UAAA,CACA,qBAAA","sourcesContent":[".FormContainer {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding-bottom: 1rem;\n  .ButtonContainer {\n    display: flex;\n    margin-top: auto;\n    column-gap: 10px;\n    button {\n      min-width: auto;\n      width: 92px;\n      box-sizing: border-box;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormContainer": "uMPG3dD01ayxlUF7TvvK",
	"ButtonContainer": "kMWWcmcyLyZnhZwJsdsv"
};
export default ___CSS_LOADER_EXPORT___;
