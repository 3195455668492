// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fu_vWX5zixFmg0xjGT7n{all:unset}.eiG_CRf27bNIWLZAdTbo{display:flex;flex-direction:column;gap:.7rem;align-items:center}.__1LdPnFFNr2awmc1jKf{display:flex;align-items:center;gap:.4rem}", "",{"version":3,"sources":["webpack://./../src/components/UserGroupBadge/UserGroupBadge.module.scss"],"names":[],"mappings":"AAAA,sBACE,SAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,SAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,SAAA","sourcesContent":[".TooltipTrigger {\n  all: unset;\n}\n\n.UpgradeTooltip {\n  display: flex;\n  flex-direction: column;\n  gap: 0.7rem;\n  align-items: center;\n}\n\n.UpgradeButton {\n  display: flex;\n  align-items: center;\n  gap: 0.4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TooltipTrigger": "fu_vWX5zixFmg0xjGT7n",
	"UpgradeTooltip": "eiG_CRf27bNIWLZAdTbo",
	"UpgradeButton": "__1LdPnFFNr2awmc1jKf"
};
export default ___CSS_LOADER_EXPORT___;
