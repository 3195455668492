import { useState } from 'react'

import { useRedirectConfirmationContext } from 'Components/ConfirmationModal/RedirectConfirmationContext'
import { useAppDetailContext } from 'Src/pages/protected/Apps/AppDetails/AppDetailContext'
import InstallApp from 'Src/pages/protected/Apps/components/InstallApp'

const InstallAzureAD = (props) => {
  const [isInstalling, setIsInstalling] = useState()
  const { app } = props
  const { title } = useAppDetailContext()
  const externalRedirect = useRedirectConfirmationContext()

  const handleInstall = () => {
    setIsInstalling(true)
    externalRedirect(app.authorize_url, {
      handleCancel: () => setIsInstalling(false)
    })
  }

  return <InstallApp name={title} onInstall={handleInstall} isInstalling={isInstalling} />
}

export default InstallAzureAD
