import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import styles from './Actions.module.css'

import PageTitle from 'Components/PageTitle'
import api from 'Services/api'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

import ActionForm from './ActionForm'
import { actionFormValidationSchema } from './ActionForm/action-form-validation-schema'

const CreateActions = () => {
  const navigate = useNavigate()
  const { currentWorkspaceId } = useWorkspace()

  const [createAIAction, createAIActionResult] = api.useCreateAIActionMutation()
  const formMethods = useForm({
    defaultValues: {
      title: '',
      description: '',
      inputs: [{ id: '', name: '', type: 'text' }],
      is_active: true,
      outputs: [],
      steps: [],
      parameters: [],
      message: '',
      workspace_id: currentWorkspaceId
    },
    resolver: yupResolver(actionFormValidationSchema)
  })

  const handleFormSubmit = useCallback(
    (data) => {
      const payload = {
        title: data.title,
        description: data.description,
        steps: data.steps,
        inputs: data.inputs.filter((input) => input.name.trim() !== ''),
        outputs: data.outputs,
        is_active: data.is_active,
        workspace_id: data.workspace_id
      }

      const promise = createAIAction(payload).unwrap()

      toast.promise(promise, {
        loading: 'Creating AI Action',
        success: 'Successfully Created AI Action',
        error: 'Unable to create AI Action'
      })

      promise.then((response) => navigate(`../${response.id}`, { replace: true })).catch(() => {})
    },
    [createAIAction, navigate]
  )

  return (
    <Fragment>
      <PageTitle backTo='../'>Actions</PageTitle>
      <div className={styles.Container}>
        <FormProvider {...formMethods}>
          <ActionForm onSubmit={handleFormSubmit} isLoading={createAIActionResult.isLoading} />
        </FormProvider>
      </div>
    </Fragment>
  )
}

export default CreateActions
