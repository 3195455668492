// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EH3su_ii0Oz1UyeKw19d{margin-top:20px}.kC3jifu8bHFSFf4vLrGN{width:600px}.cuXNNJ2_IKlLw6XX2nPI{margin-top:-2.3rem}.bFLRU4AxvHJr1QwUCyP9{padding:.5rem}.MQx3RtstIB8y00PEkyzD{width:123px}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/AppDetails/apps/Outline/components/AddCollections/AddCollections.module.css"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,WAAA,CAGF,sBACE,kBAAA,CAIF,sBACE,aAAA,CAGF,sBACE,WAAA","sourcesContent":[".AddCollectionsContainer {\n  margin-top: 20px;\n}\n\n.AddCollectionsTable {\n  width: 600px;\n}\n\n.TableContainer {\n  margin-top: -2.3rem;\n}\n\n\n.TableCell {\n  padding: 0.5rem;\n}\n\n.AddCollectionsButton {\n  width: 123px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AddCollectionsContainer": "EH3su_ii0Oz1UyeKw19d",
	"AddCollectionsTable": "kC3jifu8bHFSFf4vLrGN",
	"TableContainer": "cuXNNJ2_IKlLw6XX2nPI",
	"TableCell": "bFLRU4AxvHJr1QwUCyP9",
	"AddCollectionsButton": "MQx3RtstIB8y00PEkyzD"
};
export default ___CSS_LOADER_EXPORT___;
