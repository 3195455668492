import {
  Heading,
  Table as ReactTable,
  TableActions,
  TableBody,
  TableHead,
  TableRow,
  Td,
  Th
} from '@happyfoxinc/react-ui'
import { Fragment, useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTable } from 'react-table'

import styles from './Table.module.scss'

import { SuccessSwitch } from 'Components/Switch'
import api from 'Services/api'
import parseErrorMessage from 'Src/utils/error-message-parser'

import { useAppDetailContext } from '../../../AppDetails/AppDetailContext'
import ConfigureModal from '../ConfigureModal'
import Options from './Options'

const Table = ({ data }) => {
  const [modalData, setModalData] = useState(null)
  const [isDefaultEnabled, setIsDefaultEnabled] = useState(false)
  const { title: appTitle, id: appName } = useAppDetailContext()

  const [updateAppsAIAction] = api.useUpdateAppsAIActionMutation()
  const { results: aiActions = [] } = data

  const handleEnabledStatusToggle = useCallback(
    ({ status, id }) => {
      const newStatus = !status
      const payload = {
        is_enabled: newStatus
      }

      const promise = updateAppsAIAction({ appName, id, payload }).unwrap()

      toast.promise(promise, {
        loading: `${newStatus ? 'Enabling' : 'Disabling'} AI actions for ${appTitle}...`,
        success: `AI actions for ${appTitle} ${newStatus ? 'enabled' : 'disabled'} successfully`,
        error: parseErrorMessage(
          `Unable to ${newStatus ? 'enable' : 'disable'} AI actions for ${appTitle}. Try again...`
        )
      })
    },
    [appName, appTitle, updateAppsAIAction]
  )

  const onStatusSwitchToggle = useCallback(
    (data) => {
      const id = data.id
      const status = data.is_enabled

      if (status) {
        handleEnabledStatusToggle({ status, id })
      } else {
        setIsDefaultEnabled(true)
        setModalData(data)
      }
    },
    [handleEnabledStatusToggle, setIsDefaultEnabled, setModalData]
  )

  const columns = useMemo(() => {
    return [
      {
        Header: 'Action Name',
        accessor: 'name'
      },
      {
        Header: 'Approval Required',
        accessor: 'settings.is_approval_needed',
        Cell: ({ value }) => {
          return value === true ? 'Yes' : value === false ? 'No' : '-'
        }
      },
      {
        Header: 'Ticket Creation',
        accessor: 'settings.is_ticketing_enabled',
        Cell: ({ value }) => {
          return value === true ? 'Yes' : value === false ? 'No' : '-'
        }
      },
      {
        Header: 'Status',
        accessor: 'is_enabled',
        Cell: ({ value, row }) => {
          return (
            <div onClick={(event) => event.stopPropagation()}>
              <SuccessSwitch checked={value} onCheckedChange={() => onStatusSwitchToggle(row.original)} />
            </div>
          )
        }
      }
    ]
  }, [onStatusSwitchToggle])

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data: aiActions
  })

  return (
    <Fragment>
      <TableActions className={styles.TableHeadingContainer}>
        <div className={styles.TableHeading}>
          <Heading level={6}>Actions ({aiActions.length})</Heading>
        </div>
      </TableActions>
      <ReactTable {...getTableProps()} className={styles.ActionTable}>
        <TableHead className={styles.TableHead}>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

            return (
              <TableRow key={key} {...headerGroupProps}>
                <Fragment>
                  {headerGroup.headers.map((column) => {
                    const { key, ...headerProps } = column.getHeaderProps()

                    return (
                      <Th className={styles.Th} key={key} {...headerProps}>
                        {column.render('Header')}
                      </Th>
                    )
                  })}
                  <Th isOption />
                </Fragment>
              </TableRow>
            )
          })}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...rowProps } = row.getRowProps()

            return (
              <TableRow key={key} {...rowProps} clickable>
                {row.cells.map((cell) => {
                  const { key, ...cellProps } = cell.getCellProps()
                  return (
                    <Td className={styles.Td} key={key} onClick={() => setModalData(row.original)} {...cellProps}>
                      {cell.render('Cell')}
                    </Td>
                  )
                })}
                <Td>
                  <Options rowData={row.original} onConfigure={setModalData} />
                </Td>
              </TableRow>
            )
          })}
        </TableBody>
      </ReactTable>
      {modalData && (
        <ConfigureModal
          onClose={() => {
            setIsDefaultEnabled(false)
            setModalData(null)
          }}
          data={modalData}
          isDefaultEnabled={isDefaultEnabled}
        />
      )}
    </Fragment>
  )
}

export default Table
