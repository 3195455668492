// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qHKzqyDWGWa0kpok23U2{width:100%;height:5px;background-color:#f1f1f1;border-radius:var(--border-radius)}.qHKzqyDWGWa0kpok23U2 .pNGtbldihkLrYTa7BCFw{background-color:var(--primary);height:100%;border-radius:var(--border-radius) 0 0 var(--border-radius);width:0;animation:P6OMgmU6RC9N_H98Vmdh 1s ease-in}@keyframes P6OMgmU6RC9N_H98Vmdh{from{width:0}}", "",{"version":3,"sources":["webpack://./../src/components/ProgressBar/ProgressBar.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,UAAA,CACA,wBAAA,CACA,kCAAA,CACA,4CACE,+BAAA,CACA,WAAA,CACA,2DAAA,CACA,OAAA,CACA,yCAAA,CAIJ,gCACE,KACE,OAAA,CAAA","sourcesContent":[".ProgressBar {\n  width: 100%;\n  height: 5px;\n  background-color: #f1f1f1;\n  border-radius: var(--border-radius);\n  .Fill {\n    background-color: var(--primary);\n    height: 100%;\n    border-radius: var(--border-radius) 0 0 var(--border-radius);\n    width: 0;\n    animation: AnimateProgressFill 1s ease-in;\n  }\n}\n\n@keyframes AnimateProgressFill {\n  from {\n    width: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProgressBar": "qHKzqyDWGWa0kpok23U2",
	"Fill": "pNGtbldihkLrYTa7BCFw",
	"AnimateProgressFill": "P6OMgmU6RC9N_H98Vmdh"
};
export default ___CSS_LOADER_EXPORT___;
