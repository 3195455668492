import { Flex, Heading, Text } from '@happyfoxinc/react-ui'
import { Fragment, useMemo } from 'react'

import Link from 'Components/Link'
import PageLoader from 'Components/PageLoader'
import KB_LINKS from 'Constants/kb-links'
import api from 'Services/api'
import { getInitialApiQueryParams } from 'Src/pages/protected/Channels/channels-helper'

import ChannelsTable from './ChannelsTable'
import NoChannels from './NoChannels'
import TipToAddInChannels from './TipToAddInChannels'

const ChannelsList = () => {
  const queryParams = useMemo(getInitialApiQueryParams, [])

  const { data = {}, isLoading } = api.useGetSlackChannelsQuery(queryParams)

  if (isLoading) {
    return <PageLoader />
  }

  if (data.results?.length === 0) {
    return <NoChannels />
  }

  return (
    <Fragment>
      <Heading>Channels</Heading>

      <Flex direction='c' gap='1'>
        <Text variant='muted'>
          Slack Channels to which Assist AI is added are listed below. Assist AI can now respond to the queries posted
          on these channels. Learn more about channel configuration{' '}
          <Link variant='primary-dark' href={KB_LINKS.SLACK_CHANNEL_CONFIGURATION} target='_blank' rel='noreferrer'>
            here
          </Link>
        </Text>

        <Text variant='muted'>
          <TipToAddInChannels triggerText='Quick Tip' /> for adding Assist AI to Slack channels.
        </Text>
      </Flex>

      <ChannelsTable />
    </Fragment>
  )
}

const Channels = () => {
  return <ChannelsList />
}

export default Channels
