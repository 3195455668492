export const getConcatenatedMergeFieldText = (mergeFields, addSpacerAtStart = false, addSpacerAtEnd = false) => {
  const spacer = ' '
  const concatenatedText = mergeFields.map((field) => `{{${field}}}`).join(spacer)

  const getOutput = () => {
    if (addSpacerAtStart && addSpacerAtEnd) return spacer + concatenatedText + spacer
    if (addSpacerAtStart) return spacer + concatenatedText
    if (addSpacerAtEnd) return concatenatedText + spacer
    return concatenatedText
  }

  return getOutput()
}
