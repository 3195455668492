import * as yup from 'yup'

const quickActionsConfigFormValidationSchema = yup.object().shape({
  enabled: yup.boolean(),
  agents: yup.array().when('enabled', {
    is: true,
    then: (schema) => schema.min(1, 'No agents selected').required()
  })
})

export default quickActionsConfigFormValidationSchema
