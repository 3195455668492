// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._Nhd8lPjl0lEyU7rFyaQ{position:relative}._Nhd8lPjl0lEyU7rFyaQ .W7CIEgwJyC2DhXliTWLN{position:absolute;top:-28px;right:0}", "",{"version":3,"sources":["webpack://./../src/components/MergeTrixEditor/MergeTrixEditor.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAEA,4CACE,iBAAA,CACA,SAAA,CACA,OAAA","sourcesContent":[".FieldContainer {\n  position: relative;\n\n  .MergeFieldContainer {\n    position: absolute;\n    top: -28px;\n    right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FieldContainer": "_Nhd8lPjl0lEyU7rFyaQ",
	"MergeFieldContainer": "W7CIEgwJyC2DhXliTWLN"
};
export default ___CSS_LOADER_EXPORT___;
