// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vanqSwiBBZrLEMCIzPdo{list-style:none;height:100%;overflow-y:auto}.vanqSwiBBZrLEMCIzPdo .Mz5ki5yr2DgghuA0_gAS{color:var(--muted);padding:4px 0;border-bottom:1px solid var(--border-color-lighter)}.vanqSwiBBZrLEMCIzPdo .Mz5ki5yr2DgghuA0_gAS:last-of-type{border:0}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Reports/ContentList/ContentList.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,WAAA,CACA,eAAA,CACA,4CACE,kBAAA,CACA,aAAA,CACA,mDAAA,CACA,yDACE,QAAA","sourcesContent":[".ListContainer {\n  list-style: none;\n  height: 100%;\n  overflow-y: auto;\n  .ListItem {\n    color: var(--muted);\n    padding: 4px 0;\n    border-bottom: 1px solid var(--border-color-lighter);\n    &:last-of-type {\n      border: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ListContainer": "vanqSwiBBZrLEMCIzPdo",
	"ListItem": "Mz5ki5yr2DgghuA0_gAS"
};
export default ___CSS_LOADER_EXPORT___;
