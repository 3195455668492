import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import ConfirmationModal from 'Components/ConfirmationModal'
import PageLoader from 'Components/PageLoader'
import { CONFIRM_MESSAGES } from 'Constants/messages'
import api from 'Services/api'
import parseErrorMessage from 'Utils/error-message-parser'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

import AnswerForm, { getPayloadFromFormData } from './AnswerForm'
import answerFormValidationSchema from './AnswerForm/answer-form-validation-schema'

const AnswerFormHandler = (props) => {
  const { answer } = props
  const navigate = useNavigate()
  const { currentWorkspaceId } = useWorkspace()

  const formMethods = useForm({
    defaultValues: {
      name: answer.name,
      content: answer.content,
      workspace_id: currentWorkspaceId,
      visibility: answer.visibility,
      visibleToGroups: answer.mapped_user_groups
    },
    resolver: yupResolver(answerFormValidationSchema)
  })
  const { handleSubmit } = formMethods

  const [updateAnswer, answerResult] = api.useUpdateAnswerMutation()
  const [deleteAnswer, deleteAnswerResult] = api.useDeleteAnswerMutation()

  const isLoading = answerResult.isLoading || deleteAnswerResult.isLoading

  const update = useCallback(
    (payload) => {
      const promise = updateAnswer(payload).unwrap()
      toast.promise(promise, {
        loading: 'Updating answer...',
        success: 'Answer updated successfully.',
        error: parseErrorMessage('Unable to update answer. Try again...')
      })
      return promise
    },
    [updateAnswer]
  )

  const processAnswer = useCallback(
    async (data, addMore = false) => {
      const payload = {
        id: answer.id,
        ...getPayloadFromFormData(data)
      }

      try {
        await update(payload)
        navigate('../' + `${addMore ? 'create' : ''}`)
      } catch {}
    },
    [answer.id, update, navigate]
  )

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const handleDeleteAnswer = useCallback(() => {
    const promise = deleteAnswer(answer.id).unwrap()
    promise.then(() => setIsDeleteModalOpen(false))

    toast.promise(promise, {
      loading: 'Deleting answer...',
      success: 'Answer deleted successfully.',
      error: parseErrorMessage('Unable to delete answer. Try again...')
    })

    promise.then(() => {
      navigate('../')
    })
  }, [answer.id, deleteAnswer, navigate])

  const onSubmit = useCallback(() => {
    handleSubmit((data) => processAnswer(data))()
  }, [handleSubmit, processAnswer])

  const onSaveAndAdd = useCallback(() => {
    handleSubmit((data) => processAnswer(data, true))()
  }, [handleSubmit, processAnswer])

  return (
    <Fragment>
      <FormProvider {...formMethods}>
        <AnswerForm
          isEdit
          onSubmit={onSubmit}
          onSaveAndAdd={onSaveAndAdd}
          onDeleteAnswer={() => setIsDeleteModalOpen(true)}
          isLoading={isLoading}
        />
      </FormProvider>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        variant='alert'
        message={CONFIRM_MESSAGES.DELETE_ANSWER}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteAnswer}
      />
    </Fragment>
  )
}

const EditAnswer = () => {
  const { id } = useParams()
  const { isLoading, data: answer, isError, error } = api.useGetAnswerByIdQuery(id)

  if (isLoading) {
    return <PageLoader />
  }

  if (isError && error.originalStatus === 404) {
    return <Navigate to='../' replace />
  }

  return <AnswerFormHandler answer={answer} />
}

export default EditAnswer
