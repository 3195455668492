import { Button, FormErrorText, Input, Label, Textarea } from '@happyfoxinc/react-ui'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import styles from '../Actions.module.css'

import MergeInput from 'Components/MergeInput'
import ReactSelect from 'Components/ReactSelect'
import { STEP_OPTIONS } from 'Constants/actions'
import { getConcatenatedMergeFieldText } from 'Src/utils/mergeFields'

import MergeFieldDropdown from '../components/MergeFieldDropdown'

const StepForm = ({
  createTicketSettings,
  inputs,
  stepFields,
  categoriesOption,
  onSubmit,
  editStepIndex,
  onModalClose
}) => {
  const [editingParam, setEditingParam] = useState(null)

  const inputRef = useRef(null)

  useEffect(() => {
    if (editingParam !== null && inputRef.current) {
      inputRef.current.focus()
    }
  }, [editingParam])

  const {
    control,
    formState: { errors },
    register,
    getValues,
    setValue,
    handleSubmit,
    watch
  } = useFormContext()

  const parameters = watch('parameters')

  const selectedStep = watch('selectedStep')

  const handleCellClick = (param) => {
    setEditingParam(param)
  }

  const handleInputBlur = () => {
    setEditingParam(null)
  }

  const AvailableMergeFields = [...(createTicketSettings?.outputs || []), ...(inputs?.map((input) => input.id) || [])]
  const InputFieldNames = inputs?.filter((input) => input.name.trim() !== '').map((input) => input.id) || []

  const handleModalFormSubmit = (data) => {
    const payload = {
      ...data,
      selectedStep,
      parameters
    }

    onSubmit(payload)
  }

  const handleMergeFieldInsertion = ({ selectedFields, name }) => {
    const presentFieldValue = getValues(name)
    const cursorIndex = inputRef.current.selectionStart
    const presentValueLength = presentFieldValue.length

    const isTheCursorAtTheStart = cursorIndex === 0
    const isTheCursorAtTheEnd = cursorIndex === presentValueLength

    const canAddSpacerAtStart = !isTheCursorAtTheStart
    const canAddSpacerAtEnd = !isTheCursorAtTheEnd

    const concatenatedMergeFieldText = getConcatenatedMergeFieldText(
      selectedFields,
      canAddSpacerAtStart,
      canAddSpacerAtEnd
    )

    const updatedFieldValue =
      presentFieldValue.slice(0, cursorIndex) + concatenatedMergeFieldText + presentFieldValue.slice(cursorIndex)

    setValue(name, updatedFieldValue, { shouldDirty: true })
  }

  return (
    <form>
      <div className={styles.ModalFieldContainer}>
        <Label>Step Name</Label>
        <Controller
          control={control}
          name='selectedStep'
          render={({ field }) => {
            return <ReactSelect {...field} options={STEP_OPTIONS} isDisabled={editStepIndex !== null} />
          }}
        />
        {errors.selectedStep && <FormErrorText>{errors.selectedStep.message}</FormErrorText>}
      </div>
      {selectedStep?.value === 'create_ticket' && (
        <Fragment>
          <div className={styles.ModalFieldContainer}>
            <Label>Parameters</Label>
            <table className={styles.ParamTable}>
              <thead>
                <tr>
                  <th className={styles.ParamTableHead}>Key</th>
                  <th className={styles.ParamTableHead}>Value</th>
                </tr>
              </thead>
              <tbody>
                {createTicketSettings?.inputs?.map((param, index) => (
                  <tr key={index}>
                    <td className={styles.ParamTableData}>
                      {param.name.charAt(0).toUpperCase() + param.name.slice(1)}
                    </td>
                    <td className={styles.ParamTableData} onClick={() => handleCellClick(param.name)}>
                      {editingParam === param.name ? (
                        param.type === 'dropdown' ? (
                          <Controller
                            control={control}
                            name={`parameters.${param.name}`}
                            render={({ field }) => {
                              return (
                                <ReactSelect
                                  {...field}
                                  className={styles.ParamDropdown}
                                  styles={{
                                    control: (base) => ({
                                      ...base,
                                      borderColor: 'transparent',
                                      maxHeight: '10px',
                                      minHeight: '37px',
                                      boxShadow: 'none',
                                      ':hover': {
                                        borderColor: 'transparent'
                                      }
                                    })
                                  }}
                                  onChange={(opt) => field.onChange(opt.id)}
                                  value={categoriesOption.find((opt) => opt.id === field.value)}
                                  options={categoriesOption}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                />
                              )
                            }}
                          />
                        ) : (
                          <Fragment>
                            <Controller
                              control={control}
                              defaultValue=''
                              name={`parameters.${param.name}`}
                              render={({ field }) => {
                                const { ref, ...restField } = field
                                return (
                                  <Input
                                    style={{
                                      position: 'absolute',
                                      top: 0,
                                      left: 0,
                                      padding: '8px 25px 8px 8px',
                                      border: 'none',
                                      backgroundColor: 'transparent'
                                    }}
                                    onBlur={() => handleInputBlur(param.name)}
                                    ref={inputRef}
                                    {...restField}
                                  />
                                )
                              }}
                            />
                            {InputFieldNames.length > 0 && (
                              <MergeFieldDropdown
                                fields={InputFieldNames}
                                name={`parameters.${param.name}`}
                                onAdd={handleMergeFieldInsertion}
                              />
                            )}
                          </Fragment>
                        )
                      ) : param.type === 'dropdown' ? (
                        categoriesOption?.find((opt) => opt.id === parameters.category)?.name || ''
                      ) : (
                        parameters[param.name] || ''
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {errors.parameters && <FormErrorText>{errors.parameters.message}</FormErrorText>}
          </div>
          <div className={styles.ModalFieldContainer}>
            <Label>Outputs</Label>
            <ReactSelect
              isMulti
              value={createTicketSettings?.outputs?.map((output) => ({ value: output, label: output }))}
              isDisabled
              isClearable={false}
            />
          </div>
        </Fragment>
      )}
      {selectedStep?.value === 'send_message' && (
        <div className={styles.ModalFieldContainer}>
          <Label>Message</Label>
          {stepFields.some((step) => step.type === 'create_ticket') ? (
            <MergeInput
              showTextArea
              name='message'
              mergeFields={AvailableMergeFields.filter((input) => input.trim() !== '')}
            />
          ) : inputs?.some((input) => input.name.trim() !== '') ? (
            <MergeInput showTextArea name='message' mergeFields={InputFieldNames} />
          ) : (
            <Textarea {...register('message')} />
          )}

          {errors.message && <FormErrorText>{errors.message.message}</FormErrorText>}
        </div>
      )}
      <div className={styles.ModalButtonContainer}>
        <Button variant='primary' onClick={((e) => e.preventDefault(), handleSubmit(handleModalFormSubmit))}>
          {editStepIndex === null ? 'Add Step' : 'Update Step'}
        </Button>
        <Button onClick={onModalClose} variant='secondary-outline'>
          Cancel
        </Button>
      </div>
    </form>
  )
}

export default StepForm
