// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QvnFhiKPTE_6iFU4Vg60{width:286px}.QvnFhiKPTE_6iFU4Vg60 .Hl5_OnNdhlZVHJd7BoPZ{padding:5px 13px 6px 20px}.QvnFhiKPTE_6iFU4Vg60 .Hl5_OnNdhlZVHJd7BoPZ .FBXydKADbO5ooB7UY_c8{margin-bottom:0}.QvnFhiKPTE_6iFU4Vg60 .Hl5_OnNdhlZVHJd7BoPZ .AjFRAgO7itQUqT4NF2_R{cursor:pointer}.QvnFhiKPTE_6iFU4Vg60 .UGszxolpXkOLQxIC0SR5{background-color:#ebebeb;height:1px}.QvnFhiKPTE_6iFU4Vg60 .uy2VsFNboxJ_bELvyaJf{padding:11px 20px 10px 20px}.QvnFhiKPTE_6iFU4Vg60 .uy2VsFNboxJ_bELvyaJf .OuPP2TGv_9UrGKXcBZ9c button{padding:2.3px 19px}", "",{"version":3,"sources":["webpack://./../src/components/InsertMergeFields/InsertMergeFields.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAEA,4CACE,yBAAA,CACA,kEACE,eAAA,CAEF,kEACE,cAAA,CAIJ,4CACE,wBAAA,CACA,UAAA,CAGF,4CACE,2BAAA,CAEE,yEACE,kBAAA","sourcesContent":[".Container {\n  width: 286px;\n\n  .HeadingContainer {\n    padding: 5px 13px 6px 20px;\n    .Heading {\n      margin-bottom: 0;\n    }\n    .CloseIcon {\n      cursor: pointer;\n    }\n  }\n\n  .Divider {\n    background-color: #ebebeb;\n    height: 1px;\n  }\n\n  .Body {\n    padding: 11px 20px 10px 20px;\n    .ButtonContainer {\n      button {\n        padding: 2.3px 19px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "QvnFhiKPTE_6iFU4Vg60",
	"HeadingContainer": "Hl5_OnNdhlZVHJd7BoPZ",
	"Heading": "FBXydKADbO5ooB7UY_c8",
	"CloseIcon": "AjFRAgO7itQUqT4NF2_R",
	"Divider": "UGszxolpXkOLQxIC0SR5",
	"Body": "uy2VsFNboxJ_bELvyaJf",
	"ButtonContainer": "OuPP2TGv_9UrGKXcBZ9c"
};
export default ___CSS_LOADER_EXPORT___;
