import { Button, FormControl, FormErrorText, Heading, Input, Label } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import api from 'Services/api'
import parseErrorMessage from 'Utils/error-message-parser'

import { useAppDetailContext } from '../../AppDetailContext'
import guruAuthFormValidationSchema from './guru-auth-form-validation-schema'

const GuruAuthForm = (props) => {
  const { onCancel, app } = props
  const { title } = useAppDetailContext()
  const [install, installResult] = api.useInstallGuruMutation()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty }
  } = useForm({
    defaultValues: {
      email: app.user_email_id,
      token: ''
    },
    resolver: yupResolver(guruAuthFormValidationSchema)
  })

  const handleConnect = async (data) => {
    const payload = {
      user_email_id: data.email,
      user_token: data.token
    }

    const toastId = toast.loading(`Connecting to ${title}`)

    try {
      await install(payload).unwrap()
      toast.dismiss(toastId)
      onCancel()
    } catch (err) {
      toast.error(parseErrorMessage(`Unable to connect to ${title}. Try again...`)(err), { id: toastId })
    }
  }

  const disableSubmitButton = isSubmitting || !isDirty || installResult.isLoading

  return (
    <Fragment>
      <Heading level={2}>Connect to {title}</Heading>
      <form onSubmit={handleSubmit(handleConnect)}>
        <FormControl isInvalid={errors.accountUrl}>
          <Label>User Email address</Label>
          <Input {...register('email')} />
          {errors.email && <FormErrorText>{errors.email.message}</FormErrorText>}
        </FormControl>
        <FormControl isInvalid={errors.apiKey}>
          <Label>API Key</Label>
          <Input {...register('token')} />
          {errors.token && <FormErrorText>{errors.token.message}</FormErrorText>}
        </FormControl>
        <Button variant='primary' type='submit' disabled={disableSubmitButton}>
          Authorize
        </Button>
        <Button variant='link-muted' onClick={onCancel}>
          Cancel
        </Button>
      </form>
    </Fragment>
  )
}

export default GuruAuthForm
