// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cBZRYVx5eT_tMoSwZm90{display:flex;align-items:center}.bQAh2_i8hf0t9lRSkIUG{padding:1rem 0}.XUypjz8rymobhjMYJYqY{flex:1}.reu1Cl0tEtcO4qWqFj5t{padding:.75rem;cursor:pointer;position:absolute;right:0;top:0}", "",{"version":3,"sources":["webpack://./../src/components/Modal/Modal.module.css"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,cAAA,CAGF,sBACE,MAAA,CAGF,sBACE,cAAA,CACA,cAAA,CACA,iBAAA,CACA,OAAA,CACA,KAAA","sourcesContent":[".Header {\n  display: flex;\n  align-items: center;\n}\n\n.ModalBody {\n  padding: 1rem 0;\n}\n\n.HeaderContent {\n  flex: 1;\n}\n\n.Close {\n  padding: 0.75rem;\n  cursor: pointer;\n  position: absolute;\n  right: 0;\n  top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": "cBZRYVx5eT_tMoSwZm90",
	"ModalBody": "bQAh2_i8hf0t9lRSkIUG",
	"HeaderContent": "XUypjz8rymobhjMYJYqY",
	"Close": "reu1Cl0tEtcO4qWqFj5t"
};
export default ___CSS_LOADER_EXPORT___;
