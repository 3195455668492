import { DEFAULT_QUERY_PARAMS } from 'Constants/query-prams'

export const getInitialParamsForTable = (defaultParams) => {
  const {
    defaultPageSize,
    defaultPage,
    defaultSearch,
    supportedSortColumns,
    defaultSortedColumn,
    defaultSortOrder,
    supportedSortOrder
  } = { ...DEFAULT_QUERY_PARAMS, ...defaultParams }

  const params = new URLSearchParams(location.search)

  const paramsSize = params.get('size') || defaultPageSize
  const paramsPage = params.get('page') || defaultPage
  const paramsSearch = params.get('search') ?? defaultSearch
  const paramsSortBy = params.get('sort_by') ?? defaultSortedColumn
  const paramsOrderBy = params.get('order_by') ?? defaultSortOrder

  const page = isNaN(paramsPage) ? defaultPage : parseInt(paramsPage)
  const size = isNaN(paramsSize) ? defaultPageSize : parseInt(paramsSize)

  const sortColumn = supportedSortColumns.includes(paramsSortBy) ? paramsSortBy : defaultSortedColumn
  const sortOrder = supportedSortOrder.includes(paramsOrderBy) ? paramsOrderBy : defaultSortOrder

  const queryParams = {
    page,
    size
  }

  if (paramsSearch !== null) {
    queryParams.search = paramsSearch
  }

  if (supportedSortColumns.length > 0) {
    queryParams.sortBy = [{ id: sortColumn, desc: sortOrder === 'desc' }]
  }

  return queryParams
}

export const getInitialApiQueryParamsForTable = (params) => {
  const apiParams = {
    offset: (params.page - 1) * params.size,
    limit: params.size
  }

  if (params.search !== undefined) {
    apiParams.search = params.search
  }

  if (params.sortBy) {
    apiParams.sort_by = params.sortBy[0].id
    apiParams.order_by = params.sortBy[0].desc ? 'desc' : 'asc'
  }

  return apiParams
}
