import { Text } from '@happyfoxinc/react-ui'
import { Fragment } from 'react'

import styles from './ContentOutline.module.scss'

const ContentOutline = ({ title, children }) => {
  return (
    <Fragment>
      <Text className={styles.Title} variant='muted'>
        {title}
      </Text>
      <div className={styles.Outline}>{children}</div>
    </Fragment>
  )
}

export default ContentOutline
