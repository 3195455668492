import { AppBase, shouldShowUpgradePrompt, useAccountAndPlan } from '../Common/CommonUpgrade'
import UpgradePrompt from '../Common/UpgradePrompt'

const InstallApp = ({ name, onInstall, isInstalling = false }) => {
  const { account, planCategory, isPremiumAppsEnabled } = useAccountAndPlan()

  const upgradeMessage = shouldShowUpgradePrompt(planCategory, isPremiumAppsEnabled, account)

  if (upgradeMessage) {
    return <UpgradePrompt title={upgradeMessage.title} message={upgradeMessage.message} />
  }

  const message = `You haven't linked your ${name} account yet, click below to begin the process.`
  return <AppBase name={name} message={message} buttonText='Install' onAction={onInstall} isLoading={isInstalling} />
}

export default InstallApp
