import styles from './ContentList.module.scss'

import ReadMore from 'Components/ReadMore'

const ContentList = ({ list }) => {
  return (
    <ul className={styles.ListContainer}>
      {list?.map((item, i) => (
        <li key={item + i} className={styles.ListItem}>
          <ReadMore content={item} />
        </li>
      ))}
    </ul>
  )
}

export default ContentList
