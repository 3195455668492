import { Route, Routes, useNavigate } from 'react-router-dom'

import PageLoader from 'Components/PageLoader'
import api from 'Services/api'

import InstallOkta from './InstallOkta'
import ManageOkta from './ManageOkta'
import OktaAuthForm from './OktaAuthForm'

const Okta = () => {
  const { isLoading, data: app = {} } = api.useGetOktaAppQuery()
  const navigate = useNavigate()

  if (isLoading) {
    return <PageLoader />
  }

  if (!app.is_linked) {
    return <InstallOkta app={app} />
  }

  const handleAuthFormCancel = () => {
    navigate('./')
  }

  return (
    <Routes>
      <Route
        path='auth'
        element={<OktaAuthForm onCancel={handleAuthFormCancel} onAuth={handleAuthFormCancel} app={app} />}
      />
      <Route path='*' element={<ManageOkta app={app} />} />
    </Routes>
  )
}

export default Okta
