// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iQUb74FtiojWoKLLBRGz{position:relative;display:flex;align-items:center;width:100%}.RlhNc9V_YcHf_UIbEYMl{border-color:#e3e3e3;padding-left:3rem !important}.hqC0Wc4mglUAvBkCArmy{position:absolute;left:1rem}.q_J1Cp8Ghz_XsRfZ6ATc .hqC0Wc4mglUAvBkCArmy{right:1rem;left:auto}.q_J1Cp8Ghz_XsRfZ6ATc .RlhNc9V_YcHf_UIbEYMl{padding-left:var(--input-padding-x) !important;padding-right:3rem !important}", "",{"version":3,"sources":["webpack://./../src/components/SearchInput/SearchInput.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,UAAA,CAGF,sBACE,oBAAA,CACA,4BAAA,CAGF,sBACE,iBAAA,CACA,SAAA,CAIA,4CACE,UAAA,CACA,SAAA,CAGF,4CACE,8CAAA,CACA,6BAAA","sourcesContent":[".InputContainer {\n  position: relative;\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n\n.Search {\n  border-color: #E3E3E3;\n  padding-left: 3rem !important;\n}\n\n.Lens {\n  position: absolute;\n  left: 1rem;\n}\n\n.AlignRight {\n  .Lens {\n    right: 1rem;\n    left: auto;\n  }\n\n  .Search {\n    padding-left: var(--input-padding-x) !important;\n    padding-right: 3rem !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputContainer": "iQUb74FtiojWoKLLBRGz",
	"Search": "RlhNc9V_YcHf_UIbEYMl",
	"Lens": "hqC0Wc4mglUAvBkCArmy",
	"AlignRight": "q_J1Cp8Ghz_XsRfZ6ATc"
};
export default ___CSS_LOADER_EXPORT___;
