import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import queryString from 'query-string'

import api from 'Services/api'
import { redirect } from 'Utils/url'

export const paramsSerializer = (params) => {
  return queryString.stringify(params, {
    arrayFormat: 'bracket',
    skipEmptyString: true,
    skipNull: true
  })
}

const prepareHeaders = (headers, { endpoint, getState }) => {
  const isGetCsrfEndpoint = endpoint === 'getCsrfToken'
  if (isGetCsrfEndpoint) {
    return headers
  }

  const selector = api.endpoints.getCsrfToken.select()
  const { data: csrfTokenData } = selector(getState())
  const { csrf_token } = csrfTokenData
  headers.set('X-CSRFToken', csrf_token)

  return headers
}

const baseQuery = fetchBaseQuery({
  baseUrl: '/api/',
  credentials: 'same-origin',
  paramsSerializer,
  prepareHeaders
})

export const rtkBaseQuery = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)

  const isError = result.error
  const isUnauthorized = isError && result.error.status === 401
  const isCsrfFailedError = isError && result.error.status === 403 && result.error.data.code === 'CSRF_FAILED'

  if (isUnauthorized || isCsrfFailedError) {
    // // try to get a new token
    // const refreshResult = await baseQuery('/refreshToken', api, extraOptions)
    // if (refreshResult.data) {
    //   // store the new token
    //   api.dispatch(tokenReceived(refreshResult.data))
    //   // retry the initial query
    //   result = await baseQuery(args, api, extraOptions)
    // } else {
    //   api.dispatch(loggedOut())
    //   // or
    //   api.dispatch(unAuthorized())
    // }

    redirect('logout')
    /* HACK: We made the function wait (indefinitely) to ensure that the redirect occurs within the wait time window
    and not to return when we receive a 401/CSRF error. */
    await new Promise(() => {})
  }

  return result
}
