import { Outlet, Route, Routes, useNavigate } from 'react-router-dom'

import styles from './UserGroup.module.scss'

import Link from 'Components/Link'
import Tips, { Tip } from 'Components/Tips'
import KB_LINKS from 'Constants/kb-links'

import { AccessConditionProvider } from './AccessControl/AccessConditionContext'
import CreateUserGroup from './CreateUserGroup'
import EditUserGroup from './EditUserGroup'

const UserGroup = () => {
  const navigate = useNavigate()
  return (
    <div className={styles.Container}>
      <div className={styles.Content}>
        <div className={styles.MainContent}>
          <Routes>
            <Route path='/' element={<Outlet />}>
              <Route
                path='create'
                element={
                  <AccessConditionProvider>
                    <CreateUserGroup />
                  </AccessConditionProvider>
                }
              />
              <Route
                path=':id'
                index
                element={
                  <AccessConditionProvider>
                    <EditUserGroup />
                  </AccessConditionProvider>
                }
              />
            </Route>
          </Routes>
        </div>
        <div className={styles.SidebarContent}>
          <Tips title='Tips to add User Group'>
            <Tip>
              User groups allow you to group users based on different attributes like location, department, designation
              etc.. You can then restrict knowledge visibility across different user groups.
            </Tip>
            <Tip>
              <Link variant='primary-dark' onClick={() => navigate('/apps/category/knowledge-sources')}>
                Set Knowledge Visibility
              </Link>{' '}
              by configuring the knowledge base access to the appropriate user groups.
            </Tip>
            <Tip>
              Learn more about{' '}
              <Link variant='primary-dark' href={KB_LINKS.CREATE_USER_GROUP} target='_blank' rel='noreferrer'>
                creating user groups
              </Link>{' '}
              and{' '}
              <Link variant='primary-dark' href={KB_LINKS.SET_KNOWLEDGE_VISIBILITY} target='_blank' rel='noreferrer'>
                restricting knowledge source
              </Link>
              .
            </Tip>
          </Tips>
        </div>
      </div>
    </div>
  )
}

export default UserGroup
