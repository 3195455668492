import cx from 'classnames'

import styles from './PageLoader.module.scss'

const PageLoader = ({ isLoading = true, children, size = 'regular' }) => {
  if (isLoading) {
    return (
      <div className={styles.Container}>
        <div className={cx(styles.Loader, styles[size])} />
      </div>
    )
  }

  return children
}

export default PageLoader
