// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".O8Hk_P0VYhO5v_c75b7V{--text-color: #777777;--highlight-color: #f9f9f9;display:inline-flex;-moz-column-gap:5px;column-gap:5px;align-items:center;-webkit-user-select:none;user-select:none;font-size:12px;padding:1px 8px;border-radius:4px;background-color:var(--highlight-color);color:var(--text-color)}.O8Hk_P0VYhO5v_c75b7V.J3YototZU5eCotKjTzOx{--text-color: #385b33;--highlight-color: #f4f8ea}.O8Hk_P0VYhO5v_c75b7V.rItD6340JGkIJt1WcYQz{--highlight-color: #fcf1cc;--text-color: #7d5235}.O8Hk_P0VYhO5v_c75b7V.pGhAiGsgWHoBXsmR1qn9{--highlight-color: #fff3f3;--text-color: #a64c4c}.O8Hk_P0VYhO5v_c75b7V.rsdSzWs7cUQgWuDnux9r{cursor:pointer}", "",{"version":3,"sources":["webpack://./../src/components/Badge/Badge.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,0BAAA,CACA,mBAAA,CACA,mBAAA,CAAA,cAAA,CACA,kBAAA,CACA,wBAAA,CAAA,gBAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CACA,uCAAA,CACA,uBAAA,CACA,2CACE,qBAAA,CACA,0BAAA,CAEF,2CACE,0BAAA,CACA,qBAAA,CAEF,2CACE,0BAAA,CACA,qBAAA,CAEF,2CACE,cAAA","sourcesContent":[".Badge {\n  --text-color: #777777;\n  --highlight-color: #f9f9f9;\n  display: inline-flex;\n  column-gap: 5px;\n  align-items: center;\n  user-select: none;\n  font-size: 12px;\n  padding: 1px 8px;\n  border-radius: 4px;\n  background-color: var(--highlight-color);\n  color: var(--text-color);\n  &.Green {\n    --text-color: #385b33;\n    --highlight-color: #f4f8ea;\n  }\n  &.Brown {\n    --highlight-color: #fcf1cc;\n    --text-color: #7d5235;\n  }\n  &.Purple {\n    --highlight-color: #fff3f3;\n    --text-color: #a64c4c;\n  }\n  &.Clickable {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Badge": "O8Hk_P0VYhO5v_c75b7V",
	"Green": "J3YototZU5eCotKjTzOx",
	"Brown": "rItD6340JGkIJt1WcYQz",
	"Purple": "pGhAiGsgWHoBXsmR1qn9",
	"Clickable": "rsdSzWs7cUQgWuDnux9r"
};
export default ___CSS_LOADER_EXPORT___;
