import * as yup from 'yup'

const inviteAdminValidationSchema = yup
  .object()
  .shape({
    email: yup.string().email('Invalid Email').required('Email is required')
  })
  .required()

export default inviteAdminValidationSchema
