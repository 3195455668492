import { PaginationControls, Table, TableActions, TableBody, TableHead, TableRow, Td, Th } from '@happyfoxinc/react-ui'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { usePagination, useTable } from 'react-table'

import styles from './AdminsTable.module.css'

import PageLoader from 'Components/PageLoader'
import api from 'Services/api'
import debounce from 'Utils/debounce'
import { getInitialApiQueryParamsForTable, getInitialParamsForTable as getInitialParams } from 'Utils/query-params'

import InviteAdmin from '../InviteAdmin'
import * as Cells from './AdminCells'
import AdminOptions from './AdminOptions'

const getInitialApiQueryParams = () => {
  const initialParams = getInitialParams()
  return getInitialApiQueryParamsForTable(initialParams)
}

const AdminsTable = () => {
  const { data: currentUser } = api.useGetProfileQuery()

  const initialParams = useMemo(getInitialParams, [])
  const [, setSearchParams] = useSearchParams()

  const [queryParams, setQueryParams] = useState(getInitialApiQueryParams)
  const [pageSize] = useState(initialParams.size)

  const { isLoading, data = {} } = api.useGetAdminsQuery(queryParams)
  const { results: users = [], meta: paginationDetails = {} } = data

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Status',
        accessor: (row) => row,
        Cell: Cells.Status
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: users,
      initialState: {
        pageIndex: initialParams.page - 1,
        pageSize
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / pageSize)
    },
    usePagination
  )

  useEffect(() => {
    const params = {}

    const apiParams = {
      offset: pageIndex * pageSize,
      limit: pageSize,
      ...params
    }

    const urlPrams = {
      page: pageIndex + 1,
      size: pageSize,
      ...params
    }

    debouncedSetQueryParams(apiParams)

    setSearchParams(urlPrams, { replace: true })
  }, [pageIndex, pageSize, debouncedSetQueryParams, setSearchParams])

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <div className={styles.Container}>
      <TableActions>
        <PaginationControls
          currentPage={pageIndex + 1}
          pageSize={pageSize}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
        <InviteAdmin />
      </TableActions>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

            return (
              <TableRow key={key} {...headerGroupProps}>
                <Fragment>
                  {headerGroup.headers.map((column) => {
                    const { key, ...headerProps } = column.getHeaderProps()

                    return (
                      <Th key={key} {...headerProps}>
                        {column.render('Header')}
                      </Th>
                    )
                  })}
                  <Th isOption />
                </Fragment>
              </TableRow>
            )
          })}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...rowProps } = row.getRowProps()
            const admin = row.original

            return (
              <TableRow key={key} {...rowProps}>
                {row.cells.map((cell) => {
                  const { key, ...cellProps } = cell.getCellProps()
                  return (
                    <Td key={key} {...cellProps}>
                      {cell.render('Cell')}
                    </Td>
                  )
                })}

                <Td>
                  {rows.length > 0 && currentUser.email !== admin.email && <AdminOptions {...admin} admin={admin} />}
                </Td>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default AdminsTable
