import { Button, Heading } from '@happyfoxinc/react-ui'
import cx from 'classnames'
import { useState } from 'react'

import styles from './Login.module.scss'

import MicrosoftSigninIcon from 'Images/brands/microsoft-signin.svg'
import MsTeamsLogo from 'Images/brands/ms-teams-text-logo.svg'
import SlackSigninIcon from 'Images/brands/slack-signin.svg'
import SlackLogo from 'Images/brands/slack-text-logo.svg'
import AssistAiLogo from 'Images/happyfox-assist-ai-logo.svg'

import { ACCOUNT_TYPE } from 'Constants/account'
import { URLS } from 'Constants/urls'
import { externalRedirect } from 'Utils/url'

const LoginCard = ({ isFaded, onMouseEnter, onMouseLeave, children }) => {
  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cx(styles.LoginCardContainer, { [styles.Faded]: isFaded })}
    >
      {children}
    </div>
  )
}

const Login = () => {
  const [hoveredItem, setHoveredItem] = useState(null)
  const isSlackFaded = hoveredItem === ACCOUNT_TYPE.MS_TEAMS
  const isMsTeamsFaded = hoveredItem === ACCOUNT_TYPE.SLACK

  return (
    <div className={styles.Container}>
      <div className={styles.Content}>
        <AssistAiLogo className={styles.Logo} />

        <Heading className={styles.Heading}>Sign in with</Heading>

        <div className={styles.CardRow}>
          <LoginCard
            isFaded={isSlackFaded}
            onMouseEnter={() => setHoveredItem(ACCOUNT_TYPE.SLACK)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <SlackLogo className={styles.SlackLogo} />
            <a className={styles.SignIn} href='/app/login/slack/start'>
              <SlackSigninIcon />
            </a>
          </LoginCard>

          <LoginCard
            isFaded={isMsTeamsFaded}
            onMouseEnter={() => setHoveredItem(ACCOUNT_TYPE.MS_TEAMS)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <MsTeamsLogo className={styles.MsTeamsLogo} />
            <a className={styles.SignIn} href='/app/login/ms-teams/start'>
              <MicrosoftSigninIcon />
            </a>
          </LoginCard>
        </div>

        <div className={styles.NewCustomer}>
          Not a customer?
          <Button
            className={styles.TryButton}
            variant='default'
            size='xs'
            onClick={() => externalRedirect(URLS.ASSIST_AI)}
          >
            Try for free
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Login
