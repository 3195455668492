import { Outlet } from 'react-router-dom'

import styles from './Main.module.css'

import Sidebar from 'Components/Sidebar'
import Topbar from 'Components/Topbar'

const Main = (props) => {
  const { sidebar, navbar, ...rest } = props
  return (
    <div className={styles.MainContainer} {...rest}>
      <Sidebar />
      <div className={styles.PageContainer}>
        <Topbar />
        <article className={styles.Content}>
          <Outlet />
        </article>
      </div>
    </div>
  )
}

export default Main
