import { FormErrorText, Heading, PaginationControls, TableActions, Text, Th } from '@happyfoxinc/react-ui'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { usePagination, useTable } from 'react-table'

import styles from './SharePointSites.module.scss'

import ExternalLinkIcon from 'Icons/external-link.svg'

import { SecondaryButton } from 'Components/Buttons'
import Link from 'Components/Link'
import PageLoader from 'Components/PageLoader'
import ReactSelect from 'Components/ReactSelect'
import api from 'Services/api'
import debounce from 'Utils/debounce'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

import SharePointSitesOptions from './SharePointSitesOptions'

const PAGE_SIZE = 5
const defaultApiParams = { offset: 0, limit: PAGE_SIZE }

const getInitialApiQueryParams = (currentWorkspaceId) => {
  return {
    ...defaultApiParams,
    workspace_id: currentWorkspaceId
  }
}
const SitesList = () => {
  const { currentWorkspaceId } = useWorkspace()
  const [queryParams, setQueryParams] = useState(getInitialApiQueryParams(currentWorkspaceId))

  const { isLoading, data = {} } = api.useGetSharePointSitesQuery(queryParams)
  const { results: sites = [], meta: paginationDetails = {} } = data

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Site Name',
        accessor: (originalRow) => originalRow,
        disableSortBy: true,
        Cell: ({ cell: { value: site } }) => {
          return (
            <Link
              variant='primary-dark'
              href={site.site_url}
              target='_blank'
              className={styles.SiteLink}
              rel='noreferrer'
            >
              {site.site_name} <ExternalLinkIcon width='1em' height='1em' />
            </Link>
          )
        }
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: sites,
      initialState: {
        pageIndex: 0,
        pageSize: PAGE_SIZE
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / PAGE_SIZE)
    },
    usePagination
  )

  useEffect(() => {
    const params = {
      workspace_id: currentWorkspaceId
    }

    const apiParams = {
      offset: pageIndex * PAGE_SIZE,
      limit: PAGE_SIZE,
      ...params
    }

    debouncedSetQueryParams(apiParams)
  }, [pageIndex, debouncedSetQueryParams, currentWorkspaceId])

  if (isLoading) {
    return <PageLoader />
  }

  if (paginationDetails.total === 0) {
    return null
  }

  return (
    <Fragment>
      <Heading level={2}>Sites currently syncing from</Heading>
      <div className={styles.TableContainer}>
        <TableActions>
          <PaginationControls
            currentPage={pageIndex + 1}
            pageSize={PAGE_SIZE}
            totalItems={paginationDetails.total}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            previousPage={previousPage}
            nextPage={nextPage}
          />
        </TableActions>
        <table className={styles.Table} {...getTableProps()}>
          <thead className={styles.Th}>
            {headerGroups.map((headerGroup) => {
              const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

              return (
                <tr key={key} {...headerGroupProps}>
                  <Fragment>
                    {headerGroup.headers.map((column) => {
                      const { key, ...headerProps } = column.getHeaderProps()

                      return (
                        <th className={styles.Th} key={key} {...headerProps}>
                          {column.render('Header')}
                        </th>
                      )
                    })}
                    <Th isOption />
                  </Fragment>
                </tr>
              )
            })}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              const { key, ...rowProps } = row.getRowProps()
              const site = row.original

              return (
                <tr key={key} {...rowProps}>
                  <Fragment>
                    {row.cells.map((cell) => {
                      const { key, ...cellProps } = cell.getCellProps()
                      return (
                        <td className={styles.Td} key={key} {...cellProps}>
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                    <td>
                      <SharePointSitesOptions id={site.site_id} />
                    </td>
                  </Fragment>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className={styles.NoSites}>{sites.length === 0 && <Text size='xs'>Add sites to start syncing</Text>}</div>
      </div>
    </Fragment>
  )
}

const SharePointSites = () => {
  const { currentWorkspaceId } = useWorkspace()
  const [searchSites, searchSitesResult] = api.useLazySearchSharePointSitesQuery()
  const [addSite, addSiteResult] = api.useAddSharePointSiteMutation()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      site: null
    }
  })

  const handleSiteAddition = async (data) => {
    const { site } = data
    try {
      await addSite({ site_id: site.SiteId, workspace_id: currentWorkspaceId })
      reset()
    } catch {}
  }

  const loadOptions = (inputValue, cb) => {
    searchSites({ site_name: inputValue, workspace_id: currentWorkspaceId }).unwrap().then(cb)
  }

  return (
    <Fragment>
      <div className={styles.AddSiteContainer}>
        <Heading level={2}>Add a SharePoint site to sync from</Heading>
        <form onSubmit={handleSubmit(handleSiteAddition)} className={styles.AddSiteForm}>
          <Controller
            control={control}
            name='site'
            rules={{ required: 'Search and select a site to add' }}
            render={({ field }) => {
              return (
                <ReactSelect
                  {...field}
                  className={styles.SearchSite}
                  minCharsBeforeLoadingOptions={3}
                  loadOptions={loadOptions}
                  getOptionLabel={(option) => option.Title}
                  getOptionValue={(option) => option.Path}
                  isLoading={searchSitesResult.isLoading}
                  loadingMessage={({ inputValue }) => {
                    return `Searching for sites matching "${inputValue}"...`
                  }}
                  noOptionsMessage={({ inputValue }) => {
                    if (inputValue.length < 3) {
                      return 'Type alteast 3 characters to start searching'
                    }

                    return `No sites found for input "${inputValue}"`
                  }}
                />
              )
            }}
          />
          <SecondaryButton type='submit' disabled={addSiteResult.isLoading}>
            + Add
          </SecondaryButton>
        </form>
        {errors.site && <FormErrorText>{errors.site.message}</FormErrorText>}
      </div>
      <SitesList />
    </Fragment>
  )
}

export default SharePointSites
