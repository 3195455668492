import * as yup from 'yup'

const oktaAuthFormValidationSchema = yup
  .object()
  .shape({
    accountUrl: yup.string().trim().required('Account URL is required').url('Invalid URL'),
    token: yup.string().trim().required('Token is required')
  })
  .required()

export default oktaAuthFormValidationSchema
